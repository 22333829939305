import Utils from './utils'
import { ActionMap } from '../context'
import { Taxonomy } from './taxonomy'
import { CO2ByYear, VariableBaseNode } from '../types'
import { FlowConfig } from './flow'
import { Node } from 'reactflow'
import { User } from './user-context'
import VariableService from './service'

export interface Org extends VariableBaseNode {
    name: string
    description?: string
    order: number
    co2e?: string
    totalCo2e?: number
    co2eByYear?: CO2ByYear[]
    categoryCo2e?: string
    marketTotalCo2e?: number
    marketCo2eByYear?: CO2ByYear[]
    activityCount?: number
    otherActivityCount?: number
    level: number
    leftEdge?: number
    rightEdge?: number
    maxChildLevel?: number
    isTotal?: boolean
    isUncategorized?: boolean
    parent?: Org
    child?: Org
    children?: Org[]
    taxonomies?: Taxonomy[]
    users?: User[]
}

export interface OrgNodeData {
    org: Org
    fixedY?: number
    highlight?: boolean
    reordering?: boolean
    onCreate: () => void
    onReplace: () => void
    onEscape: () => void
    onUpdate: (org: Org) => void
}

export interface IOrgNode extends Node {
    data: OrgNodeData
}

export interface OrgContext {
    list: Org[]
    orgById?: Map<string, Org>
    current?: Org
    delete?: Org
    flow?: FlowConfig
}

export enum OrgActionType {
    Set = 'SetOrgs',
    SetOrg = 'SetOrg',
    AddUserToOrg = 'AddUserToOrg',
    RemoveUserFromOrg = 'RemoveUserFromOrg',
    UnsetOrg = 'UnsetOrg',
    SetOrgToDelete = 'SetOrgToDelete',
    UnsetOrgToDelete = 'UnsetOrgToDelete',
    SetFlow = 'SetOrgFlow',
    RefreshFlow = 'RefreshOrgFlow',
}

type OrgActionPayload = {
    [OrgActionType.Set]: Org[]
    [OrgActionType.SetOrg]: Org
    [OrgActionType.AddUserToOrg]: User
    [OrgActionType.RemoveUserFromOrg]: User
    [OrgActionType.UnsetOrg]: undefined
    [OrgActionType.SetOrgToDelete]: Org
    [OrgActionType.UnsetOrgToDelete]: undefined
    [OrgActionType.SetFlow]: FlowConfig
    [OrgActionType.RefreshFlow]: undefined
}

export type OrgActions = ActionMap<OrgActionPayload>[keyof ActionMap<OrgActionPayload>]

export const OrgReducer = (state: OrgContext, action: OrgActions): OrgContext => {
    if (action.type === OrgActionType.Set) {
        const _map = new Map<string, Org>()
        action.payload.forEach((org) => _map.set(org.uuid!, org))
        return { ...state, list: action.payload, orgById: _map }
    } else if (action.type === OrgActionType.AddUserToOrg) {
        const _current = {
            ...state.current!,
            users: [...(state.current?.users || []), action.payload],
        }
        const _list = state.list.map((org) => {
            if (org.uuid === _current.uuid) {
                return _current
            }
            return org
        })
        const _orgById = state.orgById?.set(_current.uuid!, _current)
        return { ...state, list: _list, current: _current, orgById: _orgById }
    } else if (action.type === OrgActionType.RemoveUserFromOrg) {
        const _current = {
            ...state.current!,
            users: (state.current?.users || []).filter((u) => u.uuid !== action.payload.uuid),
        }
        const _list = state.list.map((org) => {
            if (org.uuid === _current.uuid) {
                return _current
            }
            return org
        })
        const _orgById = state.orgById?.set(_current.uuid!, _current)
        return { ...state, list: _list, current: _current, orgById: _orgById }
    }
    switch (action.type) {
        case OrgActionType.UnsetOrg:
            return { ...state, current: undefined }
        case OrgActionType.SetOrg:
            return { ...state, current: action.payload }
        case OrgActionType.SetOrgToDelete:
            return { ...state, delete: action.payload }
        case OrgActionType.UnsetOrgToDelete:
            return { ...state, delete: undefined }
        case OrgActionType.SetFlow:
            return { ...state, flow: action.payload }
        case OrgActionType.RefreshFlow:
            return { ...state, flow: { ...state.flow!, refresh: (state.flow?.refresh || 0) + 1 } }
        default:
            return state
    }
}

export default class OrgService extends VariableService {
    private basePath: string = '/org'
    public static webRoot: string = '/org'
    public static webTitle = (plural: boolean = false, includeUnit: boolean = false): string => {
        if (plural) {
            return 'Org units'
        }
        if (includeUnit) {
            return `Org ${Utils.pluralize('unit', plural ? 2 : 1)}`
        }
        return 'Org'
    }
    public static readonly newOrgPrefix = 'tmp-'
    public static readonly otherOrgSuffix = '-other'
    public static readonly otherOrgName = 'Other'

    public static getDefaultOrg = (order?: number, parentOrg?: Org): Org => {
        let _order = order || parentOrg?.children?.sort((a, b) => b.order - a.order)[0]?.order || 0
        return {
            name: '',
            level: (parentOrg?.level || 0) + 1,
            order: _order + 1,
            parent: parentOrg,
        }
    }

    public getOrgArray = (org?: Org): Org[] => {
        if (!org) return []
        org = this.context.stores.orgs.orgById?.get(org.uuid || '') || org
        const _parent = this.context.stores.orgs.orgById?.get(org.parent?.uuid || '')
        if (_parent && !_parent.isTotal) return [...this.getOrgArray(_parent), org]
        return [org]
    }

    public getOrgName = (org?: Org): string => {
        if (!org?.name) {
            return ''
        }
        if (org?.isTotal) {
            return this.context.stores.company?.name || org.name || 'Total'
        }
        return org.name
    }

    public static isSystem(org?: Org): boolean {
        if (org?.level === undefined || org?.order === undefined) {
            return true
        }
        return org?.level === 1 && !!(org?.isTotal || org?.isUncategorized)
    }

    public static isTemp(org?: Org): boolean {
        return !org || !org?.uuid || org?.uuid?.startsWith(OrgService.newOrgPrefix) === true
    }

    public static isOther(org?: Org): boolean {
        if (org?.isUncategorized) {
            return true
        }
        return !!org?.uuid && org?.uuid?.endsWith(OrgService.otherOrgSuffix) === true
    }

    public static findMaxBreadth(org?: Org): number {
        let _breadth = org?.children?.length || 1
        org?.children?.forEach((child) => {
            const _childBreadth = this.findMaxBreadth(child)
            // if (org?.name === 'Suppliers') {
            //     console.log(org?.name, _breadth, child.name, _childBreadth)
            // }
            if (_childBreadth > 1) {
                _breadth += _childBreadth - 1
            }
        })
        if (org) {
            org.maxChildLevel = _breadth
        }
        return _breadth
    }

    public static resetFlowParams(org: Org): Org {
        return {
            ...org,
            leftEdge: undefined,
            rightEdge: undefined,
            maxChildLevel: undefined,
        }
    }

    private setOrgContext = (_orgs: Org[]): Org[] => {
        this.context.dispatch({ type: OrgActionType.Set, payload: _orgs })
        return _orgs
    }

    private addToFlatArray = (flatArray: Org[], org: Org, level: number, idx: number) => {
        if (org && !org?.isUncategorized) {
            if (!org.level) {
                org.level = level + 1
            }
            if (!org.order) {
                org.order = org.order || org.level * 100 + idx
            }
            flatArray.push(org)
            org.children?.forEach((item) => this.addToFlatArray(flatArray, item, org.level, ++idx))
        }
    }

    public getFlatList(org: Org[]): Org[] {
        const flatArray: Org[] = []
        const _total = org.find((item) => item.isTotal)
        if (_total) {
            this.addToFlatArray(flatArray, _total, _total.level || 0, 0)
        } else {
            org.forEach((item) => this.addToFlatArray(flatArray, item, 0, 0))
        }
        const _unCat = org.find((item) => item.isUncategorized && !item.parent)
        if (_unCat !== undefined) {
            this.addToFlatArray(flatArray, _unCat, _unCat.level || 0, 0)
        }
        return flatArray
    }

    public setParentUncategorized(org: Org, forFlow: boolean = false) {
        const co2eNum = parseFloat(org.co2e || '0')
        // org.co2e = org.categoryCo2e
        const _uuid = `${org.uuid}${OrgService.otherOrgSuffix}`
        if (org.children?.length && !org.children.find((o) => o.uuid === _uuid)) {
            if (co2eNum && !org.children.find((o) => OrgService.isTemp(o))) {
                const { children, parent, ...plainOrg } = org
                let _other: Org = {
                    ...plainOrg,
                    uuid: _uuid,
                    co2e: co2eNum.toString(),
                    categoryCo2e: co2eNum.toString(),
                    name: OrgService.otherOrgName,
                    isUncategorized: true,
                    level: org.level + 1,
                    order: 9999,
                }
                if (forFlow) {
                    _other.isUncategorized = undefined
                    _other.parent = org
                    _other.children = []
                }
                org.children.push(_other)
            }
            org.children.forEach((o) => this.setParentUncategorized(o, forFlow))
        }
    }

    public async getStructuredList(skipCache: boolean = false, queryString?: string): Promise<Org[]> {
        const _org = await this.get(skipCache, queryString)
        const uncategorized = _org.find((o) => o.isUncategorized)
        const structured = [...Utils.listToTree<Org>(_org)]
        const flatList = this.getFlatList(structured)
        if (uncategorized) {
            flatList.push(uncategorized)
        }
        return flatList
    }

    public async getSlim(): Promise<Org[]> {
        return this.httpService.get<Org[]>(`${this.basePath}?slim=true`).then(this.setOrgContext)
    }

    private lastQueryString = ''

    public async get(skipCache: boolean = false, queryString?: string): Promise<Org[]> {
        if (!skipCache && this.context.stores.orgs.list.length) {
            return Promise.resolve(this.context.stores.orgs.list)
        }
        const qs = queryString || this.lastQueryString || ''
        if (queryString !== undefined) {
            this.lastQueryString = queryString
        }
        return this.httpService.get<Org[]>(`${this.basePath}?${qs || ''}`).then(this.setOrgContext)
    }

    public async getOrg(orgId: string, skipCache: boolean = false): Promise<Org | undefined> {
        return this.get(skipCache)
            .then((orgs) => orgs.find((o) => o.uuid === orgId))
            .catch(() => undefined)
    }

    public async createOrUpdate(orgWithParent: Org): Promise<Org> {
        const _org: Org = { ...orgWithParent }
        if (_org.parent) {
            delete orgWithParent.parent?.children
        }
        return this.httpService.post<Org>(this.basePath, { body: JSON.stringify({ org: _org }) }).then((_o) => {
            this.get(true).then()
            return _o
        })
    }

    public async updateOrg(org: Org, updateSelectedOrg: boolean = false): Promise<Org> {
        return this.httpService
            .put<Org>(`${this.basePath}/${org.uuid}`, {
                body: JSON.stringify({ org: { ...org, children: undefined } }),
            })
            .then((_org) => {
                const _orgWithUsers = { ..._org, users: this.context.stores.orgs.orgById?.get(_org.uuid!)?.users || [] }
                if (updateSelectedOrg) {
                    this.context.dispatch({ type: OrgActionType.SetOrg, payload: _orgWithUsers })
                }
                return _orgWithUsers
            })
    }

    public async updateAll(orgs: Org[], queryString?: string): Promise<Org[]> {
        return this.httpService
            .put<Org[]>(this.basePath, {
                body: JSON.stringify({ orgs: orgs.map((o) => ({ ...o, children: undefined })) }),
            })
            .then(() => this.get(true, queryString))
    }

    public async moveEmissions(org: Org, newOrg: Org): Promise<Org> {
        return this.httpService.put<Org>(`${this.basePath}/${org.uuid?.replace(OrgService.otherOrgSuffix, '')}`, {
            body: JSON.stringify({ newOrgId: newOrg.uuid }),
        })
    }

    public async addUserToOrg(org: Org, user: User): Promise<Org> {
        return this.httpService
            .put<Org>(`${this.basePath}/${org.uuid}`, {
                body: JSON.stringify({ userIdToAdd: user.uuid }),
            })
            .then((org) => {
                this.context.dispatch({ type: OrgActionType.AddUserToOrg, payload: user })
                return org
            })
    }

    public async removeUserFromOrg(org: Org, user: User): Promise<Org> {
        return this.httpService
            .put<Org>(`${this.basePath}/${org.uuid}`, {
                body: JSON.stringify({ userIdToRemove: user.uuid }),
            })
            .then((org) => {
                this.context.dispatch({ type: OrgActionType.RemoveUserFromOrg, payload: user })
                return org
            })
    }

    public async removeOrg(org: Org): Promise<Org> {
        return this.httpService
            .delete<Org>(`${this.basePath}/${org.uuid}`, { body: JSON.stringify({ org: org }) })
            .then((_org) => {
                this.context.dispatch({ type: OrgActionType.UnsetOrg })
                this.get(true).then()
                return _org
            })
    }
}
