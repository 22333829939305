import { Modal } from '../Modal'
import { Form } from '../Input/Form'
import Utils from '../../services/utils'
import InputField from '../Input/InputField'
import Button from '../Input/Button'
import { useContext, useState } from 'react'
import { WorkspaceActionType } from '../../services/workspace'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import FlagService from '../../services/flag'

export const CreateAccountModal = () => {
    const context = useContext(ApplicationContext)
    const { userService } = useContext(VariableServicesContext)
    const [saving, setSaving] = useState<boolean>(false)
    const [newCompanyName, setNewCompanyName] = useState<string>()

    if (!FlagService.enabledFlags.has('CanCreateAccounts')) {
        return null
    }

    return (
        <Modal
            header='Create Company'
            hidden={!context.stores.workspace.newWorkspace}
            content={
                <Form
                    onSubmit={() => {
                        if (context.stores.user && newCompanyName) {
                            setSaving(true)
                            userService
                                .setCompany(context.stores.user, newCompanyName)
                                .then((ucd) => {
                                    const _company = ucd.companies.find((c) => c.name === newCompanyName)
                                    context.dispatch({ type: WorkspaceActionType.Create, payload: _company })
                                })
                                .catch(Utils.errorToast)
                                .finally(() => setSaving(false))
                        }
                    }}
                >
                    {context.stores.workspace.newWorkspace && (
                        <InputField
                            className='variable-form-control bg-light fs-5 w-100'
                            focusOnRender={true}
                            placeholder='Company name'
                            onChange={(newValue) => setNewCompanyName(newValue)}
                        />
                    )}
                    <Button
                        disabled={!newCompanyName}
                        saving={saving}
                        className='btn btn-sm btn-secondary mt-2 w-100 fs-6'
                        type='submit'
                    >
                        Create
                    </Button>
                </Form>
            }
            onVisibilityChange={(isVisible) => {
                if (!isVisible) {
                    context.dispatch({ type: WorkspaceActionType.Create, payload: undefined })
                }
            }}
        />
    )
}
