import Button from '../Input/Button'
import Utils from '../../services/utils'
import { OrgSelector } from '../Org/OrgSelector'
import { ScopeSelector } from '../Input/ScopeSelector'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ActivityActionType, ActivityContext } from '../../services/activityContext'
import { SlideIn } from '../SlideIn'
import OrgService, { Org } from '../../services/org'
import { DotsSixVertical } from '@phosphor-icons/react'
import { BatchSelector } from '../Input/BatchSelector'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import ActivityService, { ActivityState, activityStates, ActivityStateUpdate } from '../../services/activity'
import { Selector } from '../Input/Selector'
import InputField from '../Input/InputField'
import ProductService, { Product } from '../../services/product'
import { GHGScope } from '../../services/ghg'
import { ProductionBatch } from '../../services/batch'
import { PrettyNumber } from '../PrettyNumber'
import { ProductFilter } from '../Product/ProductFilter'
import { useActivityContext } from '../../hooks/useActivityContext'
import FlagService from '../../services/flag'

export const BulkActivityEditor = () => {
    const context = useContext(ApplicationContext)
    const activityContext = useContext(ActivityContext)
    const { statusService, analyticsService, activityService } = useContext(VariableServicesContext)
    const [fetchingAllIds, setFetchingAllIds] = useState(false)
    const [saving, setSaving] = useState(false)
    const [bulkScope, setBulkScope] = useState<GHGScope>()
    const [bulkProduct, setBulkProduct] = useState<Product>()
    const [bulkOrg, setBulkOrg] = useState<Org>()
    const [bulkBatch, setBulkBatch] = useState<ProductionBatch | null>()
    const [bulkState, setBulkState] = useState<Partial<ActivityStateUpdate>>()
    const { getAllActivityIds } = useActivityContext()

    useEffect(() => {
        return () => {
            activityService.resetBulkIds()
            activityService.setBulkFields()
        }
    }, [])

    const cleanup = useCallback(() => {
        activityService.resetBulkIds()
        setBulkProduct(undefined)
        setBulkOrg(undefined)
        setBulkScope(undefined)
        setBulkBatch(undefined)
        setBulkState(undefined)
    }, [])

    const fetchAllIds = useCallback(() => {
        activityContext.dispatch({ type: ActivityActionType.SetLoading, payload: true })
        setFetchingAllIds(true)
        getAllActivityIds()
            .then((ids) => {
                activityService.setBulkIds(ids)
                analyticsService.track('Activity Bulk Select All', { bulkListSize: ids?.size })
            })
            .finally(() => {
                activityContext.dispatch({ type: ActivityActionType.SetLoading, payload: false })
                setFetchingAllIds(false)
            })
    }, [activityContext.queryString, activityService])

    const filteringBySupplier = useMemo(() => {
        const qs = new URLSearchParams(activityContext.queryString)
        const activityState = qs.get('activityStates') as ActivityState
        const supplierId = qs.get('supplier')
        if (!ActivityService.editableStates.includes(activityState) || !supplierId) return undefined
        return context.stores.suppliers.byId.get(supplierId)
    }, [activityContext.queryString, context.stores.suppliers.byId])

    const canSetProduct = useMemo(() => {
        return (
            FlagService.enabledFlags.has('EnableBulkProductEdit') &&
            context.stores.activity.bulkFields?.includes('product') === true &&
            filteringBySupplier !== undefined
        )
    }, [context.stores.activity.bulkFields, context.stores.ui?.flagsReady, filteringBySupplier])

    if (!context.stores.activity.bulkIds.size) {
        return null
    }

    return (
        <SlideIn
            position='top'
            draggable='y'
            show={context.stores.activity.bulkIds.size > 0}
            onVisibilityChange={(isVisible) => {
                if (!isVisible) {
                    cleanup()
                    analyticsService.track('Activity Reset Bulk List')
                }
            }}
            className='h-auto'
            bodyClassName='offcanvas-body pb-5 mt--3'
            style={{ top: '235px', bottom: 'auto', opacity: 0.95, maxWidth: '100%' }}
        >
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-center gap-2'>
                <DotsSixVertical className='d-none d-md-block nt--1' />
                <Button
                    saving={fetchingAllIds}
                    hidden={activityContext.totalCount === context.stores.activity.bulkIds.size}
                    className='btn btn-sm btn-outline-primary align-self-center w-auto mx-auto position-relative'
                    onClick={fetchAllIds}
                >
                    Select all <PrettyNumber num={activityContext.totalCount} /> items
                </Button>
                <div className='d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 flex-grow-1'>
                    <h5 className='m-0'>
                        Edit <PrettyNumber num={context.stores.activity.bulkIds.size} />{' '}
                        {Utils.pluralize('Activity', context.stores.activity.bulkIds.size)}:
                    </h5>
                    {canSetProduct && (
                        <ProductFilter
                            disabled={!!bulkState}
                            placeholder={ProductService.elementTitle()}
                            product={bulkProduct}
                            className='variable-form-select active'
                            productOf={filteringBySupplier}
                            placement='bottom-start'
                            onSelect={(_product) => {
                                setBulkState(undefined)
                                setBulkProduct(_product)
                                analyticsService.track(`Activity Bulk Select ${ProductService.webTitle()}`, {
                                    uuid: _product.uuid,
                                    name: _product.name,
                                    bulkListSize: context.stores.activity.bulkIds.size,
                                })
                            }}
                        />
                    )}
                    {context.stores.activity.bulkFields?.includes('org') && (
                        <OrgSelector
                            className='variable-form-select active'
                            placement='bottom-start'
                            onSelect={(_org) => {
                                setBulkOrg(_org)
                                analyticsService.track(`Activity Bulk Select ${OrgService.webTitle()}`, {
                                    uuid: _org?.uuid,
                                    name: _org?.name,
                                    bulkListSize: context.stores.activity.bulkIds.size,
                                })
                            }}
                        />
                    )}
                    {context.stores.activity.bulkFields?.includes('scope') && (
                        <ScopeSelector
                            className='variable-form-select active'
                            placement='bottom-start'
                            onSelect={(scope) => {
                                setBulkScope(scope)
                                analyticsService.track('Activity Bulk Select Scope', {
                                    uuid: scope?.uuid,
                                    name: scope?.name,
                                    bulkListSize: context.stores.activity.bulkIds.size,
                                })
                            }}
                        />
                    )}
                    {FlagService.enabledFlags.has('UseBatches') &&
                        context.stores.activity.bulkFields?.includes('batch') && (
                            <BatchSelector
                                className='variable-form-select active'
                                placement='bottom-start'
                                showNoValueOption={true}
                                onSelect={(batch) => {
                                    setBulkBatch(batch)
                                    analyticsService.track('Activity Bulk Select Batch', {
                                        uuid: batch?.uuid,
                                        name: batch?.name,
                                        bulkListSize: context.stores.activity.bulkIds.size,
                                    })
                                }}
                            />
                        )}
                    {context.stores.activity.bulkFields?.includes('state') && (
                        <div>
                            <Selector
                                placeholder='Status'
                                className='variable-form-select active'
                                placement='bottom-start'
                                disabled={!!bulkProduct}
                                options={activityStates}
                                noValueOption='No change'
                                onSelect={(state) => {
                                    setBulkProduct(undefined)
                                    setBulkState({ ...bulkState, newState: state.value })
                                    analyticsService.track('Activity Bulk Select Status', {
                                        name: state.name,
                                        bulkListSize: context.stores.activity.bulkIds.size,
                                    })
                                }}
                            />
                            {bulkState?.newState && (
                                <InputField
                                    onChange={(newValue) => setBulkState({ ...bulkState, message: newValue })}
                                    focusOnRender={true}
                                    disabled={!!bulkProduct}
                                    placeholder='Optional message attached to this status change'
                                    style={{ width: '12rem' }}
                                    multiLine={true}
                                    rows={3}
                                    className='d-block variable-form-control active active-border mb-3'
                                />
                            )}
                        </div>
                    )}
                    <Button
                        saving={saving}
                        disabled={!bulkOrg && !bulkScope && !bulkProduct && !bulkState && bulkBatch === undefined}
                        className='btn btn-sm btn-secondary'
                        onClick={() => {
                            setSaving(true)
                            activityContext.dispatch({ type: ActivityActionType.SetLoading, payload: true })
                            activityService
                                .bulkEditActivities(context.stores.activity.bulkIds, {
                                    org: bulkOrg,
                                    scope: bulkScope,
                                    batch: bulkBatch,
                                    product: bulkProduct,
                                    stateUpdate: bulkState,
                                })
                                .then(() => {
                                    activityService.bulkUpdateDone()
                                    analyticsService.track('Activity Bulk Submit', {
                                        product: { uuid: bulkProduct?.uuid, name: bulkProduct?.name },
                                        org: { uuid: bulkOrg?.uuid, name: bulkOrg?.name },
                                        batch: { uuid: bulkBatch?.uuid, name: bulkBatch?.name },
                                        scope: { uuid: bulkScope?.uuid, name: bulkScope?.name },
                                        state: { name: bulkState?.newState },
                                        bulkListSize: context.stores.activity.bulkIds.size,
                                    })
                                    cleanup()
                                    statusService.getStatus()
                                    Utils.successToast(
                                        <>
                                            <PrettyNumber num={context.stores.activity.bulkIds.size} />{' '}
                                            {ActivityService.webTitle(context.stores.activity.bulkIds.size !== 1)}{' '}
                                            updated
                                        </>,
                                    )
                                })
                                .finally(() => {
                                    setSaving(false)
                                    activityContext.dispatch({ type: ActivityActionType.SetLoading, payload: false })
                                })
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </SlideIn>
    )
}
