import { useContext, useEffect } from 'react'
import { User } from '../../services/user-context'
import { Selector, SelectorProps } from '../Input/Selector'
import { UserCard } from '../User/UserCard'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'

export type MemberSearchProps = SelectorProps & {
    member?: User
    filter?: (member: User) => boolean
    onSelect: (member: User) => void
}

const MemberSearch = (props: MemberSearchProps) => {
    const context = useContext(ApplicationContext)
    const { companyService } = useContext(VariableServicesContext)

    useEffect(() => {
        companyService.getMembers(true).then()
    }, [])

    return (
        <Selector
            {...props}
            placeholder={props.placeholder || 'Select'}
            label={
                props.label || (props.member?.uuid ? <UserCard user={props.member} /> : props.placeholder || 'Select')
            }
            options={context.stores.members.map((m) => m.user).filter(props.filter || (() => true))}
            option={props.member?.uuid}
            filterBy={(member, filter) => {
                if (!filter) {
                    return true
                }
                return (
                    member.name.toLowerCase().includes(filter.toLowerCase()) ||
                    member.email.toLowerCase().includes(filter.toLowerCase())
                )
            }}
            renderItemValue={(member) => (
                <span className='d-block text-height-1 py-1'>
                    <UserCard user={member} />
                    <span className='d-block small text-muted'>{member.email}</span>
                </span>
            )}
        />
    )
}

export default MemberSearch
