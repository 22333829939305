import Utils from '../services/utils'
import { PrettyNumber } from './PrettyNumber'
import { CSSProperties, useMemo } from 'react'

export interface PercentBarSegment {
    key?: string
    name: string
    description?: string
    amount?: number
    percent?: number
    color?: string
    className?: string
    style?: CSSProperties
    order?: number
}

export type PercentBarProps = {
    extraClassName?: string
    total: number
    height?: string
    showLabels?: boolean
    precision?: number
    fillLast?: boolean
    segments: PercentBarSegment[]
}

export const PercentBar = (props: PercentBarProps) => {
    const labels = useMemo(() => {
        if (!props.showLabels) return null
        return (
            <div className='d-flex align-items-center justify-content-between'>
                {props.segments.map((segment, idx) => {
                    let textAlignment = 'text-center'
                    let flexAlignment = 'justify-content-center'
                    if (idx === 0) {
                        textAlignment = 'text-start'
                        flexAlignment = 'justify-content-start'
                    } else if (idx === props.segments.length - 1) {
                        textAlignment = 'text-end'
                        flexAlignment = 'justify-content-end'
                    }
                    return (
                        <span key={`seg-label-${segment.name}`} className={`small ${textAlignment}`}>
                            <span className='d-block text-alt-font'>{segment.name}</span>
                            <span className={`d-flex align-items-center ${flexAlignment}`}>
                                <span
                                    className={`d-inline-block rounded-circle me-1 ${segment.className}`}
                                    style={{
                                        width: '.75rem',
                                        height: '.75rem',
                                    }}
                                />
                                <PrettyNumber
                                    num={segment.amount}
                                    precision={2}
                                    showZeroesToPrecision={false}
                                    showZeroesToNumber={true}
                                    extraClassName='nt-1'
                                />
                            </span>
                        </span>
                    )
                })}
            </div>
        )
    }, [props.segments, props.showLabels])

    const bar = useMemo(() => {
        let addedUpTo = 0
        return (
            <div
                className={`position-relative d-flex rounded-3 overflow-hidden ${props.extraClassName || ''}`}
                style={{ height: props.height || '4px' }}
            >
                {props.segments.map((pbs, idx) => {
                    const _amount = pbs.amount || 0
                    let [, percentNumber] = Utils.percentOfTotal(Math.abs(_amount), props.total, props.precision)
                    addedUpTo += percentNumber
                    if (props.fillLast && idx === props.segments.length - 1 && addedUpTo < 100) {
                        percentNumber += 100 - addedUpTo
                    }
                    const className = _amount < 0 ? `${pbs.className}-striped` : pbs.className
                    return (
                        <div
                            key={`seg-bar-${pbs.name}`}
                            className={`transition-all-300ms ${className}`}
                            style={{
                                height: props.height || '4px',
                                top: 0,
                                bottom: 0,
                                width: `${percentNumber}%`,
                                maxWidth: '100%',
                                ...pbs.style,
                            }}
                        />
                    )
                })}
            </div>
        )
    }, [props.segments, props.height, props.extraClassName, props.total, props.precision])

    if (props.segments.length === 0) {
        return null
    }

    return (
        <>
            {labels}
            {bar}
        </>
    )
}
