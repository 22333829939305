import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Utils from '../../services/utils'
import PartService, { Part, PartActionType, PartType, partTypeOptions } from '../../services/part'
import { Link, useLocation } from 'react-router-dom'
import ProductService from '../../services/product'
import CO2e from '../CO2e'
import { SlideIn } from '../SlideIn'
import { UnitSelector } from '../Input/UnitSelector'
import { Selector } from '../Input/Selector'
import { AmountOfEmissionFactor } from '../Product/AmountOfEmissionFactor'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import UnitService from '../../services/unit'
import { TitleEditor } from '../Input/TitleEditor'
import { Footnotes } from '../Footnotes'

import { PartSourceProducts } from './PartSourceProducts'
import { Input } from '../../services/input'
import { usePart } from '../../hooks/usePart'
import { useInput } from '../../hooks/useInput'

export const PartEditor = () => {
    const context = useContext(ApplicationContext)
    const location = useLocation()
    const { partService, productService, inputService, analyticsService } = useContext(VariableServicesContext)
    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        return () => context.dispatch({ type: PartActionType.DeselectPart })
    }, [location.pathname])

    const part = usePart({ partId: context.stores.parts.selectedId })

    const fetchPart = useCallback(() => {
        if (!context.stores.parts.selectedId) return
        partService.getPart(context.stores.parts.selectedId).catch(Utils.errorToast)
    }, [context.stores.parts.selectedId])

    const updateInput = useCallback((input?: Partial<Input>) => {
        if (!input?.uuid) return
        inputService.updateInput(input).catch(Utils.errorToast)
    }, [])

    const partInput = useInput({ inputId: context.stores.parts.inputId })

    const showCo2e = useMemo(() => part?.type === PartType.MIX || part?.sourceProductCount === 0, [part?.uuid])

    const updatePart = useCallback(
        (_part: Partial<Part>) => {
            if (!part) return
            setSaving(true)
            partService
                .createOrUpdatePart({ ...part, ..._part })
                .catch(Utils.errorToast)
                .finally(() => setSaving(false))
        },
        [part],
    )

    const header = useMemo(() => {
        if (!part?.uuid) return null
        return (
            <TitleEditor
                node={part}
                inputPlaceholder={`${PartService.webTitle()} name`}
                inputExtraClassName='fs-5'
                onChanged={(name) => updatePart({ name })}
            />
        )
    }, [part, updatePart])

    return (
        <SlideIn
            style={{ width: '500px', maxWidth: '90%' }}
            show={part?.uuid !== undefined}
            ariaLabel={`${PartService.webTitle()} Editor`}
            expandLink={PartService.getPartUrl(part)}
            onVisibilityChange={(isVisible) => {
                if (!isVisible) {
                    context.dispatch({ type: PartActionType.DeselectPart })
                    analyticsService.track('Element Detail: Close')
                } else {
                    analyticsService.track('Element Detail: Open')
                }
            }}
            useBackdrop={true}
            header={header}
        >
            {part?.uuid && (
                <div className='d-flex flex-column flex-grow-1'>
                    <div className='d-flex flex-column gap-3 flex-grow-1'>
                        <div className='d-flex align-items-baseline justify-content-between gap-2 px-2'>
                            <div className='d-flex align-items-baseline'>
                                {showCo2e && (
                                    <>
                                        <CO2e
                                            className='fs-5'
                                            co2e={part.liveCo2e}
                                            unitSize='small'
                                            unitsClassName='very-small'
                                        />
                                        <span className='px-2'>/</span>
                                    </>
                                )}
                                {!showCo2e && <span className='small me-1'>Unit:</span>}
                                <UnitSelector
                                    placeholder='Select'
                                    placement='bottom-start'
                                    className='variable-form-select active'
                                    unit={part.unit}
                                    onChange={(unit) => updatePart({ unit })}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <span className='small'>Type:</span>
                                <Selector
                                    options={partTypeOptions}
                                    option={part.type}
                                    className='variable-form-select active'
                                    placeholder='Select'
                                    placement='bottom-start'
                                    hideTextFilter={true}
                                    onSelect={(newValue) => updatePart({ type: newValue.value })}
                                />
                            </div>
                        </div>
                        <PartSourceProducts
                            part={part}
                            input={partInput}
                            sourceProducts={part?.sourceProducts}
                            onSelect={async (sp) => {
                                if (!partInput?.uuid) {
                                    Utils.errorToast('No input selected')
                                    return
                                }
                                if (!sp.uuid) return
                                const _sp = await productService.getById(sp.uuid)
                                updateInput({
                                    uuid: partInput?.uuid,
                                    sourceProduct: _sp,
                                    part: part,
                                    unit: UnitService.getNewUnit(partInput?.unit, _sp),
                                    supplier: _sp.productOf || undefined,
                                })
                            }}
                            onWeightsUpdated={() => {
                                updateInput({ uuid: partInput?.uuid })
                                fetchPart()
                            }}
                            onRemove={() => {
                                updateInput({ uuid: partInput?.uuid })
                                fetchPart()
                            }}
                        />
                        <div
                            hidden={Utils.dayjs().subtract(5, 'seconds').isAfter(part.created)}
                            className='bg-secondary bg-opacity-10 p-2 rounded-2 shadow'
                        >
                            Here's your newly created {PartService.webTitle()}. You can add and remove alternatives, and
                            the list will be updated everywhere this {PartService.webTitle()} is used.
                        </div>
                        <div hidden={(part.partOf?.length || 0) <= 1} className='bg-light p-2 rounded-2 small shadow'>
                            ⚠️ <strong>Note:</strong> Elements are global. Changes made here will also affect the
                            options available in the following footprints:
                            {part.partOf?.map((po) => (
                                <Link
                                    key={`part-of-${po.uuid}`}
                                    className='d-block underline-on-hover mt-1'
                                    to={ProductService.getProductUrl(po)}
                                >
                                    {po.name}
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className='mt-3'>
                        <div className='bg-light rounded-2 p-2'>
                            <div className='fs-base mb-1'>
                                Database {ProductService.footprintTitle().toLowerCase()}
                                <div className='text-muted small'>
                                    This is used as a fallback when there is no supplier data available.
                                </div>
                            </div>
                            <AmountOfEmissionFactor
                                targetNode={part}
                                targetType='Part'
                                factor={part?.emissionFactor}
                                onChange={(factor) =>
                                    updatePart({ emissionFactor: factor, unit: part?.unit || factor.unit })
                                }
                            />
                        </div>
                        <Footnotes node={part} saving={saving} />
                    </div>
                </div>
            )}
        </SlideIn>
    )
}
