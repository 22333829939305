import Utils from '../../services/utils'
import PartService from '../../services/part'
import { DataImport } from '../../services/dataImport'
import { PrettyNumber } from '../PrettyNumber'

export const DataImportNodes = (props: { dataImport: DataImport; className?: string }) => {
    const nodeLength = (props.dataImport.nodes?.length || 0) - 1
    const nodeList = props.dataImport.nodes?.map((node, idx) => (
        <span key={`din-${props.dataImport.uuid}-${idx}`} hidden={!node.type}>
            <span className='text-nowrap'>
                <PrettyNumber num={node.count} />{' '}
                {Utils.pluralize(node.type === 'Part' ? PartService.webTitle() : node.type, node.count)}
            </span>
            {idx < nodeLength && ', '}
        </span>
    ))
    return <span className={props.className}>{nodeList}</span>
}
