import { VariableBaseNode } from '../types'
import VariableService from './service'
import { ActionMap } from '../context'
import { ReactNode } from 'react'
import { Dayjs } from 'dayjs'
import Utils from './utils'

export interface StatusMessage extends VariableBaseNode {
    message?: string
    item?: ReactNode
}

export interface StatusResponse extends VariableBaseNode {
    ready: boolean
    messages: StatusMessage[]
    activityRange: [number, number]
    activityPendingCount: number
    pendingSupplierRequestCount: number
    pendingCustomerRequestCount: number
    uiVersion?: string
    apiVersion?: string
}

export const initialStatusState: StatusResponse = {
    ready: false,
    messages: [],
    activityRange: [0, 0],
    activityPendingCount: 0,
    pendingSupplierRequestCount: 0,
    pendingCustomerRequestCount: 0,
}

export enum StatusActionType {
    Set = 'SetStatus',
}

type StatusActionPayload = {
    [StatusActionType.Set]: StatusResponse
}

export type StatusActions = ActionMap<StatusActionPayload>[keyof ActionMap<StatusActionPayload>]

export const StatusReducer = (state: StatusResponse, action: StatusActions): StatusResponse => {
    switch (action.type) {
        case StatusActionType.Set:
            return action.payload
        default:
            return state
    }
}

export default class StatusService extends VariableService {
    private basePath: string = '/status'

    private lastBackgroundCheck: Dayjs = Utils.dayjs()

    public backgroundStatusCheck(): void {
        const now = Utils.dayjs()
        const diff = now.diff(this.lastBackgroundCheck, 'seconds')
        if (diff < 5) {
            return
        }
        this.getStatus()
        this.lastBackgroundCheck = now
    }

    public getStatus(queryString?: string): void {
        if (!this.context.stores.user?.uuid) return
        this.httpService.get<StatusResponse>(`${this.basePath}?${queryString || ''}`).then((s) => {
            this.context.dispatch({ type: StatusActionType.Set, payload: s })
        })
    }
}
