import { ActionMap } from '../context'
import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { DataRequest, DataRequestPerspective } from './dataRequest'
import { ListResponse } from '../types'

export enum DataRequestActionType {
    Add = 'AddDataRequest',
    Update = 'UpdateDataRequest',
    BackgroundUpdate = 'BackgroundUpdateDataRequest',
    Remove = 'RemoveDataRequest',
    Select = 'SelectDataRequest',
    SetPerspective = 'SetDataRequestPerspective',
    Deselect = 'DeselectDataRequest',
    Reset = 'ResetDataRequests',
    SetDataRequestData = 'SetDataRequestData',
}

type DataRequestListActionPayload = {
    [DataRequestActionType.Add]: DataRequest
    [DataRequestActionType.Update]: DataRequest
    [DataRequestActionType.BackgroundUpdate]: DataRequest
    [DataRequestActionType.Remove]: DataRequest
    [DataRequestActionType.Select]: DataRequest
    [DataRequestActionType.SetPerspective]: DataRequestPerspective
    [DataRequestActionType.Deselect]: undefined
    [DataRequestActionType.Reset]: undefined
    [DataRequestActionType.SetDataRequestData]: ListResponse
}

type Actions = ActionMap<DataRequestListActionPayload>[keyof ActionMap<DataRequestListActionPayload>]

interface IDataRequestContext {
    totalCount: number
    dataRequests: DataRequest[]
    dataRequest?: DataRequest
    drPerspective?: DataRequestPerspective
}

const mainReducer = (state: IDataRequestContext, action: Actions): IDataRequestContext => {
    switch (action.type) {
        case DataRequestActionType.SetDataRequestData:
            return {
                ...state,
                totalCount: action.payload.count,
                dataRequests: action.payload.data as DataRequest[],
            }
        case DataRequestActionType.Add:
            return { ...state, dataRequests: [action.payload, ...state.dataRequests] }
        case DataRequestActionType.Update:
            return {
                ...state,
                dataRequests: state.dataRequests.map((dr) => {
                    if (dr.uuid === action.payload.uuid) {
                        return action.payload
                    }
                    return dr
                }),
                dataRequest: action.payload,
            }
        case DataRequestActionType.BackgroundUpdate:
            return {
                ...state,
                dataRequests: state.dataRequests.map((dr) => {
                    if (dr.uuid === action.payload.uuid) {
                        return action.payload
                    }
                    return dr
                }),
            }
        case DataRequestActionType.Remove:
            return {
                ...state,
                dataRequests: state.dataRequests.filter((dr) => dr.uuid !== action.payload.uuid),
                dataRequest: undefined,
            }
        case DataRequestActionType.Select:
            return { ...state, dataRequest: action.payload }
        case DataRequestActionType.SetPerspective:
            return { ...state, drPerspective: action.payload }
        case DataRequestActionType.Deselect:
            return { ...state, dataRequest: undefined, drPerspective: undefined }
        case DataRequestActionType.Reset:
            return initialState
        default:
            return state
    }
}

const initialState: IDataRequestContext = {
    totalCount: 0,
    dataRequests: [],
    dataRequest: undefined,
    drPerspective: undefined,
}

export interface DataRequestContextInterface extends IDataRequestContext {
    dispatch: Dispatch<Actions>
}

export const DataRequestContext = createContext<DataRequestContextInterface>({
    ...initialState,
    dispatch: () => null,
})

export const DataRequestProvider = ({ children }: { children: ReactNode }) => {
    const [stores, dispatch] = useReducer(mainReducer, initialState)
    return <DataRequestContext.Provider value={{ ...stores, dispatch }}>{children}</DataRequestContext.Provider>
}
