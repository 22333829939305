import { ListResponse, VariableBaseNode } from '../types'
import VariableService from './service'

export interface Tag extends VariableBaseNode {
    slug: string
}

export interface TagResponse {
    tags: Tag[]
}

export default class TagService extends VariableService {
    private basePath: string = '/tag'

    public getTagsForNode(nodeId: string): Promise<TagResponse> {
        return this.httpService.get<TagResponse>(`${this.basePath}/node/${nodeId}`)
    }

    public getNodesForTag(tagSlug: string): Promise<ListResponse> {
        return this.httpService.get<ListResponse>(`${this.basePath}/${tagSlug}`)
    }

    public tagNode(tagSlug: string, nodeId: string): Promise<TagResponse> {
        return this.httpService.post<TagResponse>(this.basePath, {
            body: JSON.stringify({
                tag: tagSlug,
                node: nodeId,
            }),
        })
    }

    public removeTagFromNode(tagSlug: string, nodeId: string): Promise<TagResponse> {
        return this.httpService.delete<TagResponse>(`${this.basePath}/node/${nodeId}/tag/${tagSlug}`)
    }
}
