import Utils, { LocalOption } from './utils'
import { GenericObject } from '../types'
import VariableService from './service'
import { InventoryService } from './inventory'

export class AnalyticsService extends VariableService {
    private getCommonProperties = () => {
        return {
            userId: this.context.stores.user?.uuid,
            companyId: this.context.stores.company?.uuid,
            companyName: this.context.stores.company?.name,
            uiVersion: this.context.stores.ui?.uiVersion,
            apiVersion: this.context.stores.status?.apiVersion,
            plan:
                this.context.stores.company?.subscription?.stripeName ||
                this.context.stores.company?.subscription?.name,
        }
    }

    public track = (event: string, properties?: GenericObject, options?: any, callback?: () => void) => {
        const props = {
            ...this.getCommonProperties(),
            ...properties,
        }
        if (Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA)) {
            console.debug('Track', event, props, options)
        }
        analytics.track(event, props, options, callback)
    }

    private pageAnalyticsTimer: NodeJS.Timeout | undefined = undefined
    public page = (
        category?: string,
        name?: string,
        properties?: GenericObject,
        options?: any,
        callback?: () => void,
    ) => {
        if (this.pageAnalyticsTimer) {
            clearTimeout(this.pageAnalyticsTimer)
        }
        const props = {
            ...this.getCommonProperties(),
            ...properties,
        }
        this.pageAnalyticsTimer = setTimeout(() => {
            if (Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA)) {
                console.debug('Page', `[${document?.location?.pathname}]`, category, name, props, options, callback)
            }
            analytics.page(category, name, props, options, callback)
        }, 300)
    }

    public identify = (userId: string, traits?: Object, options?: any, callback?: () => void) => {
        if (Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA)) {
            console.debug('Identify', userId, traits, options, callback)
        }
        analytics.identify(userId, traits, options, callback)
    }

    public group = (groupId?: string, traits?: any) => {
        if (Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA)) {
            console.debug('Group', groupId, traits)
        }
        if (groupId) {
            analytics.group(groupId, traits)
        }
    }

    public reset = () => {
        if (Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA)) {
            console.debug('Reset')
        }
        analytics.reset()
    }

    public trackSupplierCreated = () => {
        this.track('Supplier Created', { supplierCount: this.context.stores.suppliers.list.length })
    }

    public trackCustomerCreated = () => {
        this.track('Customer Created', { customerCount: this.context.stores.customers.list.length })
    }

    public trackProductFootprintCreated = () => {
        const _products = InventoryService.list.filter(
            (inv) => inv.productOf?.uuid === this.context.stores.company?.uuid,
        )
        this.track('Product Footprint Created', { productFootprintCount: _products.length })
    }

    public trackActivityCreated = (props?: GenericObject) => {
        this.track('Created Activity', props)
    }
}
