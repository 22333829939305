import { GeneralError, GenericObject, HttpErrors } from '../types'
import AuthenticationService from './authentication'
import CompanyService from './company'
import { ApplicationContextInterface } from '../context'
import Utils from './utils'

export type RequestHeaders = {
    Authorization?: string
    'Content-Type'?: string
    'Variable-Company'?: string
    'Variable-Token'?: string
    timezone?: string
}

export type RequestConfig = {
    headers?: RequestHeaders
    body?: BodyInit | null
    json?: boolean
    auth?: boolean
    cache?: boolean
    isRetry?: boolean
    responseType?: 'json' | 'text'
    ttlInSeconds?: number
}

interface CacheItem {
    count: number
    fetching: boolean
    result: any
    ttl: Date
    resolvers: any[]
}

export type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'

class HttpService {
    private static _apiOnline: boolean | undefined = undefined
    private cache: GenericObject = {}

    public static databaseSubdomains: string[] = ['db', 'data', 'database']

    public static subdomains: string[] = [
        'local',
        'api',
        'dev',
        'docs',
        'documentation',
        'dev-app',
        'dev-data',
        'app',
        'cascade',
        'dev.cascade',
        ...this.databaseSubdomains,
    ]

    public static isDatabaseSubdomain = (hostname?: string): boolean => {
        if (process.env.REACT_APP_VARIABLE_ENV === 'prod' && hostname === 'variable.global') {
            return true
        }
        const subdomains = hostname?.split('.') || []
        if (subdomains.length > 2) {
            return this.databaseSubdomains.includes(subdomains[0])
        }
        return false
    }

    public static getSubdomain = (): string => {
        const subdomains = document.location?.hostname?.split('.') || []
        if (subdomains.length <= 2) {
            return ''
        }
        let subdomain = subdomains.shift()
        if (process.env.REACT_APP_VARIABLE_ENV !== 'prod' && subdomain === 'dev') {
            subdomain = subdomains.shift()
        }
        if (subdomain && !this.subdomains.includes(subdomain)) {
            return subdomain
        }
        return ''
    }

    private context: ApplicationContextInterface

    constructor(context: ApplicationContextInterface) {
        this.context = context
    }

    public setContext(context: ApplicationContextInterface) {
        this.context = context
    }

    public static get apiOnline(): boolean | undefined {
        return this._apiOnline || undefined
    }

    public static set apiOnline(online) {
        this._apiOnline = online
    }

    public get<T>(path: string, config?: RequestConfig): Promise<T> {
        return this.fetch<T>('get', path, config)
    }

    public post<T>(path: string, config?: RequestConfig): Promise<T> {
        return this.fetch<T>('post', path, config)
    }

    public put<T>(path: string, config?: RequestConfig): Promise<T> {
        return this.fetch<T>('put', path, config)
    }

    public patch<T>(path: string, config?: RequestConfig): Promise<T> {
        return this.fetch<T>('patch', path, config)
    }

    public delete<T>(path: string, config?: RequestConfig): Promise<T> {
        return this.fetch<T>('delete', path, config)
    }

    public async fetch<T>(method: HttpMethod, path: string, config?: RequestConfig): Promise<T> {
        if (!HttpService.apiOnline && path !== '/health') {
            throw new Error('API is not online')
        }
        const cacheKey = `${method}:${path}:${JSON.stringify(config || {})}`
        const cacheItem = this.getCacheItem(cacheKey)
        // if (cacheItem.count > 3) {
        //     throw new Error('Rate limit')
        // }
        if (method === 'get' && cacheItem.result && cacheItem.ttl && Utils.dayjs().isBefore(cacheItem.ttl)) {
            return cacheItem.result as T
        }

        if (!path.startsWith(AuthenticationService.basePath)) {
            await AuthenticationService.checkRefreshToken()
        }

        if (cacheItem.fetching) {
            return new Promise<T>((resolve) => {
                cacheItem.resolvers.push(resolve)
            })
        }
        cacheItem.fetching = true
        const headers = this.getHeaders(config)
        let reqConfig: RequestInit = {
            method: method.toUpperCase(),
            headers: headers,
        }
        if (config?.body) {
            reqConfig.body = config.body
        }
        const req = new Request(`/api${path}`, reqConfig)
        return fetch(req).then(async (response) => {
            cacheItem.fetching = false

            let responseData: any
            if (config?.responseType === 'text') {
                responseData = await response.text()
            } else {
                responseData = await response.json()
            }

            if (!response.ok) {
                if (responseData.message === HttpErrors.TOKEN_EXPIRED && !config?.isRetry) {
                    try {
                        await AuthenticationService.refreshAccessToken()
                        return this.fetch(method, path, { ...config, isRetry: true })
                    } catch (e) {
                        AuthenticationService.sendUserToAuth()
                        throw new GeneralError('Token error')
                    }
                }
                const err = new GeneralError(responseData?.message || response.statusText)
                err.setStatus(response.status)
                throw err
            }

            if (method === 'get' && config?.cache !== false) {
                cacheItem.ttl = Utils.dayjs()
                    .second(config?.ttlInSeconds || 1)
                    .toDate()
                cacheItem.result = responseData
            }
            cacheItem.resolvers.forEach((r) => r(responseData))
            cacheItem.fetching = false
            return responseData
        })
    }

    public static async fetch<T>(method: HttpMethod, path: string, config?: RequestConfig): Promise<T> {
        let reqConfig: RequestInit = {
            method: method,
            headers: { 'Content-Type': 'application/json' },
        }
        if (config?.body) {
            reqConfig.body = config.body
        }
        const req = new Request(`/api${path}`, reqConfig)
        return fetch(req).then(async (response) => {
            const json = await response.json()
            if (!response.ok) {
                const err = new GeneralError(json?.message || response.statusText)
                err.setStatus(response.status)
                throw err
            }
            return json
        })
    }

    private getCacheItem(id: string): CacheItem {
        if (!this.cache[id]) {
            this.cache[id] = {
                count: 0,
                fetching: false,
                resolvers: [],
            }
        }
        this.cache[id].count++
        setTimeout(() => {
            this.cache[id].count--
        }, 1000)
        return this.cache[id]
    }

    private getHeaders(config: RequestConfig = {}) {
        const headers: RequestHeaders = {
            ...config.headers,
        }
        try {
            headers['timezone'] = window.Intl.DateTimeFormat().resolvedOptions().timeZone
        } catch (e) {
            // do nothing.
        }
        if (CompanyService.companyId) {
            headers['Variable-Company'] = CompanyService.companyId
        }
        if (config.json !== false) {
            headers['Content-Type'] = 'application/json'
            delete config.json
        }
        if (!AuthenticationService.accessToken && this.context.stores.tokenValue) {
            headers['Variable-Token'] = this.context.stores.tokenValue
        }
        if (config.auth !== false && AuthenticationService.accessToken) {
            headers.Authorization = AuthenticationService.accessToken
            delete config.auth
        }
        return headers
    }
}

export default HttpService
