import { Selector, SelectorProps } from './Selector'
import { useContext, useEffect, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import { KeyValuePair } from '../../types'
import TaxonomyService, { Taxonomy } from '../../services/taxonomy'
import { ApplicationContext } from '../../context'

interface TaxonomySelectorProps extends SelectorProps {
    filterList?: (o: KeyValuePair) => boolean
    onTaxonomy?: (taxonomy?: Taxonomy) => void
}

export const TaxonomySelector = (props: TaxonomySelectorProps) => {
    const context = useContext(ApplicationContext)
    const { taxonomyService } = useContext(VariableServicesContext)

    useEffect(() => {
        taxonomyService.get().then()
    }, [])

    const options = useMemo(() => {
        let list = [...TaxonomyService.optionList]
        if (props.filterList) list = list.filter(props.filterList)
        return list
    }, [props.filterList, context.stores.ui?.taxonomyReady])

    return (
        <Selector
            placeholder='Category'
            placement='bottom-start'
            options={options}
            {...props}
            onSelect={(newValue) => {
                props.onSelect(newValue)
                props.onTaxonomy?.(TaxonomyService.getPlainTaxonomy(newValue.node))
            }}
        />
    )
}
