import { CSSProperties, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import Button from './Input/Button'
import { ArrowsOutSimple, X } from '@phosphor-icons/react'
import { UIOptionActionType } from '../services/ui'
import { ApplicationContext } from '../context'
import { Link } from 'react-router-dom'

export const SlideIn = (props: {
    id?: string
    ariaLabel?: string
    header?: ReactNode
    children: ReactNode
    show?: boolean
    position?: 'start' | 'end' | 'top' | 'bottom'
    draggable?: 'x' | 'y' | 'xy'
    useBackdrop?: boolean
    expandLink?: string
    className?: string
    bodyClassName?: string
    style?: CSSProperties
    onVisibilityChange: (isVisible: boolean) => void
}) => {
    const context = useContext(ApplicationContext)
    const [ready, setReady] = useState<boolean>(false)
    const [slideInId] = useState(props.id || `slide-in-${Math.random()}`)
    const [offsetLeft, setOffsetLeft] = useState<number | undefined>(
        props.style?.left ? parseInt(props.style.left.toString()) : undefined,
    )
    const [offsetTop, setOffsetTop] = useState<number | undefined>(
        props.style?.top ? parseInt(props.style.top.toString()) : undefined,
    )
    const [startDragX, setStartDragX] = useState<number>()
    const [startDragY, setStartDragY] = useState<number>()
    const [dragging, setDragging] = useState<boolean>(false)
    const [extraClassName, setExtraClassName] = useState<string>('')
    const backdropRef = useRef<any>()
    const contentRef = useRef<any>()

    useEffect(() => setReady(true), [])

    useEffect(() => setVisibility(props.show === true), [props.show])

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mouseup', onMouseUp)
            document.addEventListener('mousemove', onMouseMove)
        }
    }, [dragging])

    useEffect(() => {
        if (context.stores.ui?.layers?.[0] === slideInId) {
            setVisibility(false)
        }
    }, [context.stores.ui?.escapeKey])

    const setVisibility = (isVisible: boolean) => {
        // console.log('setVisibility', isVisible)
        if (isVisible) {
            context.dispatch({ type: UIOptionActionType.AddUiLayer, payload: slideInId })
        } else if (ready) {
            setTimeout(() => {
                context.dispatch({ type: UIOptionActionType.RemoveUiLayer, payload: slideInId })
            }, 100)
        }
        if (!contentRef.current) {
            return
        }
        if (isVisible && !extraClassName) {
            setExtraClassName('visible')
            setTimeout(() => {
                setExtraClassName('visible show')
                contentRef.current?.focus()
                props.onVisibilityChange && props.onVisibilityChange(isVisible)
            })
        } else if (extraClassName) {
            setExtraClassName('visible')
            setTimeout(() => {
                setExtraClassName('')
                props.onVisibilityChange && props.onVisibilityChange(isVisible)
            }, 300)
        }
    }

    const onMouseUp = () => {
        setDragging(false)
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
    }

    const onMouseMove = (e: MouseEvent) => {
        if (dragging) {
            if (props.draggable?.includes('y')) {
                const _diff = e.pageY - (startDragY || 0)
                setOffsetTop(Math.max(10, (offsetTop || 0) + _diff))
                setStartDragY(e.pageY)
            }
            if (props.draggable?.includes('x')) {
                const _diff = e.pageX - (startDragX || 0)
                setOffsetLeft((offsetLeft || 0) + _diff)
                setStartDragX(e.pageX)
            }
        }
    }

    return (
        <>
            <div
                ref={contentRef}
                role='link'
                aria-label={`Panel: ${props.ariaLabel || ''}`}
                onMouseDown={(e) => {
                    if (props.draggable) {
                        setStartDragX(e.pageX)
                        setStartDragY(e.pageY)
                        setDragging(true)
                    }
                }}
                className={[
                    `offcanvas offcanvas-${props.position || 'end'} shadow-lg z-index-off-canvas border-0 rounded-4`,
                    props.style?.top ? '' : 'mt-6 mb-4',
                    props.style?.left ? '' : 'mx-3',
                    props.className,
                    extraClassName,
                ].join(' ')}
                tabIndex={-1}
                id={slideInId}
                style={{
                    maxWidth: '90%',
                    ...props.style,
                    cursor: dragging ? 'grabbing' : props.draggable ? 'grab' : undefined,
                    top: offsetTop !== undefined ? `${offsetTop}px` : undefined,
                    left: offsetLeft !== undefined ? `${offsetLeft}px` : undefined,
                }}
            >
                <div className='offcanvas-header'>
                    {props.header}
                    <span className='ms-auto nt--5 ne--5 d-flex align-items-center'>
                        {props.expandLink && (
                            <Link to={props.expandLink} className='btn btn-xs bg-light-hover'>
                                <ArrowsOutSimple className=' ' />
                            </Link>
                        )}
                        <Button className='btn btn-xs bg-light-hover' onClick={() => setVisibility(!props.show)}>
                            <X className=' ' />
                        </Button>
                    </span>
                </div>
                <div className={props.bodyClassName || 'offcanvas-body pt-0 h-100 d-flex flex-column'}>
                    {props.children}
                </div>
            </div>
            {props.show && props.useBackdrop && (
                <div
                    role='link'
                    aria-label={`Close: ${props.ariaLabel || ''}`}
                    tabIndex={-1}
                    onClick={() => setVisibility(false)}
                    onKeyDown={() => {}}
                    ref={backdropRef}
                    className='offcanvas-backdrop fade opacity-10 show'
                />
            )}
        </>
    )
}
