import Tooltip, { TooltipProps } from './Tooltip'
import { ReactNode, useContext, useMemo, useState } from 'react'
import Button from './Input/Button'
import { Trash } from '@phosphor-icons/react'
import { VariableServicesContext } from '../services'

const Delete = (props: {
    hidden?: boolean
    disabled?: boolean
    disabledTooltip?: ReactNode
    icon?: ReactNode
    iconColor?: string
    iconOnly?: boolean
    className?: string
    buttonClassName?: string
    confirmModal?: boolean
    warningText?: string
    confirmText?: string
    tooltipProps?: Partial<TooltipProps>
    deleteFn: () => Promise<void>
    children?: ReactNode
}) => {
    const { uiService } = useContext(VariableServicesContext)
    const [deleting, setDeleting] = useState<boolean>(false)

    const buttonClassName = useMemo(
        () =>
            [
                props.hidden ? 'd-none' : 'd-inline-flex',
                'align-items-center gap-1',
                props.className,
                props.disabled ? 'opacity-50' : '',
            ].join(' '),
        [props.hidden, props.className, props.disabled],
    )

    const buttonText = useMemo(
        () => (
            <>
                {props.icon || <Trash color={props.iconColor} className=' ' />}
                {props?.iconOnly !== true ? props.children || 'Delete' : ''}
            </>
        ),
        [props.icon, props.iconColor, props.iconOnly, props.children],
    )

    const _tooltipContent = useMemo(() => {
        if (props.disabled && props.disabledTooltip) {
            return props.disabledTooltip
        } else if (!props.disabled) {
            return (
                <div className={props.tooltipProps?.tooltipClassName || 'text-start d-flex flex-column small'}>
                    <div>{props.warningText || 'Are you sure? This cannot be undone.'}</div>
                    <Button
                        ariaLabel='Confirm Delete'
                        saving={deleting}
                        onClick={() => {
                            setDeleting(true)
                            props.deleteFn().finally(() => setDeleting(false))
                        }}
                        className={props.buttonClassName || 'btn btn-sm btn-outline-danger align-self-end text-nowrap'}
                    >
                        {props.confirmText || `Yes, I'm sure. Delete!`}
                    </Button>
                </div>
            )
        }
        return null
    }, [
        deleting,
        props.disabled,
        props.disabledTooltip,
        props.buttonClassName,
        props.warningText,
        props.confirmText,
        props.deleteFn,
    ])

    if (props.confirmModal) {
        return (
            <Button
                className={buttonClassName}
                ariaLabel='Delete'
                disabled={props.disabled}
                onClick={() => {
                    uiService.showDeleteModal({
                        warningText: props.warningText,
                        confirmText: props.confirmText,
                        deleteFn: props.deleteFn,
                    })
                }}
            >
                {buttonText}
            </Button>
        )
    }

    return (
        <Tooltip
            type='widget'
            ariaLabel='Delete'
            disabled={props.disabled && !props.disabledTooltip}
            trigger={props.disabled && props.disabledTooltip ? 'hover' : 'click'}
            showArrow={false}
            placement='top-end'
            positioning='fixed'
            {...props.tooltipProps}
            interactive={true}
            className={buttonClassName}
            tooltipContent={_tooltipContent}
        >
            {buttonText}
        </Tooltip>
    )
}

export default Delete
