import { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import Button from '../Input/Button'
import { Input } from '../../services/input'
import { DataQuality, Product } from '../../services/product'
import InputField from '../Input/InputField'
import { Amount, VariableNode } from '../../types'
import {
    CategoryModel,
    CategoryModelConfig,
    CategoryModelConfigType,
    CategoryModelConfigValue,
    CategoryModelInstance,
} from '../../services/category-model'
import { AmountInput } from '../Input/Amount'
import { Selector } from '../Input/Selector'
import { UseStage } from '../Input/UseStage'
import { TransportCalculator } from '../Transport/Transport'
import { TripleCircleIcon } from '../Icons/TripleCircleIcon'
import Utils from '../../services/utils'
import { VariableServicesContext } from '../../services'
import { ProcessingCalculator } from '../Input/Processing'
import UseStageService from '../../services/useStage'
import Delete from '../Delete'
import { usePart } from '../../hooks/usePart'
import { useTransportInstance } from '../../hooks/useTransportInstance'
import { useProcessingType } from '../../hooks/useProcessingType'
import { ApplicationContext } from '../../context'
import TransportService from '../../services/transport'
import ProcessingService from '../../services/processing'
import { useUseStageType } from '../../hooks/useUseStageType'

interface ModelContext {
    selecting: boolean
    setSelecting: (selecting: boolean) => void
    hovering?: CategoryModelConfig
    setHovering: (categoryModel: CategoryModelConfig) => void
    onSelect: (input: Input, type: CategoryModelConfigType, _field: string) => void
    setCategoryModelProduct: (product: Product) => void
    categoryModel?: CategoryModel
    categoryModelConfigs?: CategoryModelConfig[]
    modelConfigUi?: ReactNode
    modelSelectArea: (input: Input, type: CategoryModelConfigType, field: string) => ReactElement
}

const initialContext: ModelContext = {
    selecting: false,
    setSelecting: (_selecting: boolean) => {},
    setHovering: (_categoryModel: CategoryModelConfig) => {},
    onSelect: (_input: Input, _type: CategoryModelConfigType, _field: string) => {},
    setCategoryModelProduct: (_product: Product) => {},
    categoryModel: undefined,
    categoryModelConfigs: undefined,
    modelConfigUi: undefined,
    modelSelectArea: (_input: Input, _type: CategoryModelConfigType, _field: string) => <></>,
}

export const ModelConfigContext = createContext(initialContext)

export const ModelConfigContextProvider = (props: { children: ReactNode }) => {
    const { categoryModelService } = useContext(VariableServicesContext)
    const [shouldSave, setShouldSave] = useState<boolean>(false)
    const [selecting, setSelecting] = useState<boolean>(false)
    const [categoryModel, setCategoryModel] = useState<CategoryModel>({ config: [] })
    const [hovering, setHovering] = useState<CategoryModelConfig>()

    useEffect(() => {
        if (!shouldSave) {
            return
        }
        setShouldSave(false)
        categoryModelService.createOrUpdateModel(categoryModel).then((ucm) => {
            setCategoryModel((state) => ({ ...state, uuid: ucm.uuid, config: ucm.config }))
        })
    }, [shouldSave])

    const setCategoryModelProduct = (p: Product) => {
        if (p.quality === DataQuality.MODEL && p.uuid && !categoryModel.product) {
            categoryModelService.getModelByProductId(p.uuid).then((cm) => {
                if (cm.uuid) {
                    setCategoryModel(cm)
                } else {
                    setCategoryModel({ product: p, config: [] })
                }
            })
        }
    }

    const onSelect = (node: VariableNode, type: CategoryModelConfigType, field: string) => {
        const configs = [...categoryModel.config]
        configs.push({
            type: type,
            input: node as Input,
            field: field,
            description: '',
        })
        setCategoryModel((state) => ({ ...state, config: configs }))
        setSelecting(false)
        setShouldSave(true)
    }

    const modelSelectArea = (input: Input, type: CategoryModelConfigType, field: string) => {
        const cmc: CategoryModelConfig = {
            type: type,
            input: input,
            field: field,
            description: '',
        }
        return (
            <Button
                hidden={!selecting && !hovering}
                onMouseEnter={() => setHovering(cmc)}
                onMouseLeave={() => setHovering(undefined)}
                className={[
                    'fill-parent btn btn-sm rounded-0 z-index-popover',
                    selecting && 'bg-secondary',
                    hovering?.input?.uuid === cmc?.input?.uuid && hovering?.type === type && hovering?.field === field
                        ? 'bg-secondary opacity-50'
                        : 'opacity-10',
                ].join(' ')}
                onClick={() => onSelect(input, type, field)}
            />
        )
    }

    const modelConfigUi = (
        <div className='p-2 border border-primary-light border-1 rounded-2'>
            <div className='d-flex justify-content-between'>
                <span>
                    <h5>Model Config</h5>
                    <p className='mb-1'>What can the user configure in this model?</p>
                </span>
                <span>
                    <Button
                        className={[
                            'btn btn-sm shadow-none text-primary',
                            selecting ? 'btn-secondary' : 'btn-outline-secondary',
                        ].join(' ')}
                        onClick={() => setSelecting(!selecting)}
                    >
                        <TripleCircleIcon size={Utils.verySmallIconSize} /> Select
                    </Button>
                </span>
            </div>
            {categoryModel?.config?.map((cmc, idx) => {
                return (
                    <div
                        key={`cmc-${cmc.input?.uuid}-${cmc.field}-${cmc.type}`}
                        onMouseEnter={() => setHovering(cmc)}
                        onMouseLeave={() => setHovering(undefined)}
                    >
                        <span className='small fw-bold'>
                            {cmc.input?.name} {cmc.field}
                        </span>
                        <div className='row'>
                            <div className='col'>
                                <InputField
                                    placeholder='Description'
                                    // focusOnRender={true}
                                    defaultValue={cmc.description}
                                    className='variable-form-control bg-white border w-100'
                                    onChange={(newValue) => {
                                        const configs = [...categoryModel.config]
                                        configs[idx].description = newValue
                                        setCategoryModel((state) => ({
                                            ...state,
                                            config: configs,
                                        }))
                                    }}
                                    onDebounced={setShouldSave}
                                    onEnterOrBlur={setShouldSave}
                                />
                            </div>
                            <div className='col-3'>
                                {cmc.type}: {cmc.field}
                            </div>
                            <div className='col-2'>
                                <Delete
                                    disabled={cmc.inUse && !Utils.inDebugMode()}
                                    disabledTooltip='This config is in use and cannot be deleted'
                                    iconOnly={true}
                                    deleteFn={async () => {
                                        const configs = [...categoryModel.config]
                                        configs.splice(idx, 1)
                                        setCategoryModel((state) => ({
                                            ...state,
                                            config: configs,
                                        }))
                                        setShouldSave(true)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return (
        <ModelConfigContext.Provider
            value={{
                selecting,
                setSelecting,
                onSelect,
                modelConfigUi,
                modelSelectArea,
                categoryModel,
                setCategoryModelProduct,
                hovering,
                setHovering,
            }}
        >
            {props.children}
        </ModelConfigContext.Provider>
    )
}

export const CategoryModelInput = (props: {
    cmc?: CategoryModelConfig
    cmi?: CategoryModelInstance
    cmv?: CategoryModelConfigValue
    onSave?: (cmi: CategoryModelInstance) => void
}) => {
    const context = useContext(ApplicationContext)
    const { categoryModelService, partService, processingService, transportService, useStageService } =
        useContext(VariableServicesContext)
    const [shouldSave, setShouldSave] = useState<boolean>(false)
    const [amount, setAmount] = useState<Amount | undefined>(props.cmv?.amount)
    const [sourceProduct, setSourceProduct] = useState<Product>()

    const transportInstance = useTransportInstance({
        transportInstanceId: props.cmv?.transportInstance?.uuid || TransportService.transportNewId,
    })
    const processingType = useProcessingType({
        processingTypeId: props.cmv?.processingType?.uuid || ProcessingService.newId,
    })
    const useStageType = useUseStageType({ useStageTypeId: props.cmv?.useStageType?.uuid || UseStageService.newId })
    const part = usePart({ part: props.cmc?.input?.part })

    useEffect(() => {
        if (
            context.stores.transport.instanceId &&
            !transportInstance?.uuid &&
            context.stores.transport.instanceId !== TransportService.transportNewId
        ) {
            const ti = TransportService.transportInstanceById.get(context.stores.transport.instanceId)
            if (ti?.node?.uuid === props.cmv?.uuid && props.cmi) {
                props.onSave?.(props.cmi)
            }
        }
        if (
            context.stores.processingType.processingId &&
            !processingType?.uuid &&
            context.stores.processingType.processingId !== ProcessingService.newId
        ) {
            const pi = ProcessingService.byId.get(context.stores.processingType.processingId)
            if (pi?.node?.uuid === props.cmv?.uuid && props.cmi) {
                props.onSave?.(props.cmi)
            }
        }
    }, [context.stores.transport.updates])

    useEffect(() => {
        if (props.cmc?.type === 'use-stage') {
            if (props.cmv?.uuid && props.cmv?.useStageType) {
                useStageService.getUseStageType(props.cmv.useStageType, props.cmv.uuid).then()
            }
        } else if (props.cmc?.type === 'transport') {
            if (props.cmv?.uuid && props.cmv?.transportInstance) {
                transportService.getTransportInstance(props.cmv.transportInstance, props.cmv.uuid).then()
            }
        } else if (props.cmc?.type === 'processing') {
            if (props.cmv?.uuid && props.cmv?.processingType) {
                processingService.getProcessing(props.cmv.processingType, props.cmv.uuid).then()
            }
        } else if (props.cmc?.type === 'element' && props.cmc?.input?.part?.uuid) {
            partService.getPart(props.cmc?.input?.part.uuid).catch(Utils.errorToast)
        }
        if (!props.cmv?.uuid) {
            setShouldSave(true)
        }
    }, [props.cmc?.type])

    useEffect(() => {
        if (shouldSave) {
            setShouldSave(false)
            if (props.cmi?.uuid) {
                categoryModelService
                    .saveConfigValue(props.cmi, {
                        ...props.cmv,
                        configId: props.cmc?.uuid,
                        amount,
                        part,
                        sourceProduct,
                        transportInstance,
                        useStageType,
                        processingType,
                    })
                    .then(props.onSave)
            }
        }
    }, [shouldSave])

    const elementAmount = useMemo(() => {
        if (props.cmc?.type !== 'element' || props.cmc?.field !== 'amount') return null
        return (
            <AmountInput
                amount={props.cmv?.amount}
                unitSelectorProps={{
                    unitType: props.cmc.input?.unit?.type,
                    hardCodeUnit: props.cmc.input?.unit?.code === 'tkm' ? props.cmc.input?.unit : undefined,
                }}
                onChange={(newAmount) => {
                    setAmount(newAmount)
                    setShouldSave(true)
                }}
            />
        )
    }, [props.cmc, props.cmv?.amount])

    const elementPicker = useMemo(() => {
        if (props.cmc?.type !== 'element' || props.cmc?.field !== 'element') return null
        return (
            <Selector
                placeholder='Select'
                placement='bottom-start'
                className='variable-form-select active'
                tooltipStyle={{ maxWidth: '400px' }}
                options={part?.sourceProducts}
                option={props.cmv?.sourceProduct}
                onSelect={(newValue) => {
                    setSourceProduct(newValue)
                    setShouldSave(true)
                }}
                renderItemValue={(o) => {
                    return (
                        <div className='mb-1'>
                            <strong>{o.name}</strong>
                            <div className='small text-muted overflow-hidden' style={{ maxHeight: '2rem' }}>
                                {o.description}
                            </div>
                        </div>
                    )
                }}
            />
        )
    }, [props.cmc, part?.sourceProducts, props.cmv?.sourceProduct])

    const useStageTypePicker = useMemo(() => {
        if (props.cmc?.type !== 'use-stage') return null
        if (!useStageType) return <span className='spinner-border spinner-border-sm' />
        return (
            <UseStage
                node={props.cmv}
                nodeType='Model'
                startOpen={false}
                useStageType={useStageType}
                onChange={() => setShouldSave(true)}
            />
        )
    }, [props.cmc?.type, props.cmv, useStageType])

    const transportTypePicker = useMemo(() => {
        if (props.cmc?.type !== 'transport') return null
        if (!transportInstance) return <span className='spinner-border spinner-border-sm' />
        return (
            <TransportCalculator
                node={props.cmv}
                startOpen={false}
                transportInstance={transportInstance}
                onChange={() => setShouldSave(true)}
            />
        )
    }, [props.cmc?.type, props.cmv, transportInstance])

    const processingTypePicker = useMemo(() => {
        if (props.cmc?.type !== 'processing') return null
        if (!processingType) return <span className='spinner-border spinner-border-sm' />
        return (
            <ProcessingCalculator
                node={props.cmv}
                startOpen={false}
                processingType={processingType}
                onChange={() => setShouldSave(true)}
            />
        )
    }, [props.cmc?.type, props.cmv, processingType])

    const content = useMemo(() => {
        return (
            elementAmount ||
            elementPicker ||
            useStageTypePicker ||
            transportTypePicker ||
            processingTypePicker || (
                <span className='p-1 border-1 bg-warning bg-opacity-25 rounded-1 text-nowrap'>Not implemented</span>
            )
        )
    }, [elementAmount, elementPicker, useStageTypePicker, transportTypePicker, processingTypePicker])

    if (!props.cmc) return null

    return <>{content}</>
}
