import { useContext, useEffect, useState } from 'react'
import Spinner from './Spinner'
import ProductService, { Product } from '../services/product'
import CO2e from './CO2e'
import { Img } from './Img'
import { GoTo } from './GoTo'
import { VariableServicesContext } from '../services'
import { LifecycleBar } from './Product/LifecycleBar'
import { ProductIcon } from './Icons/ProductIcon'
import Utils from '../services/utils'

import { Co2eDisplay } from '../types'

export const MiniPassport = (props: {
    className?: string
    productId?: string
    product?: Product
    hideIcon?: boolean
    hidden?: boolean
    co2eDisplay?: Co2eDisplay
    onChange?: (product: Product) => void
}) => {
    const { productService } = useContext(VariableServicesContext)
    const [product, setProduct] = useState<Product | undefined>(props.product)

    useEffect(() => {
        if (props.productId) productService.getPassport(props.productId).then(setProduct)
    }, [props.productId])

    if (props.hidden) return null

    if (!product?.uuid) return <Spinner />

    return (
        <div className={props.className}>
            <span className='d-flex gap-3'>
                <div>
                    <Img
                        src={product?.productImageUrl}
                        width='auto'
                        height='50px'
                        placeholder='50px'
                        placeholderIcon={<ProductIcon product={product} size={Utils.largeIconSize} />}
                    />
                </div>
                <div>
                    <span className='d-flex align-items-top gap-1'>
                        <span>
                            {ProductService.isLiveProduct(product) ? <span className='label-live me-2'>Live</span> : ''}
                            {product?.name}
                        </span>
                        <GoTo
                            hidden={props.hideIcon}
                            className='nt--1'
                            url={ProductService.getProductUrl(product)}
                            preview={product}
                        />
                    </span>
                    <span className='d-block small text-muted' hidden={!product.productOf?.uuid}>
                        {product.productOf?.name && `by ${product.productOf?.name}`}
                        {product.dataSources?.[0]?.name && `source: ${product.dataSources?.[0]?.name}`}
                    </span>
                    <CO2e
                        className='d-block text-nowrap'
                        co2e={props.co2eDisplay === 'Total' ? product?.co2e : product?.upstreamCo2e || product?.co2e}
                        product={product}
                        unitSize='small'
                        unitsClassName='small text-muted'
                        functionalUnitClassName='ms-1'
                        functionalUnit={product.unit?.code}
                    />
                    <LifecycleBar
                        className='mx-auto mx-sm-0'
                        product={product}
                        co2eDisplay={props.co2eDisplay}
                        percentBarProps={{
                            height: '5px',
                            extraClassName: 'bg-dark bg-opacity-10',
                            showLabels: false,
                        }}
                    />
                </div>
            </span>
        </div>
    )
}
