import VariableService from './service'
import { UsedIn, VariableBaseNode } from '../types'
import { TransportDistance } from './transport'
import { UIOptionActionType } from './ui'
import Utils from './utils'
import { Product } from './product'

// modeled to imitate neo4j: https://neo4j.com/docs/cypher-manual/current/syntax/spatial/
export interface GeoLocation extends VariableBaseNode {
    latitude?: number
    longitude?: number
    height?: number
    formattedAddress?: string
    countryCode?: string
    locationType?: string
    googleMapsId?: string
    usedIn?: UsedIn[]
}

export type GoogleMapsGeometryLocationType = 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE'

export interface DistanceResponse {
    origin?: GeoLocation
    destination?: GeoLocation
    distance?: TransportDistance
}

export interface ElectricityResponse {
    footprints: Product[]
}

export default class GeoLocationService extends VariableService {
    private basePath: string = '/geo'

    public static list: GeoLocation[] = []
    public static byId: Map<string, GeoLocation> = new Map()

    public static getName(geoLocation?: GeoLocation): string {
        return geoLocation?.formattedAddress || ''
    }

    public static getShortName(geoLocation?: GeoLocation): string {
        return geoLocation?.formattedAddress?.split(',')?.[0] || ''
    }

    public static getZoomLevel(geoLocation?: GeoLocation): number | undefined {
        switch (geoLocation?.locationType) {
            case 'ROOFTOP':
                return 20
            case 'RANGE_INTERPOLATED':
                return 18
            case 'GEOMETRIC_CENTER':
                return 16
            case 'APPROXIMATE':
                return 14
        }
        return
    }

    public static updateContext(gls: GeoLocation[]): void {
        gls.forEach((gl) => gl.uuid && GeoLocationService.byId.set(gl.uuid, gl))
        GeoLocationService.list = Array.from(GeoLocationService.byId.values()).sort((a, b) =>
            Utils.sortByFormattedName(a, b, GeoLocationService.getName),
        )
    }

    public updateContext(gls: GeoLocation[]): void {
        GeoLocationService.updateContext(gls)
        this.context.dispatch({ type: UIOptionActionType.SetGeoLocationsUpdated })
    }

    public get(searchTerm?: string): Promise<GeoLocation[]> {
        const qs = new URLSearchParams()
        if (searchTerm) {
            qs.set('t', searchTerm)
        }
        return this.httpService.get<GeoLocation[]>(`${this.basePath}?${qs.toString()}`)
    }

    public async getMyLocations(skipCache: boolean = false): Promise<GeoLocation[]> {
        if (GeoLocationService.list.length && !skipCache) return GeoLocationService.list
        return this.httpService.get<GeoLocation[]>(this.basePath).then((gls) => {
            this.updateContext(gls)
            return gls
        })
    }

    public addGeoLocation(geoLocation: GeoLocation): Promise<GeoLocation> {
        return this.httpService.post<GeoLocation>(this.basePath, { body: JSON.stringify({ add: geoLocation?.uuid }) })
    }

    public async removeGeoLocation(geoLocation: GeoLocation): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/${geoLocation.uuid}`).then(() => {
            GeoLocationService.byId.delete(geoLocation.uuid!)
            GeoLocationService.updateContext([])
        })
    }

    public getElectricityGeoLocation(geoLocation?: GeoLocation): Promise<ElectricityResponse> {
        if (!geoLocation?.uuid) return Promise.reject()
        return this.httpService.post<ElectricityResponse>(this.basePath, {
            body: JSON.stringify({ electricityGeoLocationId: geoLocation?.uuid }),
        })
    }

    public getDistance(originId: string, destinationId: string, mode: string): Promise<DistanceResponse> {
        return this.httpService.get<DistanceResponse>(
            `${this.basePath}/distance?originId=${originId}&destinationId=${destinationId}&mode=${mode}`,
        )
    }
}
