import UserService, { ColorMode } from './user'
import Decimal from 'decimal.js'
import {
    FriendlyNumber,
    GenericObject,
    KeyValuePair,
    QueryOptions,
    TimePeriod,
    TimePeriodFormat,
    VariableNode,
} from '../types'
import { UnitString } from '../components/CO2e'
import slugify from 'slugify'
import { toast, ToastContent } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'
import { MouseEvent, ReactNode } from 'react'
import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import TaxonomyService from './taxonomy'
import { Info, Trash } from '@phosphor-icons/react'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)

// thanks: https://www.w3schools.com/js/js_numbers.asp
// const MAX_INTEGER_PRECISION = 15
const MAX_DECIMAL_PRECISION = 17

// Decimal.set({ toExpNeg: -MAX_DECIMAL_PRECISION, toExpPos: MAX_INTEGER_PRECISION })

export enum LocalOption {
    SHOW_DEBUG_DATA = 'showDebugData',
}

export interface Locale {
    name?: string
    value?: string | number
}

export interface LocaleOptions {
    number: Locale[]
    date: Locale[]
    time: Locale[]
    languages: Set<string>
}

export type BomSortByOption = 'created' | 'stage' | 'emissions'

export type TimeUnit = 's' | 'm' | 'h' | 'd' | 'w' | 'M' | 'y'

export default class Utils {
    public static colorMode: ColorMode

    public static breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
    }

    public static thinPage = 'col-md-8 offset-md-2 col-lg-6 offset-lg-3'

    public static isThinScreen = () => window.innerWidth < Utils.breakpoints.md

    // public static isTouchScreen = () => window.matchMedia('(pointer: coarse)').matches
    public static isTouchScreen = () => {
        try {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0
        } catch (e) {
            return false
        }
    }

    public static largeIconSize = 24
    public static mediumIconSize = 20
    public static smallIconSize = 16
    public static verySmallIconSize = 14
    public static tinyIconSize = 12

    public static exampleNumber = 1737.42
    public static exampleDateObject = new Date(`${new Date().getFullYear()}-05-17T13:30`)
    public static exampleDate = this.toDateString(this.exampleDateObject.valueOf())

    public static ONE_MB = 1_048_576

    public static MAX_DECIMAL_PRECISION = MAX_DECIMAL_PRECISION

    public static co2e = (
        <>
            CO<sub>2</sub>e
        </>
    )

    public static baseFont = `'Object sans', normal`

    public static black = '#000000'
    public static white = '#ffffff'
    public static gray0 = '#F5F4F0'
    public static gray1 = '#E6E3DD'
    public static gray2 = '#EBE9E2'
    public static lightBlue = '#B7E2E2'
    public static darkBlue = '#9AC6C6'

    public static bodyColor = '#526266'
    public static primaryBackgroundColor = '#003540'
    public static tertiaryColor = '#009999'
    public static fadedColor = '#6c757d'
    public static veryFadedColor = '#adb5bd'
    public static warningColor = '#FFAA00'
    public static dangerColor = '#FF5500'
    public static successColor = '#00990F'
    public static infoColor = this.tertiaryColor
    public static secondaryBackgroundColor = this.warningColor
    public static secondaryTextColor = this.black
    public static mutedTextColor = '#7E8A8C'
    public static tagColor = 'rgba(197, 221, 232, 0.48)'

    public static upstreamColor = this.primaryBackgroundColor
    public static scope2Color = this.secondaryBackgroundColor
    public static scope1Color = '#EA5A1C'
    public static downStreamColor = this.tertiaryColor

    public static baselineColor = '#8C98FF'
    public static reductionColor = '#FF9966'
    public static growthColor = '#A1D897'
    public static targetLineColor = '#003540'

    public static getPrimaryColor = (): string => {
        if (this.colorMode === 'dark') {
            return Utils.gray0
        }
        return Utils.primaryBackgroundColor
    }

    public static chartColors = [
        this.tertiaryColor,
        this.warningColor,
        this.scope1Color,
        '#E5A7E3',
        '#679642',
        '#78C9C9',
        '#BA7C00',
        '#FAA885',
        '#B76FB5',
        '#8CCD5B',
        '#006C6C',
        this.lightBlue,
        '#845800',
        '#FFD47F',
    ]

    public static booleanOptions: KeyValuePair[] = [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
    ]

    public static Decimal(value: Decimal.Value): Decimal {
        return new Decimal(value)
    }

    public static _dayjs(date?: dayjs.ConfigType): Dayjs {
        return dayjs(date)
    }

    public static dayjs(date?: dayjs.ConfigType): Dayjs {
        return dayjs.utc(date)
    }

    public static dayjsString(date?: string, format?: string): Dayjs {
        return dayjs(date, format || 'YYYY-MM-DD')
    }

    public static periodToDateString(period?: TimePeriod): TimePeriodFormat {
        switch (period) {
            case 'day':
                return {
                    date: 'YYYY-MM-DD',
                    short: 'DD',
                    friendly: 'MMM DD, YYYY',
                    manipulateType: 'day',
                    period: 'day',
                    incrementBy: 1,
                }
            case 'quarter':
                return {
                    date: 'YYYY-Q',
                    short: 'Q',
                    friendly: 'Q',
                    manipulateType: 'month',
                    period: 'quarter',
                    incrementBy: 3,
                }
            case 'year':
                return {
                    date: 'YYYY',
                    short: 'YYYY',
                    friendly: 'YYYY',
                    manipulateType: 'year',
                    period: 'month',
                    incrementBy: 1,
                }
            default:
                return {
                    date: 'YYYY-MM',
                    short: 'MMM',
                    friendly: 'MMM YYYY',
                    manipulateType: 'month',
                    period: 'day',
                    incrementBy: 1,
                }
        }
    }

    public static justCreated(
        node?: VariableNode,
        timeQuantity: number = 5,
        timeUnit: ManipulateType = 'seconds',
    ): boolean {
        if (!node?.created) return false
        const fiveSecondsAgo = dayjs().subtract(timeQuantity, timeUnit)
        return dayjs(node.created).isAfter(fiveSecondsAgo)
    }

    public static sleep = (ms: number): Promise<void> => {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    public static isCmdKey = (e: MouseEvent): boolean => {
        return e.ctrlKey || e.metaKey
    }

    public static isAltKey = (e: MouseEvent): boolean => {
        return e.altKey
    }

    public static isModifierKey = (e: MouseEvent): boolean => {
        return e.shiftKey || e.altKey || Utils.isCmdKey(e)
    }

    public static hasValue = (value?: string | number | null): boolean => {
        return value !== undefined && value !== null
    }

    public static getFullImageUrl = (storagePath?: string): string | undefined => {
        if (storagePath?.startsWith('http')) {
            return storagePath
        }

        if (!storagePath) {
            return undefined
        }

        if (process.env.REACT_APP_VARIABLE_ENV === 'local') {
            return `https://dev-app.variable.co/${storagePath}`
        }

        return `${process.env.REACT_APP_BASE_URL}/${storagePath}`
    }

    public static prepareForSave<T>(node: T): T {
        // @ts-ignore
        if (node.taxonomy) node.taxonomy = TaxonomyService.basicTaxonomy(node.taxonomy)
        return node
    }

    public static getLocales(): LocaleOptions {
        const numberExamples = new Set<string>()
        const dateExamples = new Set<string>()
        const timeExamples = new Set<string>()
        let numberFormats: Locale[] = []
        let dateFormats: Locale[] = []
        let timeFormats: Locale[] = []
        const locales = new Set<string>()
        locales.add('en-us') // USA
        locales.add('en-gb') // England
        locales.add('de-de') // Germany
        locales.add('no-no') // Norway
        locales.add('fr-fr') // France
        locales.add('it-ch') // Switzerland
        locales.add('es-cl') // Latin America
        locales.add('ja-jp') // Japan
        locales.add('zh-cn') // China
        // add any other languages the user has set in their browser
        navigator?.languages.forEach((nl) => locales.add(nl.toLowerCase()))
        locales.forEach((l: string) => {
            const numberExample = this.exampleNumber.toLocaleString(l)
            if (!numberExamples.has(numberExample)) {
                numberExamples.add(numberExample)
                numberFormats.push({ name: numberExample, value: l })
            }
            const dateExample = this.exampleDateObject.toLocaleDateString(l)
            if (!dateExamples.has(dateExample)) {
                dateExamples.add(dateExample)
                dateFormats.push({ name: dateExample, value: l })
            }
            const timeExample = this.exampleDateObject.toLocaleTimeString(l, {
                hour: '2-digit',
                minute: '2-digit',
            })
            if (!timeExamples.has(timeExample)) {
                timeExamples.add(timeExample)
                timeFormats.push({ name: timeExample, value: l })
            }
        })
        return {
            number: numberFormats,
            date: dateFormats,
            time: timeFormats,
            languages: locales,
        }
    }

    public static normalize(value: number, min: number, max: number): number {
        return Math.round(Math.min(Math.max((value / 100) * max, min), max))
    }

    public static mergeArrays<T>(array1: any[], array2: any[], key: string, properties?: string[]): T[] {
        const map = new Map()
        array1.forEach((item) => map.set(item[key], item))
        array2.forEach((item) => {
            const _existing = map.get(item[key])
            let _new = { ..._existing }
            if (properties) {
                properties.forEach((p) => (_new[p] = item[p]))
            } else {
                _new = { ..._new, ...item }
            }
            map.set(item[key], _new)
        })
        // console.log(map)
        return Array.from(map.values())
    }

    public static arrayToKeyValuePair(arr?: any[], properties?: Partial<KeyValuePair>): KeyValuePair[] {
        const kvp: KeyValuePair[] = []
        arr?.forEach((item, idx) => {
            kvp.push({ value: idx, name: item, ...properties })
        })
        return kvp
    }

    public static nodeToKeyValuePair(node?: any, properties?: Partial<KeyValuePair>): KeyValuePair {
        return { name: node?.name, value: node?.uuid, description: node?.description, ...properties }
    }

    public static commonPublicEmailDomains = ['gmail', 'google', 'yahoo', 'hotmail', 'outlook', 'aol']

    public static exampleEmailDomain = 'example.com'

    public static getEmailDomain(email?: string, ignoreCommonPublicEmails: boolean = false): string | undefined {
        const emailParts = email?.split('@')
        const domain = emailParts?.[1]
        if (ignoreCommonPublicEmails && this.commonPublicEmailDomains.some((d) => domain?.includes(d))) {
            return undefined
        }
        return domain
    }

    public static isValidEmail(email?: string): boolean {
        if (!email) {
            return false
        }
        return this.validEmailRegex.test(email)
    }

    private static variableEmailDomains: string[] = process.env.REACT_APP_VARIABLE_EMAIL_DOMAINS?.split(';') || [
        'variable.co',
    ]

    public static isVariableEmail(email?: string): boolean {
        if (!email) {
            return false
        }
        return this.variableEmailDomains.some((d) => email.endsWith(d))
    }

    public static readonly validEmailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    public static readonly numberInputPattern = '^[0-9e,.\\-+ ]+$'

    public static readonly javascriptNumber = '^(-)?([0-9]+)?(.)?([0-9]+)?$'

    public static readonly javascriptNumberFormatExplanation =
        'Numbers need to be formatted to use a period "." as the decimal separator and without a thousands separator. For example: 1.234,56 or 1 234,56 or 1,234.56 should be: 1234.56'

    public static toJavascriptNumber(value: string, locale?: string): [string, boolean] {
        if (!value) {
            return [value, false]
        }
        let changed = false
        const originalValue = Utils.toNormalNumber(value.toString())
        const floatValue = parseFloat(originalValue.toString())
        // console.log(value, originalValue)
        if (floatValue.toString() !== originalValue) {
            const format = this.toFixedFloat(1234.56, 2, true, locale)
            const decimal = format.substring(format.length - 3, format.length - 2)
            value = originalValue.replace(new RegExp(`[^0-9-${decimal}]`, 'gi'), '')
            if (decimal !== '.') {
                value = value.replaceAll(decimal, '.')
            }
            const testValue = Utils.toNormalNumber(this.toFixedFloat(value, 8, false, locale))
            // console.log(originalValue, encodeURIComponent(originalValue))
            // console.log(testValue, encodeURIComponent(testValue))
            // console.log(originalValue !== testValue)
            changed = testValue !== originalValue
        }
        return [value, changed]
    }

    public static toNormalNumber(numberString: string): string {
        if (numberString.substring(0, 1).search(/[0-9]/) === -1) {
            numberString = '-' + numberString.substring(1)
        }
        return numberString.replaceAll('\u00A0', ' ')
    }

    public static parseAsNumber(input?: string, locale?: string): number | undefined {
        if (!input) {
            return undefined
        }
        input = input.toString()
        const localeDecimal = this.getPreferredDecimal(locale)
        // console.log('localeDecimal', localeDecimal)
        const sign = this.getNumberSign(input)
        // console.log('sign', sign)
        const decimalRegex = new RegExp(`[^0-9${localeDecimal}]`, 'gi')
        // console.log('decimalRegex', decimalRegex)
        input = input.replace(decimalRegex, '')
        // console.log('input', input)
        input = input.replace(localeDecimal, '.')
        // console.log('input', input)
        return parseFloat(`${sign}${input}`)
    }

    public static toEditableNumber(number?: number): string {
        if (!number) {
            return ''
        }
        return number?.toString().replace('.', Utils.getPreferredDecimal()) || ''
    }

    public static getNumberSign(number?: string): string {
        if (!number) {
            return ''
        }
        if (
            number
                .toString()
                .substring(0, 1)
                .search(/[-–—−]/) === 0
        ) {
            return '-'
        }
        return ''
    }

    public static getPreferredDecimal(locale?: string): string {
        const _formatted = this.toFixedFloat(1234.56, 2, true, locale || UserService.numberLocale)
        return _formatted.substring(_formatted.length - 3, _formatted.length - 2)
    }

    public static getDecimalName(decimal?: string): string {
        switch (decimal) {
            case '.':
                return 'period'
            case ',':
                return 'comma'
            default:
                return ''
        }
    }

    public static inDebugMode(): boolean {
        return this.getLocalOption(LocalOption.SHOW_DEBUG_DATA)
    }

    public static getLocalOption(option: LocalOption): boolean {
        return localStorage.getItem(option) === 'true'
    }

    public static setLocalOption(option: LocalOption, newValue: boolean, onlyIfNotSet: boolean = false): void {
        if (onlyIfNotSet && localStorage.getItem(option) !== null) {
            return
        }
        localStorage.setItem(option, newValue ? 'true' : 'false')
    }

    private static SORT_BOM_BY = 'sortBomBy'

    public static get sortBomBy(): BomSortByOption {
        return (localStorage.getItem(this.SORT_BOM_BY) || 'created') as BomSortByOption
    }

    public static set sortBomBy(value) {
        if (!value) {
            localStorage.removeItem(this.SORT_BOM_BY)
        } else {
            localStorage.setItem(this.SORT_BOM_BY, value)
        }
    }

    private static FILTER_SETTINGS = 'filterSettings'

    public static get filterSettings(): QueryOptions {
        const settings = localStorage.getItem(this.FILTER_SETTINGS) || '{}'
        return JSON.parse(settings) as QueryOptions
    }

    public static set filterSettings(value) {
        if (!value) {
            localStorage.removeItem(this.FILTER_SETTINGS)
        } else {
            localStorage.setItem(this.FILTER_SETTINGS, JSON.stringify(value))
        }
    }

    private static REFERRER = 'referrer'

    public static get referrer(): string | null {
        return localStorage.getItem(this.REFERRER)
    }

    public static set referrer(value) {
        if (!value) {
            localStorage.removeItem(this.REFERRER)
        } else {
            localStorage.setItem(this.REFERRER, value)
        }
    }

    private static BIG_CO2E_KEY = 'bigCo2eNumbers'

    public static get bigCo2eNumbers(): UnitString {
        return (localStorage.getItem(this.BIG_CO2E_KEY) || 't') as UnitString
    }

    public static set bigCo2eNumbers(value) {
        if (!value) {
            localStorage.removeItem(this.BIG_CO2E_KEY)
        } else {
            localStorage.setItem(this.BIG_CO2E_KEY, value)
        }
    }

    private static SMALL_CO2E_KEY = 'smallCo2eNumbers'

    public static get smallCo2eNumbers(): UnitString {
        return (localStorage.getItem(this.SMALL_CO2E_KEY) || 'kg') as UnitString
    }

    public static set smallCo2eNumbers(value) {
        if (!value) {
            localStorage.removeItem(this.SMALL_CO2E_KEY)
        } else {
            localStorage.setItem(this.SMALL_CO2E_KEY, value)
        }
    }

    public static isEqual = (a: any, b: any): boolean => {
        if (a === b) {
            return true
        }
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() === b.getTime()
        }
        if (!a || !b || typeof a !== 'object' || typeof b !== 'object') {
            return a === b
        }
        const keys = Object.keys(a)
        if (keys.length !== Object.keys(b).length) {
            return false
        }
        for (const key of keys) {
            if (!Object.prototype.hasOwnProperty.call(b, key)) {
                return false
            }
            if (!Utils.isEqual(a[key], b[key])) {
                return false
            }
        }
        return true
    }

    public static isNull(value: any): boolean {
        return value === 'null' || value === null
    }

    public static mergeObjects<T>(obj1?: T, obj2?: GenericObject): T {
        if (!obj2) {
            return obj1 as T
        }
        const _qo: GenericObject = { ...obj1 }
        Object.keys(obj2).forEach((key) => {
            if (obj2[key] === undefined) {
                delete _qo[key]
            } else {
                _qo[key] = obj2[key]
            }
        })
        return _qo as T
    }

    public static filterItem(list: any[], filter?: string): boolean {
        if (!list?.length || !filter) return true
        const searchTerms = filter?.toLowerCase().split(' ')
        const dataToSearch = list.join(' ')?.toLowerCase()
        return searchTerms?.every((term) => dataToSearch?.includes(term))
    }

    public static apocSlugify(str: string): string {
        return slugify(str.replaceAll('&', ''), { lower: true, trim: true })
    }

    public static copyToClipboard(value?: string, success?: () => void, failure?: () => void) {
        if (!value) return
        if (navigator.clipboard) {
            navigator.clipboard.writeText(value).then(success, failure)
        } else {
            if (document.execCommand) {
                const el = document.createElement('input')
                el.value = value
                document.body.append(el)

                el.select()
                el.setSelectionRange(0, value.length)

                if (document.execCommand('copy')) {
                    success && success()
                }

                el.remove()
            } else {
                failure && failure()
            }
        }
    }

    public static infoToast = (msg: ToastContent, toastOptions?: ToastOptions) => {
        toast.info(msg, {
            icon: <Info />,
            position: 'bottom-center',
            closeButton: false,
            autoClose: 700,
            theme: this.colorMode === 'dark' ? 'dark' : 'light',
            ...toastOptions,
        })
    }

    public static deletedToast = (msg: ToastContent, toastOptions?: ToastOptions) => {
        this.infoToast(msg, { icon: <Trash color={Utils.warningColor} />, ...toastOptions })
    }

    public static warningToast = (msg: ToastContent, toastOptions?: ToastOptions) => {
        toast.warn(msg, {
            position: 'top-center',
            closeButton: false,
            autoClose: 700,
            theme: this.colorMode === 'dark' ? 'dark' : 'light',
            ...toastOptions,
        })
    }

    public static successToast = (msg: ToastContent, toastOptions?: ToastOptions) => {
        toast.success(msg, {
            position: 'bottom-right',
            autoClose: 700,
            theme: this.colorMode === 'dark' ? 'dark' : 'light',
            ...toastOptions,
        })
    }

    public static errorToast = (e: any, msg?: ReactNode, toastOptions?: ToastOptions) => {
        console.warn(e)
        toast.error(msg || e.message || e, {
            theme: this.colorMode === 'dark' ? 'dark' : 'light',
            ...toastOptions,
        })
        return e
    }

    // thanks: https://stackoverflow.com/a/18018037/1106199
    public static listToTree<T>(list: any[]): T[] {
        const map: GenericObject = {}
        const roots: any[] = []
        let node
        let i

        for (i = 0; i < list.length; i++) {
            map[list[i].uuid] = i
            list[i].children = []
        }

        for (i = 0; i < list.length; i++) {
            node = list[i]
            if (node.parent?.uuid && list[map[node.parent.uuid]]) {
                list[map[node.parent.uuid]].children.push(node)
            } else {
                roots.push(node)
            }
        }
        return roots as T[]
    }

    public static queryString(opts: any): string {
        let qs = new URLSearchParams()
        for (let opt in opts) {
            if (opts[opt]) {
                qs.set(opt, opts[opt])
            }
        }
        if (qs.keys.length > 0) {
            return `?${qs.toString()}`
        }
        return ''
    }

    public static alwaysLowerCase = ['and', 'of', 'the']

    public static toTitleCase(text: string): string {
        const words = text.split(' ')
        const titleCased = words.map((word) => {
            if (this.alwaysLowerCase.indexOf(word) >= 0) {
                return word
            }
            return word.substring(0, 1).toUpperCase() + word.substring(1)
        })
        return titleCased.join(' ')
    }

    public static capitalize(text?: string): string {
        if (!text) {
            return ''
        }
        return text.substring(0, 1).toUpperCase() + text.substring(1)
    }

    public static toInitials(text?: string, max?: number): string {
        if (!text) {
            return ''
        }
        const words = text.split(' ')
        let initials = words.map((word) => word.substring(0, 1).toUpperCase())
        if (max) {
            initials = initials.slice(0, max)
        }
        return initials.join('')
    }

    public static toFixedFloat(
        num?: number | string | null,
        maxPrecision: number = 4,
        showZeroesToPrecision: boolean = false,
        locale?: string,
        showZeroesToNumber: boolean = false,
        friendlyNumbers?: FriendlyNumber[],
    ): string {
        if (!num) {
            return '0'
        }
        if (num === Infinity) {
            return 'Unlimited'
        }
        if (typeof num === 'string') {
            num = parseFloat(num)
        }

        const _friendlyNumber = friendlyNumbers?.find((fn) => fn.value === num)
        if (_friendlyNumber) {
            return _friendlyNumber.name
        }

        if (showZeroesToNumber && num < 1 && num > -1) {
            try {
                const jsNum = num.toLocaleString('en-US', {
                    maximumFractionDigits: Utils.MAX_DECIMAL_PRECISION,
                    useGrouping: false,
                    notation: 'standard',
                    style: 'decimal',
                })
                const parts = jsNum.split('.')
                const firstNonZero = (parts[1]?.search(/[1-9]/) || 0) + 1
                maxPrecision = Math.max(maxPrecision, firstNonZero, 0)
            } catch (e) {
                console.warn(e)
            }
        }
        maxPrecision = Math.max(maxPrecision, 0)

        const minPrecision = showZeroesToPrecision ? maxPrecision : 0
        return num.toLocaleString(locale || UserService.numberLocale, {
            minimumFractionDigits: minPrecision,
            maximumFractionDigits: maxPrecision,
        })
    }

    public static percentOfTotal(
        num: number,
        total: number,
        decimals: number = 0,
        maxDecimals: number = 2,
    ): [string, number, number] {
        if (!total) {
            return ['0', 0, 0]
        }
        const percent = Utils.Decimal(num).div(total).times(100)
        if (!decimals) {
            if (percent.lt(1)) {
                const firstNonZero = percent.toString().search(/[1-9]/) - 1
                if (firstNonZero >= 1 && firstNonZero <= maxDecimals) {
                    decimals = firstNonZero
                }
            }
        }
        const numberValue = percent.toDecimalPlaces(decimals).toNumber()
        return [
            Utils.toFixedFloat(percent.toNumber(), decimals, true, undefined, true),
            numberValue,
            numberValue ? decimals : 0,
        ]
    }

    public static percentOfTotalString(num: number, total: number, decimals: number = 0): string {
        const [percentString] = this.percentOfTotal(num, total, decimals)
        return percentString
    }

    public static toDateString(unixDate?: number, locale?: string): string {
        if (!unixDate) {
            return ''
        }
        return this._dayjs(unixDate)
            .toDate()
            .toLocaleDateString(locale || UserService.dateLocale)
    }

    public static toTimeString(unixDate?: number, options?: Intl.DateTimeFormatOptions): string {
        if (!unixDate) {
            return ''
        }
        return this._dayjs(unixDate)
            .toDate()
            .toLocaleTimeString(
                UserService.timeLocale,
                options || {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                },
            )
    }

    public static toDateTimeString(unixDate?: number, options?: Intl.DateTimeFormatOptions): string {
        if (!unixDate) {
            return ''
        }
        return `${this.toDateString(unixDate)} ${this.toTimeString(unixDate, options)}`
    }

    public static dateFormats: GenericObject = {
        'af-za': 'yyyy/MM/dd',
        'am-et': 'd/M/yyyy',
        'ar-ae': 'dd/MM/yyyy',
        'ar-bh': 'dd/MM/yyyy',
        'ar-dz': 'dd-MM-yyyy',
        'ar-eg': 'dd/MM/yyyy',
        'ar-iq': 'dd/MM/yyyy',
        'ar-jo': 'dd/MM/yyyy',
        'ar-kw': 'dd/MM/yyyy',
        'ar-lb': 'dd/MM/yyyy',
        'ar-ly': 'dd/MM/yyyy',
        'ar-ma': 'dd-MM-yyyy',
        'ar-om': 'dd/MM/yyyy',
        'ar-qa': 'dd/MM/yyyy',
        'ar-sa': 'dd/MM/yy',
        'ar-sy': 'dd/MM/yyyy',
        'ar-tn': 'dd-MM-yyyy',
        'ar-ye': 'dd/MM/yyyy',
        'arn-cl': 'dd-MM-yyyy',
        'as-in': 'dd-MM-yyyy',
        'az-cyrl-az': 'dd.MM.yyyy',
        'az-latn-az': 'dd.MM.yyyy',
        'ba-ru': 'dd.MM.yy',
        'be-by': 'dd.MM.yyyy',
        'bg-bg': 'dd.M.yyyy',
        'bn-bd': 'dd-MM-yy',
        'bn-in': 'dd-MM-yy',
        'bo-cn': 'yyyy/M/d',
        'br-fr': 'dd/MM/yyyy',
        'bs-cyrl-ba': 'd.M.yyyy',
        'bs-latn-ba': 'd.M.yyyy',
        'ca-es': 'dd/MM/yyyy',
        'co-fr': 'dd/MM/yyyy',
        'cs-cz': 'd.M.yyyy',
        'cy-gb': 'dd/MM/yyyy',
        'da-dk': 'dd-MM-yyyy',
        'de-at': 'dd.MM.yyyy',
        'de-ch': 'dd.MM.yyyy',
        'de-de': 'dd.MM.yyyy',
        'de-li': 'dd.MM.yyyy',
        'de-lu': 'dd.MM.yyyy',
        'dsb-de': 'd. M. yyyy',
        'dv-mv': 'dd/MM/yy',
        'el-gr': 'd/M/yyyy',
        'en-029': 'MM/dd/yyyy',
        'en-au': 'd/MM/yyyy',
        'en-bz': 'dd/MM/yyyy',
        'en-ca': 'dd/MM/yyyy',
        'en-gb': 'dd/MM/yyyy',
        'en-ie': 'dd/MM/yyyy',
        'en-in': 'dd-MM-yyyy',
        'en-jm': 'dd/MM/yyyy',
        'en-my': 'd/M/yyyy',
        'en-nz': 'd/MM/yyyy',
        'en-ph': 'M/d/yyyy',
        'en-sg': 'd/M/yyyy',
        'en-tt': 'dd/MM/yyyy',
        'en-us': 'M/d/yyyy',
        'en-za': 'yyyy/MM/dd',
        'en-zw': 'M/d/yyyy',
        'es-ar': 'dd/MM/yyyy',
        'es-bo': 'dd/MM/yyyy',
        'es-cl': 'dd-MM-yyyy',
        'es-co': 'dd/MM/yyyy',
        'es-cr': 'dd/MM/yyyy',
        'es-do': 'dd/MM/yyyy',
        'es-ec': 'dd/MM/yyyy',
        'es-es': 'dd/MM/yyyy',
        'es-gt': 'dd/MM/yyyy',
        'es-hn': 'dd/MM/yyyy',
        'es-mx': 'dd/MM/yyyy',
        'es-ni': 'dd/MM/yyyy',
        'es-pa': 'MM/dd/yyyy',
        'es-pe': 'dd/MM/yyyy',
        'es-pr': 'dd/MM/yyyy',
        'es-py': 'dd/MM/yyyy',
        'es-sv': 'dd/MM/yyyy',
        'es-us': 'M/d/yyyy',
        'es-uy': 'dd/MM/yyyy',
        'es-ve': 'dd/MM/yyyy',
        'et-ee': 'd.MM.yyyy',
        'eu-es': 'yyyy/MM/dd',
        'fa-ir': 'MM/dd/yyyy',
        'fi-fi': 'd.M.yyyy',
        'fil-ph': 'M/d/yyyy',
        'fo-fo': 'dd-MM-yyyy',
        'fr-be': 'd/MM/yyyy',
        'fr-ca': 'yyyy-MM-dd',
        'fr-ch': 'dd.MM.yyyy',
        'fr-fr': 'dd/MM/yyyy',
        'fr-lu': 'dd/MM/yyyy',
        'fr-mc': 'dd/MM/yyyy',
        'fy-nl': 'd-M-yyyy',
        'ga-ie': 'dd/MM/yyyy',
        'gd-gb': 'dd/MM/yyyy',
        'gl-es': 'dd/MM/yy',
        'gsw-fr': 'dd/MM/yyyy',
        'gu-in': 'dd-MM-yy',
        'ha-latn-ng': 'd/M/yyyy',
        'he-il': 'dd/MM/yyyy',
        'hi-in': 'dd-MM-yyyy',
        'hr-ba': 'd.M.yyyy.',
        'hr-hr': 'd.M.yyyy',
        'hsb-de': 'd. M. yyyy',
        'hu-hu': 'yyyy. MM. dd.',
        'hy-am': 'dd.MM.yyyy',
        'id-id': 'dd/MM/yyyy',
        'ig-ng': 'd/M/yyyy',
        'ii-cn': 'yyyy/M/d',
        'is-is': 'd.M.yyyy',
        'it-ch': 'dd.MM.yyyy',
        'it-it': 'dd/MM/yyyy',
        'iu-cans-ca': 'd/M/yyyy',
        'iu-latn-ca': 'd/MM/yyyy',
        'ja-jp': 'yyyy/MM/dd',
        'ka-ge': 'dd.MM.yyyy',
        'kk-kz': 'dd.MM.yyyy',
        'kl-gl': 'dd-MM-yyyy',
        'km-kh': 'yyyy-MM-dd',
        'kn-in': 'dd-MM-yy',
        'ko-kr': 'yyyy. MM. dd',
        'kok-in': 'dd-MM-yyyy',
        'ky-kg': 'dd.MM.yy',
        'lb-lu': 'dd/MM/yyyy',
        'lo-la': 'dd/MM/yyyy',
        'lt-lt': 'yyyy.MM.dd',
        'lv-lv': 'yyyy.MM.dd.',
        'mi-nz': 'dd/MM/yyyy',
        'mk-mk': 'dd.MM.yyyy',
        'ml-in': 'dd-MM-yy',
        'mn-mn': 'yy.MM.dd',
        'mn-mong-cn': 'yyyy/M/d',
        'moh-ca': 'M/d/yyyy',
        'mr-in': 'dd-MM-yyyy',
        'ms-bn': 'dd/MM/yyyy',
        'ms-my': 'dd/MM/yyyy',
        'mt-mt': 'dd/MM/yyyy',
        no: 'dd.MM.yyyy',
        'no-no': 'dd.MM.yyyy',
        'nb-no': 'dd.MM.yyyy',
        'ne-np': 'M/d/yyyy',
        'nl-be': 'd/MM/yyyy',
        'nl-nl': 'd-M-yyyy',
        'nn-no': 'dd.MM.yyyy',
        'nso-za': 'yyyy/MM/dd',
        'oc-fr': 'dd/MM/yyyy',
        'or-in': 'dd-MM-yy',
        'pa-in': 'dd-MM-yy',
        'pl-pl': 'dd.MM.yyyy',
        'prs-af': 'dd/MM/yy',
        'ps-af': 'dd/MM/yy',
        'pt-br': 'd/M/yyyy',
        'pt-pt': 'dd-MM-yyyy',
        'qut-gt': 'dd/MM/yyyy',
        'quz-bo': 'dd/MM/yyyy',
        'quz-ec': 'dd/MM/yyyy',
        'quz-pe': 'dd/MM/yyyy',
        'rm-ch': 'dd/MM/yyyy',
        'ro-ro': 'dd.MM.yyyy',
        'ru-ru': 'dd.MM.yyyy',
        'rw-rw': 'M/d/yyyy',
        'sa-in': 'dd-MM-yyyy',
        'sah-ru': 'MM.dd.yyyy',
        'se-fi': 'd.M.yyyy',
        'se-no': 'dd.MM.yyyy',
        'se-se': 'yyyy-MM-dd',
        'si-lk': 'yyyy-MM-dd',
        'sk-sk': 'd. M. yyyy',
        'sl-si': 'd.M.yyyy',
        'sma-no': 'dd.MM.yyyy',
        'sma-se': 'yyyy-MM-dd',
        'smj-no': 'dd.MM.yyyy',
        'smj-se': 'yyyy-MM-dd',
        'smn-fi': 'd.M.yyyy',
        'sms-fi': 'd.M.yyyy',
        'sq-al': 'yyyy-MM-dd',
        'sr-cyrl-ba': 'd.M.yyyy',
        'sr-cyrl-cs': 'd.M.yyyy',
        'sr-cyrl-me': 'd.M.yyyy',
        'sr-cyrl-rs': 'd.M.yyyy',
        'sr-latn-ba': 'd.M.yyyy',
        'sr-latn-cs': 'd.M.yyyy',
        'sr-latn-me': 'd.M.yyyy',
        'sr-latn-rs': 'd.M.yyyy',
        'sv-fi': 'd.M.yyyy',
        'sv-se': 'yyyy-MM-dd',
        'sw-ke': 'M/d/yyyy',
        'syr-sy': 'dd/MM/yyyy',
        'ta-in': 'dd-MM-yyyy',
        'te-in': 'dd-MM-yy',
        'tg-cyrl-tj': 'dd.MM.yy',
        'th-th': 'd/M/yyyy',
        'tk-tm': 'dd.MM.yy',
        'tn-za': 'yyyy/MM/dd',
        'tr-tr': 'dd.MM.yyyy',
        'tt-ru': 'dd.MM.yyyy',
        'tzm-latn-dz': 'dd-MM-yyyy',
        'ug-cn': 'yyyy-M-d',
        'uk-ua': 'dd.MM.yyyy',
        'ur-pk': 'dd/MM/yyyy',
        'uz-cyrl-uz': 'dd.MM.yyyy',
        'uz-latn-uz': 'dd/MM yyyy',
        'vi-vn': 'dd/MM/yyyy',
        'wo-sn': 'dd/MM/yyyy',
        'xh-za': 'yyyy/MM/dd',
        'yo-ng': 'd/M/yyyy',
        'zh-cn': 'yyyy/M/d',
        'zh-hk': 'd/M/yyyy',
        'zh-mo': 'd/M/yyyy',
        'zh-sg': 'd/M/yyyy',
        'zh-tw': 'yyyy/M/d',
        'zu-za': 'yyyy/MM/dd',
    }

    public static getLocaleDateString() {
        return this.dateFormats[UserService.dateLocale?.toLowerCase()] || 'dd/MM/yyyy'
    }

    public static yearsKeyValueList(startYear: number, endYear?: number): KeyValuePair[] {
        const _endYear = endYear || this.dayjs().year()
        const years: KeyValuePair[] = []
        while (startYear <= _endYear) {
            years.push({ name: startYear.toString(), value: startYear })
            startYear++
        }
        return years
    }

    public static months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    public static pluralize(word?: string, count?: number, pluralSuffix?: string): string {
        if (!word) {
            return ''
        }
        if (!count) {
            count = 0
        }
        if (count === 1) {
            return word
        }
        const lastLetter = word.substring(word.length - 1)
        if (pluralSuffix) {
            return `${word}${pluralSuffix}`
        } else if (lastLetter === 'y') {
            return `${word.substring(0, word.length - 1)}ies`
        } else if (['s', 'h', 'x', 'z'].includes(lastLetter)) {
            // thanks: https://www.grammarly.com/blog/plural-nouns/
            return `${word}es`
        }
        return `${word}s`
    }

    public static setTrueFor(fn: (val: boolean) => void, durationInMilliseconds: number = 10): void {
        fn(true)
        setTimeout(() => fn(false), durationInMilliseconds)
    }

    public static sortObjectByKeys(obj: GenericObject): GenericObject {
        return Object.keys(obj)
            .sort()
            .reduce((result: GenericObject, key: string) => {
                result[key] = obj[key]
                return result
            }, {})
    }

    public static sortByName(a: any, b: any) {
        return a.name?.localeCompare(b.name || '') || -1
    }

    public static sortByFormattedName(a: any, b: any, formatter: (str: any) => string) {
        return formatter(a)?.localeCompare(formatter(b) || '') || -1
    }

    public static sortByCreated(a: any, b: any) {
        return (b?.created || 0) - (a?.created || 0)
    }

    public static sortByUpdatedOrCreated(a: any, b: any) {
        return (b?.updated || b?.created || 0) - (a?.updated || a?.created || 0)
    }

    public static sortListByKey<T>(list: T[], key: string, reverse?: boolean): T[] {
        const _list = list.sort((a: any, b: any) => {
            let aKey = a[key]
            if (typeof aKey === 'string') {
                aKey = aKey.toLowerCase()
            }
            let bKey = b[key]
            if (typeof bKey === 'string') {
                bKey = bKey.toLowerCase()
            }
            if (aKey < bKey) {
                return -1
            }
            return 1
        })
        if (reverse) {
            _list.reverse()
        }
        return _list
    }
}
