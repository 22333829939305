import { Input } from '../../services/input'
import Button from './Button'
import { useContext, useEffect, useMemo } from 'react'
import { VariableNode, VariableNodeType } from '../../types'
import { ApplicationContext } from '../../context'
import { PrettyNumber } from '../PrettyNumber'
import Utils from '../../services/utils'
import UseStageService, { UseStageType } from '../../services/useStage'
import LocationService from '../../services/location'
import { VariableServicesContext } from '../../services'
import { useUseStageType } from '../../hooks/useUseStageType'

export const UseStage = (props: {
    useStageType?: UseStageType
    node?: VariableNode
    nodeType?: VariableNodeType
    startOpen?: boolean
    disabled?: boolean
    onChange: (useStageType: UseStageType) => void
}) => {
    const context = useContext(ApplicationContext)
    const { useStageService } = useContext(VariableServicesContext)

    const useStageType = useUseStageType({ useStageType: props.useStageType })
    const _co2e = useMemo(() => Utils.Decimal(useStageType?.co2e || 0).toNumber(), [useStageType?.co2e])

    useEffect(() => {
        if (props.node && props.node.uuid === context.stores.useStageType?.node?.uuid) {
            if (useStageType && context.stores.useStageType?.updates) {
                props.onChange(useStageType)
            }
        }
        if (
            context.stores.useStageType?.deletedUseStageTypeId !== undefined &&
            context.stores.useStageType?.deletedUseStageTypeId === useStageType?.uuid
        ) {
            useStageService.clearUseStageType()
        }
    }, [context.stores.useStageType, context.stores.useStageType?.deletedUseStageTypeId])

    const _active: boolean = useMemo(
        () => !!useStageType?.uuid && context.stores.useStageType?.useStageId === useStageType?.uuid,
        [useStageType?.uuid, context.stores.useStageType?.useStageId],
    )

    return (
        <Button
            disabled={props.disabled}
            className={[
                'input-element w-auto',
                !_co2e && !_active ? 'btn-light border border-primary border-dashed' : '',
                _active ? 'active' : '',
            ].join(' ')}
            onClick={async () => {
                if (props.disabled) return
                if (useStageType && props.node) {
                    if (!useStageType?.uuid) useStageType.uuid = UseStageService.newId
                    useStageService.editUseStageType({
                        node: props.node as Input,
                        nodeType: props.nodeType,
                        useStageType,
                    })
                } else {
                    useStageService.clearUseStageType()
                }
            }}
        >
            {!useStageType && <span className='spinner-border spinner-border-sm opacity-25' />}
            {useStageType && (
                <span className='d-block text-start'>
                    <span hidden={!!_co2e}>Configure</span>
                    <UseStageSummary useStageType={useStageType} />
                </span>
            )}
        </Button>
    )
}

export const UseStageSummary = (props: { useStageType?: UseStageType; className?: string }) => {
    const _co2e = Utils.Decimal(props.useStageType?.co2e || 0).toNumber()
    const _location = LocationService.getNodeLocation(props.useStageType)
    return (
        <span className={props.className}>
            {_co2e > 0 && props.useStageType?.method === 'static' && (
                <span>
                    <PrettyNumber num={props.useStageType?.totalEnergy?.quantity} />{' '}
                    {props.useStageType?.totalEnergy?.unit?.code}
                </span>
            )}
            {_co2e > 0 && props.useStageType?.method === 'use-frequency-duration' && (
                <span>
                    <PrettyNumber num={props.useStageType?.energyPerUse?.quantity} />{' '}
                    {props.useStageType?.energyPerUse?.unit?.code},{' '}
                    <PrettyNumber num={props.useStageType?.useFrequency?.quantity} /> times per{' '}
                    {props.useStageType?.useFrequency?.unit?.code} over{' '}
                    <PrettyNumber num={props.useStageType?.timeInUse?.quantity} />{' '}
                    {props.useStageType?.timeInUse?.unit?.code}
                </span>
            )}
            <span hidden={!_co2e}> in {LocationService.getLocationName(_location)}</span>
        </span>
    )
}
