import { IconProps } from '@phosphor-icons/react'

export const ConnectionStatus = (props: IconProps & { hasLiveData?: boolean }) => {
    return (
        <span className={`${props.className} position-relative`} style={props.style}>
            {props.hasLiveData && <span className='pulsating-circle-pseudo me--1' />}
            {!props.hasLiveData && <span className='dot-not-connected' style={{ width: '10px', height: '10px' }} />}
        </span>
    )
}
