import { ProgressCircle } from '../ProgressCircle'
import {
    dataCompletenessOptions,
    dataReliabilityOptions,
    PathfinderDataQualityRatingValue,
    Product,
} from '../../services/product'
import Utils from '../../services/utils'
import { Selector } from '../Input/Selector'
import { InfoIcon } from '../Icons/InfoIcon'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { PrettyNumber } from '../PrettyNumber'

export const QualitySummary = (props: {
    className?: string
    size?: number
    product?: Product
    progress?: number
    value?: number
    includeSummary?: boolean
    showDqr?: boolean
    onChange?: (product: Partial<Product>) => void
    onClick?: () => void
}) => {
    const [specificPercent, setSpecificPercent] = useState<number>(0)
    const [averagePercent, setAveragePercent] = useState<number>(0)
    const [qr, setQr] = useState<number>(3)

    const hasData = useMemo(
        () => (props.product && props.product?.qualityRating) || (!props.product && props.value),
        [props.product?.qualityRating, props.value],
    )

    const textColor = useMemo(() => {
        let _color = Utils.bodyColor
        const _qr = props.product?.qualityRating || props.value || 3
        if (_qr <= 1.5) {
            _color = Utils.tertiaryColor
        } else if (_qr >= 2.5) {
            _color = Utils.dangerColor
        }
        setQr(_qr)
        return _color
    }, [props.product?.qualityRating, props.value])

    useEffect(() => {
        let _specific = props.product?.qualitySummary?.specificPercent || 0
        let _average = props.product?.qualitySummary?.averagePercent || 0
        if (props.progress) {
            _specific = props.progress
            _average = 100 - props.progress
        } else if (!props.product) {
            _specific = 0
            _average = 100
        }
        setSpecificPercent(_specific)
        setAveragePercent(_average)
    }, [
        props.product?.type,
        props.product?.pcfPrimaryDataShare,
        props.product?.qualitySummary?.specificPercent,
        props.product?.qualitySummary?.averagePercent,
        props.progress,
    ])

    const miniSettings = useMemo(() => {
        if (!props.showDqr || !props.onChange) return null
        return (
            <div className='d-table-row-group border-0 fs-base'>
                <div className='d-table-row'>
                    <div className='d-table-cell px-1 py-0 text-end' style={{ width: '3rem' }}>
                        <Selector
                            hideTextFilter={true}
                            className={[
                                'small p-1 m--1 rounded-1 text-nowrap nt--1',
                                props.product?.qualitySummary?.dataCompleteness ? '' : 'border border-dashed',
                            ].join(' ')}
                            options={dataCompletenessOptions}
                            option={props.product?.qualitySummary?.dataCompleteness}
                            tooltipStyle={{ maxWidth: '320px' }}
                            placement='left-start'
                            onSelect={(r) => {
                                props.onChange?.({
                                    qualitySummary: {
                                        ...props.product?.qualitySummary,
                                        dataCompleteness: r.value as PathfinderDataQualityRatingValue,
                                    },
                                })
                            }}
                        />
                    </div>
                    <div className='d-table-cell px-1 py-0 text-muted text-start small'>Completeness</div>
                </div>
                <div className='d-table-row'>
                    <div className='d-table-cell px-1 py-0 text-end' style={{ width: '3rem' }}>
                        <Selector
                            hideTextFilter={true}
                            className={[
                                'small p-1 m--1 rounded-1 text-nowrap nt--1',
                                props.product?.qualitySummary?.dataReliability ? '' : 'border border-dashed',
                            ].join(' ')}
                            options={dataReliabilityOptions}
                            option={props.product?.qualitySummary?.dataReliability}
                            tooltipStyle={{ maxWidth: '320px' }}
                            placement='left-start'
                            onSelect={(r) => {
                                props.onChange?.({
                                    qualitySummary: {
                                        ...props.product?.qualitySummary,
                                        dataReliability: r.value as PathfinderDataQualityRatingValue,
                                    },
                                })
                            }}
                        />
                    </div>
                    <div className='d-table-cell px-1 py-0 text-muted text-start small'>Reliability</div>
                </div>
            </div>
        )
    }, [
        props.product?.qualitySummary?.dataCompleteness,
        props.product?.qualitySummary?.dataReliability,
        props.onChange,
        props.showDqr,
    ])

    const summary = useMemo(
        () => (
            <div className={['d-table small', !hasData ? 'grayscale opacity-50' : ''].join(' ')}>
                <div className='d-table-row'>
                    <div className='d-table-cell px-1 py-0 text-end'>
                        <PrettyNumber num={Math.round(specificPercent)} suffix='%' />
                    </div>
                    <div className='d-table-cell px-1 py-0 text-muted text-start'>Primary data</div>
                </div>
                <div className='d-table-row'>
                    <div className='d-table-cell px-1 py-0 text-end'>
                        <PrettyNumber num={Math.round(averagePercent)} suffix='%' />
                    </div>
                    <div className='d-table-cell px-1 py-0 text-muted text-start'>Secondary data</div>
                </div>
                {miniSettings}
            </div>
        ),
        [hasData, miniSettings, specificPercent, averagePercent],
    )

    return (
        <>
            <ProgressCircle
                textValue={hasData ? qr : '-'}
                progressPercent={specificPercent}
                round={1}
                tooltip={props.size && props.size <= 30 ? summary : undefined}
                size={props.size}
                textColor={textColor}
                className={[props.className, !hasData ? 'grayscale opacity-50' : ''].join(' ')}
                onClick={props.onClick}
            />
            {props.includeSummary && summary}
        </>
    )
}

export const DataQualityHeader = (props: { text?: ReactNode; className?: string }) => (
    <h6 className={props.className || 'd-flex flex-nowrap gap-1'}>
        <span className='text-nowrap'>{props.text || 'Data quality'}</span>
        <InfoIcon
            color={Utils.veryFadedColor}
            href='https://variable.co/blog/introducing-the-data-quality-indicator'
            size={17}
            interactive={true}
            className='fs-base'
            tooltip={
                <div className='small text-start text-muted'>
                    The Data Quality Indicator is a way to quickly get a sense for the quality of the underlying data.
                    The best possible score is 1.
                    <a
                        target='_blank'
                        rel='noreferrer'
                        className='text-decoration-underline ms-1'
                        href='https://variable.co/blog/introducing-the-data-quality-indicator'
                    >
                        Read more
                    </a>
                </div>
            }
        />
    </h6>
)
