import { Selector, SelectorContainerProps, SelectorProps } from './Selector'
import Button from './Button'
import { X } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import { useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../../services'
import DataSourceService, { DataSource } from '../../services/dataSource'
import { ApplicationContext } from '../../context'
import { ProductVisibility } from '../../services/product'

export type DataSourceSelectorProps = SelectorContainerProps &
    SelectorProps & {
        showPublicSources?: boolean
        showAllSources?: boolean
        extraSources?: DataSource[]
    }

export const DataSourceSelector = (props: DataSourceSelectorProps) => {
    const context = useContext(ApplicationContext)
    const { dataSourceService } = useContext(VariableServicesContext)
    const [dataSources, setDataSources] = useState<Map<string, DataSource>>(new Map())

    useEffect(() => {
        dataSourceService.getPublic().then()
    }, [])

    useEffect(() => {
        if (props.showAllSources || props.showPublicSources) {
            setDataSources((state) => {
                const newState = new Map(state)
                DataSourceService.publicList
                    .filter((pds) => (pds.visibility || ProductVisibility.ARCHIVED) > ProductVisibility.ARCHIVED)
                    .forEach((pds) => newState.set(pds.uuid!, pds))
                return newState
            })
        }
        if (props.showAllSources) {
            setDataSources((state) => {
                const newState = new Map(state)
                context.stores.suppliers.list.forEach((s) => newState.set(s.uuid!, s as DataSource))
                return newState
            })
        }
    }, [
        props.showAllSources,
        props.showPublicSources,
        context.stores.suppliers.list.length,
        context.stores.ui?.dataSourcesUpdated,
    ])

    useEffect(() => {
        setDataSources((state) => {
            let newState = new Map()
            if (props.showAllSources || props.showPublicSources) {
                newState = new Map(state)
            }
            props.extraSources?.forEach((s) => newState.set(s.uuid!, s))
            return newState
        })
    }, [props.extraSources])

    return (
        <div
            className={
                props.containerClassName ||
                [props.hidden ? 'd-none' : 'd-flex', 'align-items-center gap-1', props.containerExtraClassName].join(
                    ' ',
                )
            }
        >
            <Selector
                {...props}
                placement={props.placement || 'bottom-start'}
                options={Array.from(dataSources.values()).sort(Utils.sortByName)}
            />
            {props.onClear && Utils.hasValue(props.option) && (
                <Button className='btn btn-xxs bg-light-hover' onClick={props.onClear}>
                    <X size={Utils.verySmallIconSize} />
                </Button>
            )}
        </div>
    )
}
