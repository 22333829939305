import ActivityService, { ActivityDirection, ActivityItem } from '../../services/activity'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ProductService, { Product } from '../../services/product'
import Button from '../Input/Button'
import { CompactProductCatalog } from '../Product/CompactProductCatalog'
import { TaxonomyLabel } from '../TaxonomyLabel'
import { CompanyFilter } from '../Company/CompanyFilter'
import { Company } from '../../services/company'
import { useFootprintSelector } from '../../hooks/useFootprintSelector'
import { Taxonomy } from '../../services/taxonomy'
import { DataRequestContext } from '../../services/dataRequestContext'
import Utils from '../../services/utils'
import { X } from '@phosphor-icons/react'
import { VariableServicesContext } from '../../services'
import Tooltip from '../Tooltip'
import { ElementBadge } from '../Product/ElementBadge'
import { ActivityContext } from '../../services/activityContext'
import { FootprintSearchView } from '../../services/ui'
import { TransportType } from '../../services/transport'
import { TransportTypeSelector } from '../Transport/TransportTypeSelector'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { TransportIcons } from '../Icons/TransportIcons'
import { InventoryType } from '../../services/inventory'
import { useProduct } from '../../hooks/useProduct'
import { useTransportType } from '../../hooks/useTransportType'

export const ActivityInputItem = (props: {
    activity: ActivityItem
    disabled?: boolean
    onProduct: (product?: Product | null, taxonomy?: Taxonomy) => void
    onTransport: (transport?: TransportType | null, taxonomy?: Taxonomy) => void
    onTaxonomy: (taxonomy?: Taxonomy) => void
    onActivityChange: (activity: Partial<ActivityItem>) => void
    onSupplierChange: (company?: Company) => void
}) => {
    const { activityService } = useContext(VariableServicesContext)
    const activityContext = useContext(ActivityContext)
    const drContext = useContext(DataRequestContext)
    const [showModal, setShowModal] = useState(false)
    const [footprintModalView, setFootprintModalView] = useState<FootprintSearchView>()

    const perspective = useMemo(() => {
        return activityService.getActivityPerspective(props.activity)
    }, [props.activity])

    const product = useProduct({ product: props.activity.product })
    const transportType = useTransportType({ transportType: props.activity.transportType })

    useEffect(() => {
        setFootprintModalView(activityContext.isOnboarding ? 'database' : 'inventory')
    }, [activityContext.isOnboarding])

    useEffect(() => {
        if (showModal) {
            setShowModal(false)
            showFootprintModal()
        }
    }, [showModal])

    const taxonomy = useMemo(
        () => ActivityService.getActivityTaxonomy(props.activity),
        [product?.uuid, product?.taxonomy, transportType?.taxonomy, props.activity?.taxonomy],
    )

    const showFootprintModal = useFootprintSelector({
        searchConfig: {
            instanceId: 'activity-input-item',
            title: 'Activity Footprint',
            targetType: 'ActivityItem',
            targetNode: props.activity,
            queryOptions: { taxonomy: taxonomy?.uuid },
            inventoryTypes: [InventoryType.Product, InventoryType.Transport],
            view: footprintModalView,
            visible: true,
        },
        onSelectProduct: props.onProduct,
        onSelectTransport: props.onTransport,
        onSelectTaxonomy: props.onTaxonomy,
    })

    const companySelector = useCallback(
        () => (
            <CompanyFilter
                placement='bottom-start'
                perspective={perspective.otherCompanyPerspective}
                company={perspective.otherCompany}
                disabled={props.disabled || !!product?.productOf?.uuid}
                allowCreate={true}
                showGoTo={true}
                onSelect={props.onSupplierChange}
            />
        ),
        [
            perspective.otherCompany,
            perspective.otherCompanyPerspective,
            props.disabled,
            product?.productOf?.uuid,
            props.onSupplierChange,
        ],
    )

    const catalogListClassName = 'overflow-y-auto border rounded-1 p-2'
    const catalogListStyle = { height: '12rem' }
    const actionButtonText = 'Select'
    const actionButtonClassName = 'btn btn-xs btn-outline-primary'

    const removeFromActivityButton = useCallback(
        (type: 'product' | 'transport') => (
            <Tooltip
                trigger='hover'
                placement='top-end'
                hidden={!!props.activity.batch?.uuid}
                disabled={!!props.activity.batch?.uuid}
                tooltipClassName='small'
                tooltipContent={`Remove from ${ActivityService.webTitle()}`}
                className={['ms-auto', props.disabled ? 'd-none' : 'show-on-hover'].join(' ')}
            >
                <Button
                    disabled={props.disabled}
                    className='btn btn-xs'
                    onClick={() => {
                        switch (type) {
                            case 'product':
                                props.onProduct(null, taxonomy)
                                break
                            case 'transport':
                                props.onTransport(null, taxonomy)
                                break
                        }
                    }}
                >
                    <X />
                </Button>
            </Tooltip>
        ),
        [props.activity.batch?.uuid, props.disabled, taxonomy],
    )

    const productContent = useMemo(() => {
        if (!product) return null
        return (
            <div className='ms-2'>
                <div className='d-flex hover-parent-direct'>
                    <ElementBadge
                        product={product}
                        showCompany={true}
                        className='d-block bg-light-hover p-2 mx--2 rounded-1'
                        nameClassName='fs-4 text-overflow-ellipsis'
                        noTooltip={true}
                        iconProps={{ size: 22, weight: 'regular', className: 'flex-shrink-0 nt--2' }}
                    />
                    {removeFromActivityButton('product')}
                </div>
                {perspective.incoming && !product.productOf && companySelector()}
            </div>
        )
    }, [product, props.disabled, perspective, removeFromActivityButton])

    const transportContent = useMemo(() => {
        if (!transportType) return null
        return (
            <div className='d-flex align-items-center gap-1 hover-parent-direct ps-2'>
                <TransportIcons />
                <TransportTypeSelector
                    placement='bottom-start'
                    option={transportType}
                    onSelect={props.onTransport}
                    disabled={props.disabled}
                />
                {removeFromActivityButton('transport')}
            </div>
        )
    }, [transportType, props.disabled, removeFromActivityButton])

    const dataRequestSupplierContent = useMemo(() => {
        if (!drContext.dataRequest?.supplier) return null
        return (
            <CompactProductCatalog
                disabled={props.disabled}
                listClassName={catalogListClassName}
                listStyle={catalogListStyle}
                productOf={drContext.dataRequest?.supplier}
                onClick={props.onProduct}
                actionButtonText={actionButtonText}
                actionButtonClassName={actionButtonClassName}
            />
        )
    }, [drContext.dataRequest?.supplier])

    const inputContent = useMemo(() => {
        if (perspective.otherCompany?.uuid) {
            return (
                <>
                    <CompactProductCatalog
                        disabled={props.disabled}
                        listClassName={catalogListClassName}
                        listStyle={catalogListStyle}
                        productOf={perspective.otherCompany}
                        onClick={props.onProduct}
                        actionButtonText={actionButtonText}
                        actionButtonClassName={actionButtonClassName}
                    />
                    <Button
                        className='position-absolute btn btn-xs bg-light border bg-light-hover'
                        style={{ top: '-.75rem', right: '0' }}
                        onClick={() => props.onProduct(null)}
                    >
                        <X size={Utils.verySmallIconSize} />
                    </Button>
                </>
            )
        } else {
            return (
                <div className='d-flex align-items-center'>
                    {!product && (
                        <Button className='btn btn-sm btn-light border my-2' onClick={() => showFootprintModal()}>
                            <InventoryIcon /> {ProductService.elementTitle()}
                        </Button>
                    )}
                    <div className={product ? '' : 'border-start ms-2 ps-2'}>{companySelector()}</div>
                </div>
            )
        }
    }, [
        catalogListClassName,
        catalogListStyle,
        actionButtonText,
        actionButtonClassName,
        perspective,
        props.activity,
        props.disabled,
    ])

    const outputContent = useMemo(() => {
        return (
            <CompactProductCatalog
                disabled={props.disabled}
                listClassName={catalogListClassName}
                listStyle={catalogListStyle}
                productOf={perspective?.sender}
                co2eDisplay='Downstream'
                onClick={props.onProduct}
                actionButtonText={actionButtonText}
                actionButtonClassName={actionButtonClassName}
            />
        )
    }, [props.activity, catalogListClassName, catalogListStyle, actionButtonText, actionButtonClassName, perspective])

    const content = useMemo(() => {
        if (product?.uuid) {
            return productContent
        }
        if (transportType?.uuid) {
            return transportContent
        }
        if (props.disabled) return null
        if (drContext.dataRequest?.uuid && drContext.dataRequest.supplier?.uuid) {
            return dataRequestSupplierContent
        }
        if (perspective.direction === ActivityDirection.INPUT) {
            return inputContent
        }
        return outputContent
    }, [
        props.activity,
        props.disabled,
        drContext,
        perspective,
        productContent,
        transportContent,
        inputContent,
        outputContent,
        dataRequestSupplierContent,
    ])

    const taxonomyLabel = useMemo(() => {
        return (
            <TaxonomyLabel
                disabled={props.disabled}
                taxonomy={taxonomy}
                extraClassName='bg-none'
                onClick={() => {
                    if (props.disabled) return
                    if (!product && !transportType) {
                        const _previousView = footprintModalView
                        setFootprintModalView('database')
                        setShowModal(true)
                        setTimeout(() => setFootprintModalView(_previousView), 100)
                    }
                }}
                className='small mb-1 ms-2'
            />
        )
    }, [taxonomy, footprintModalView, props.disabled, props.activity])

    return (
        <div className='position-relative'>
            {taxonomyLabel}
            {content}
        </div>
    )
}
