import { UsedIn } from '../types'
import ProductService from '../services/product'
import TransportService from '../services/transport'
import LocationService from '../services/location'
import { Link } from 'react-router-dom'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { VariableServicesContext } from '../services'
import Utils from '../services/utils'
import { TransportIcons } from './Icons/TransportIcons'
import { ProductIcon } from './Icons/ProductIcon'
import { PartIcon } from './Icons/PartIcon'
import { Buildings, Factory } from '@phosphor-icons/react'
import { Company } from '../services/company'
import PartService from '../services/part'
import { ApplicationContext } from '../context'
import { useProduct } from '../hooks/useProduct'
import { usePart } from '../hooks/usePart'
import { useTransportType } from '../hooks/useTransportType'

export const UsedInItem = (props: { usedIn: UsedIn; followLink?: boolean; extraOnClick?: () => void }) => {
    const context = useContext(ApplicationContext)
    const { productService, companyService, transportService, locationService } = useContext(VariableServicesContext)
    const [name, setName] = useState<string | undefined>(props.usedIn.name)
    const [icon, setIcon] = useState<ReactNode>()
    const [link, setLink] = useState<string>('#')

    const product = useProduct({ productId: props.usedIn.uuid })
    const part = usePart({ partId: props.usedIn.uuid })
    const transportType = useTransportType({ transportTypeId: props.usedIn.uuid })

    const location = useMemo(
        () => LocationService.locationById.get(props.usedIn.uuid),
        [props.usedIn.uuid, context.stores.ui?.locationsUpdated],
    )

    useEffect(() => {
        // console.log(props.usedIn)
        if (props.usedIn.companyType) {
            if (props.usedIn.companyType === 'supplier') {
                setIcon(<Factory />)
            } else {
                setIcon(<Buildings />)
            }
            setLink(companyService.getCompanyUrl(props.usedIn as unknown as Company, props.usedIn.companyType))
            setName(props.usedIn.name)
        } else if (props.usedIn.type === 'Product') {
            setIcon(<ProductIcon product={product} />)
            setLink(ProductService.getProductUrl(product))
            if (product?.name) setName(product.name)
        } else if (props.usedIn.type === 'Part') {
            setIcon(<PartIcon part={part} />)
            setLink(PartService.getPartUrl(part))
            if (part?.name) setName(part.name)
        } else if (props.usedIn.type === 'TransportType') {
            setIcon(<TransportIcons />)
            setLink(TransportService.getTransportTypeUrl(transportType))
            setName(TransportService.getTransportTypeName(transportType))
        } else if (props.usedIn.type === 'Location') {
            setIcon(LocationService.getLocationTypeIcon(location))
            setName(LocationService.getLocationName(location))
        }
    }, [props.usedIn, product, part, transportType, location])

    return (
        <Link
            to={link}
            className='d-inline-flex align-items-center gap-1 hover-parent-direct'
            onClick={(e) => {
                if (Utils.isModifierKey(e)) return
                if (
                    !props.followLink &&
                    !props.usedIn.companyType &&
                    !['Part', 'ActivityItem'].includes(props.usedIn.type)
                ) {
                    e.preventDefault()
                    e.stopPropagation()
                }
                if (!props.followLink) {
                    if (['Product'].includes(props.usedIn.type)) {
                        productService.openPreview(product)
                    } else if (props.usedIn.type === 'TransportType') {
                        if (transportType) transportService.setTransportType(transportType)
                    } else if (props.usedIn.type === 'Location') {
                        if (location?.uuid) locationService.openLocation(location.uuid)
                    } else {
                        locationService.openLocation(undefined)
                    }
                }
                props.extraOnClick?.()
            }}
        >
            {icon !== undefined ? <span className='flex-shrink-0'>{icon}</span> : null}
            <span className='underline-on-hover'>{name}</span>
        </Link>
    )
}
