import { Input } from '../../services/input'
import { useContext, useEffect, useMemo } from 'react'
import Button from './Button'
import { VariableNode } from '../../types'
import { ApplicationContext } from '../../context'
import { PrettyNumber } from '../PrettyNumber'
import { Lightning } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import ProcessingService, { ProcessingType, ProcessingTypeMethod } from '../../services/processing'
import LocationService from '../../services/location'
import { VariableServicesContext } from '../../services'
import { useProcessingType } from '../../hooks/useProcessingType'

export const ProcessingCalculator = (props: {
    processingType?: ProcessingType
    node?: VariableNode
    startOpen?: boolean
    disabled?: boolean
    bodyOnly?: boolean
    onChange?: (processingType: ProcessingType) => void
}) => {
    const context = useContext(ApplicationContext)
    const { processingService } = useContext(VariableServicesContext)

    const processingType = useProcessingType({ processingType: props.processingType })

    const co2e = useMemo(() => Utils.Decimal(processingType?.co2e || 0).toNumber(), [processingType?.co2e])

    const processingLocation = useMemo(
        () => LocationService.getNodeLocation(processingType),
        [processingType?.location, processingType?.geoLocation],
    )

    useEffect(() => {
        if (
            props.node &&
            props.node.uuid === context.stores.processingType?.node?.uuid &&
            context.stores.processingType?.updates &&
            processingType
        ) {
            props.onChange?.(processingType)
        }
    }, [props.node?.uuid, context.stores.processingType?.updates, processingType])

    useEffect(() => {
        if (
            !!context.stores.processingType.deletedProcessingTypeId &&
            context.stores.processingType.deletedProcessingTypeId === processingType?.uuid
        ) {
            processingService.clearProcessingType()
        }
    }, [context.stores.processingType.deletedProcessingTypeId])

    const active = useMemo(
        () => !!processingType?.uuid && context.stores.processingType?.processingId === processingType?.uuid,
        [processingType?.uuid, context.stores.processingType?.processingId],
    )

    const body = useMemo(() => {
        if (!processingType) return <span className='spinner-border spinner-border-sm opacity-25' />
        if (!co2e) return <span>Configure</span>
        let locationString = ` in ${LocationService.getLocationName(processingLocation)}`
        if (processingType.method === ProcessingTypeMethod.FOOTPRINT && processingType.footprint?.name) {
            locationString = `, ${processingType.footprint.name}`
        }
        return (
            <span className='d-block text-start'>
                <Lightning className='nt--1 flex-shrink-0' />{' '}
                <PrettyNumber num={processingType.totalKWh} showZeroesToNumber={true} suffix={' kWh'} />
                <span>{locationString}</span>
            </span>
        )
    }, [processingType, co2e, processingLocation])

    if (props.bodyOnly) return body

    return (
        <Button
            disabled={props.disabled}
            className={[
                'input-element w-auto',
                !co2e && !active ? 'btn-light border border-primary border-dashed' : '',
                active ? 'active' : '',
            ].join(' ')}
            onClick={async () => {
                if (props.disabled) return
                if (processingType && props.node) {
                    if (!processingType?.uuid) processingType.uuid = ProcessingService.newId
                    processingService.editProcessingType({ node: props.node as Input, processingType })
                } else {
                    processingService.clearProcessingType()
                }
            }}
        >
            {body}
        </Button>
    )
}
