import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import ActivityService, { ActivityState } from '../../services/activity'
import OrgService from '../../services/org'
import ProductService from '../../services/product'
import { ActivityContext } from '../../services/activityContext'
import { ApplicationContext } from '../../context'

interface ActivityOnboardingStep {
    className: string
    content: ReactNode
}

export const ActivityOnboarding = () => {
    const context = useContext(ApplicationContext)
    const activityContext = useContext(ActivityContext)
    const [step, setStep] = useState<ActivityOnboardingStep>()

    useEffect(() => {
        if (!activityContext.isOnboarding) return
        if (activityContext.activity?.state === ActivityState.PENDING) {
            setStep(steps.pending)
        } else if (activityContext.activity?.state === ActivityState.APPROVED) {
            setStep(steps.approved)
        } else if (activityContext.activity?.product && activityContext.activity?.quantity) {
            setStep(steps.ready)
        } else {
            setStep(steps.default)
        }
    }, [activityContext.activity?.product, activityContext.activity?.quantity, activityContext.activity?.state])

    const steps: { [key: string]: ActivityOnboardingStep } = useMemo(() => {
        return {
            default: {
                className: '',
                content: (
                    <>
                        <p>
                            Here's a new {ActivityService.webTitle()}. We've pre-filled that it's a purchase of
                            Electricity. You'll need to click the{' '}
                            <span className={!activityContext.activity?.product?.uuid ? 'fw-bold' : ''}>
                                select {ProductService.elementTitle().toLowerCase()}
                            </span>{' '}
                            button and filter the list for your location to find an emission factor and then{' '}
                            <span
                                className={
                                    activityContext.activity?.product?.uuid && !activityContext.activity?.quantity
                                        ? 'fw-bold'
                                        : ''
                                }
                            >
                                enter the kWh used
                            </span>
                            .
                        </p>
                        <p>
                            We've also pre-selected the {OrgService.webTitle()} "Operations" and set the GHG Scope to
                            "Scope 2: Electricity"
                        </p>
                    </>
                ),
            },
            ready: {
                className: 'bottom-0 mb-5',
                content: (
                    <p>
                        You've got all the required data for an {ActivityService.webTitle().toLowerCase()}. You can now
                        submit it for approval.
                    </p>
                ),
            },
            pending: {
                className: 'bottom-0 mb-5',
                content: (
                    <>
                        <p>The {ActivityService.webTitle().toLowerCase()} now has a status of Pending.</p>
                        {context.stores.members.length === 1 && (
                            <p>
                                Because you are the only user in this account, you can approve your own{' '}
                                {ActivityService.webTitle(true).toLowerCase()}. When you have more of your colleagues
                                using Variable, you can use the {OrgService.webTitle()} structure (in the left side
                                navigation) to manage who can approve different{' '}
                                {ActivityService.webTitle(true).toLowerCase()}.
                            </p>
                        )}
                        <p>
                            The emissions from this {ActivityService.webTitle().toLowerCase()} will be only be added to
                            your company account after it is approved.
                        </p>
                    </>
                ),
            },
            approved: {
                className: 'top-0 mt-3',
                content: (
                    <p>
                        You can see the status history by clicking on the status label at the top of each{' '}
                        {ActivityService.webTitle().toLowerCase()}.
                    </p>
                ),
            },
        }
    }, [context.stores.members.length, activityContext.activity?.product, activityContext.activity?.quantity])

    if (!step || !activityContext.isOnboarding || activityContext.activity?.state === ActivityState.REJECTED) {
        return null
    }

    return (
        <div
            className={[
                'position-absolute end-100 me-2 bg-white rounded-3',
                'p-3 border border-primary border-2 shadow',
                step.className,
            ].join(' ')}
            style={{ width: '400px' }}
        >
            {step.content}
        </div>
    )
}
