import { Product } from './product'
import { Contact, User, UserWithoutID } from './user-context'
import { ActivityCalculationMethod, ActivityItem } from './activity'
import { KeyValuePair, ListResponse, VariableBaseNode } from '../types'
import { SharingToken } from './token'
import { Unit } from './unit'
import { ActionMap } from '../context'
import { IndustryActionType, IndustrySector } from './industries'
import { Subscription } from './subscription'
import { ReactNode } from 'react'
import { DataSource, PremiumFactors } from './dataSource'
import Utils from './utils'
import VariableService from './service'
import { ManipulateType } from 'dayjs'

export type CompanyPerspective = 'supplier' | 'customer'

export type CompanyTableColumnKey = 'name' | 'connectionStatus' | 'priority' | 'spendValue' | 'totalCo2e'

export type CompanyPageTab = 'products' | 'activities' | 'requests' | 'contacts'

export type CompanyAdminTab = 'settings' | 'users' | 'imports'

export interface CompanyTableColumn {
    name: ReactNode
    key: CompanyTableColumnKey
    dir: 'ASC' | 'DESC'
    isDefault?: boolean
    className?: string
}

export type CompanySize = 'very-small' | 'small' | 'medium' | 'large' | 'enterprise'

export enum CompanyImportance {
    High = 100,
    Medium = 50,
    Low = 10,
    Unknown = 0,
}

export const companyImportanceOptions: KeyValuePair[] = [
    { name: 'High', value: CompanyImportance.High },
    { name: 'Medium', value: CompanyImportance.Medium },
    { name: 'Low', value: CompanyImportance.Low },
]

export interface CompanyRelationshipConfig {
    internalId?: string
    nationalId?: string
    importance?: CompanyImportance
}

export interface CompanySpend extends VariableBaseNode {
    year?: number
    value?: number
}

export enum CompanySubdomainVisibility {
    PRIVATE = 'private',
    CONNECTED = 'connected',
    PUBLIC = 'public',
}

export interface Company extends VariableBaseNode {
    labelKey?: string
    syncId?: string
    claimed?: boolean
    name: string
    aka?: string[]
    slug?: string
    type?: 'company' | 'database'
    subdomainVisibility?: CompanySubdomainVisibility
    companySize?: CompanySize
    industry?: IndustrySector
    logoUrl?: string
    nationalId?: string
    currency?: Unit
    methodologyDescription?: string
    carbonAmbition?: number
    internalCarbonPrice?: number
    downstreamActivityConfiguration?: ActivityCalculationMethod
    domains?: CompanyDomain[]
    contacts?: Contact[]
    contactCount?: number
    token?: SharingToken
    subscription?: Subscription
    replacedBy?: Company
    replacedCompany?: Company
    rlConfig?: CompanyRelationshipConfig
    spend?: CompanySpend
    yearlySpend?: CompanySpend[]
    dataSources?: DataSource[]

    memberCount?: number
    userCount?: number
    partCount?: number
    productCount?: number
    activityCount?: number
    premiumCount?: number
    co2e?: string
    dataImportId?: string
}

export interface VariableCustomer extends VariableBaseNode {
    stripeId: string
}

export interface CompanyDomain extends VariableBaseNode {
    url: string
    txt?: string
    verified?: boolean
    verifiedDate?: number
    adminVerified?: boolean
    autoJoin?: boolean
}

export interface CompanyMembership {
    created: number
    updated?: number
}

export interface CompanyMember {
    user: User
    membership?: CompanyMembership
}

export enum UserRoleSlug {
    OWNER = 'owner',
    ADMIN = 'admin',
    CONTRIBUTOR = 'contributor',
}

export interface UserRole extends VariableBaseNode {
    slug: UserRoleSlug
    level: number
    isDefault?: boolean
}

export interface SupplierCustomerResponse {
    company: Company
    activities?: ActivityItem[]
    products?: Product[]
    co2e?: string
}

export const EmptyCompany = {
    name: '',
    contacts: [],
}

export enum CompanyActionType {
    Set = 'SetCompany',
    Reset = 'ResetCompany',
}

export interface CompanyListContext {
    list: Company[]
    byId: Map<string, Company>
}

type CompanyActionPayload = {
    [CompanyActionType.Set]: Company | undefined
    [CompanyActionType.Reset]: undefined
}

export type CompanyActions = ActionMap<CompanyActionPayload>[keyof ActionMap<CompanyActionPayload>]

export const CompanyReducer = (state: Company | undefined, action: CompanyActions): Company | undefined => {
    switch (action.type) {
        case CompanyActionType.Set:
            return action.payload
        case CompanyActionType.Reset:
            return undefined
        default:
            return state
    }
}

export enum SuppliersActionType {
    SetSuppliers = 'SetSuppliers',
    RemoveSupplier = 'RemoveSupplier',
    SetContacts = 'SetSupplierContacts',
}

type SuppliersActionPayload = {
    [SuppliersActionType.SetSuppliers]: Company[]
    [SuppliersActionType.RemoveSupplier]: Company
    [SuppliersActionType.SetContacts]: {
        companyId: string
        contacts: Contact[]
    }
}

export type SuppliersActions = ActionMap<SuppliersActionPayload>[keyof ActionMap<SuppliersActionPayload>]

export const SuppliersReducer = (state: CompanyListContext, action: SuppliersActions): CompanyListContext => {
    if (action.type === SuppliersActionType.SetSuppliers) {
        action.payload.forEach((c) => {
            if (c.uuid) {
                state.byId.set(c.uuid, { ...state.byId.get(c.uuid), ...c })
            }
        })
        state.list = Array.from(state.byId.values())
    } else if (action.type === SuppliersActionType.RemoveSupplier) {
        if (action.payload.uuid) state.byId.delete(action.payload.uuid)
        state.list = Array.from(state.byId.values())
    } else if (action.type === SuppliersActionType.SetContacts) {
        const supplier = state.byId.get(action.payload.companyId)
        if (supplier) {
            supplier.contacts = action.payload.contacts
            supplier.contactCount = action.payload.contacts.length
        }
    }
    return state
}

export enum CustomersActionType {
    SetCustomers = 'SetCustomers',
    RemoveCustomer = 'RemoveCustomer',
}

type CustomersActionPayload = {
    [CustomersActionType.SetCustomers]: Company[]
    [CustomersActionType.RemoveCustomer]: Company
}

export type CustomersActions = ActionMap<CustomersActionPayload>[keyof ActionMap<CustomersActionPayload>]

export const CustomersReducer = (state: CompanyListContext, action: CustomersActions): CompanyListContext => {
    if (action.type === CustomersActionType.SetCustomers) {
        action.payload.forEach((c) => {
            if (c.uuid) {
                state.byId.set(c.uuid, { ...state.byId.get(c.uuid), ...c })
            }
        })
        state.list = Array.from(state.byId.values())
    } else if (action.type === CustomersActionType.RemoveCustomer) {
        if (action.payload.uuid) state.byId.delete(action.payload.uuid)
        state.list = Array.from(state.byId.values())
    }
    return state
}

export enum MembersActionType {
    SetMembers = 'SetMembers',
    RemoveMember = 'RemoveMember',
}

type MembersActionPayload = {
    [MembersActionType.SetMembers]: CompanyMember[]
    [MembersActionType.RemoveMember]: User
}

export type MembersActions = ActionMap<MembersActionPayload>[keyof ActionMap<MembersActionPayload>]

export const MembersReducer = (state: CompanyMember[], action: MembersActions): CompanyMember[] => {
    switch (action.type) {
        case MembersActionType.SetMembers:
            return action.payload
        case MembersActionType.RemoveMember:
            return state.filter((m) => m.user.uuid !== action.payload.uuid)
        default:
            return state
    }
}

export enum UserRoleActionType {
    SetUserRole = 'SetUserRole',
}

type UserRoleActionPayload = {
    [UserRoleActionType.SetUserRole]: UserRole[]
}

export type UserRoleActions = ActionMap<UserRoleActionPayload>[keyof ActionMap<UserRoleActionPayload>]

export const UserRoleReducer = (state: UserRole[], action: UserRoleActions): UserRole[] => {
    switch (action.type) {
        case UserRoleActionType.SetUserRole:
            return action.payload
        default:
            return state
    }
}

export default class CompanyService extends VariableService {
    private basePath: string = '/company'
    public static webRootSupplierList: string = '/suppliers'
    public static webRootSupplier: string = '/supplier'
    public static webRootCustomerList: string = '/customers'
    public static webRootCustomer: string = '/customer'
    public static webRootAdmin: string = '/company'
    public static webRootUsers: string = '/company/users'
    public static webTitle = (plural: boolean = false): string => (plural ? 'Companies' : 'Company')
    public static webTitleSupplier: string = 'Suppliers'
    public static webTitleCustomer: string = 'Customers'

    public getCompanyUrl(
        company?: Company | null,
        perspective: CompanyPerspective = 'supplier',
        fullUrl: boolean = false,
    ): string {
        let url = `${fullUrl ? document.location.origin : ''}`
        const isSupplier = this.context.stores.suppliers.byId.get(company?.uuid || '')
        const isCustomer = this.context.stores.customers.byId.get(company?.uuid || '')
        if (perspective === 'supplier' && isSupplier) {
            url += `${CompanyService.webRootSupplier}/${company?.uuid}`
        } else if (perspective === 'customer' && isCustomer) {
            url += `${CompanyService.webRootCustomer}/${company?.uuid}`
        }
        return url
    }

    public static getCompanyImportanceString(value?: number): string {
        if (value === CompanyImportance.High) {
            return 'High'
        }
        if (value === CompanyImportance.Medium) {
            return 'Medium'
        }
        if (value === CompanyImportance.Low) {
            return 'Low'
        }
        return ''
    }

    public static getCompanyImportanceIcon(importance?: CompanyImportance): ReactNode {
        return (
            <span
                style={{ width: '.5rem', height: '.5rem' }}
                className={[
                    'rounded-circle me-1 d-inline-block nt--1',
                    importance === CompanyImportance.Low ? 'bg-dark bg-opacity-50' : '',
                    importance === CompanyImportance.Medium ? 'bg-warning' : '',
                    importance === CompanyImportance.High ? 'bg-danger' : '',
                ].join(' ')}
            />
        )
    }

    public static get companyId(): string | null {
        return localStorage.getItem('companyId')
    }

    public static set companyId(companyId: string | null) {
        const currentCompanyId = this.companyId
        if (companyId === null) {
            localStorage.removeItem('companyId')
        } else if (companyId !== currentCompanyId) {
            localStorage.setItem('companyId', companyId)
        }
    }

    private subdomainRegex = /^[a-z0-9-]+$/

    public isValidSubdomain(subdomain: string): boolean {
        return this.subdomainRegex.test(subdomain)
    }

    public isMyCompany(company?: Company | null): boolean {
        if (!company) return false
        return company.uuid === this.context.stores.company?.uuid
    }

    public isNewCompany(company?: Company | null, timeQuantity?: number, timeUnit?: ManipulateType): boolean {
        if (!company) company = this.context.stores.company
        return (
            company?.created !== undefined &&
            Utils._dayjs(company?.created).isAfter(Utils._dayjs().subtract(timeQuantity || 1, timeUnit || 'week'))
        )
    }

    public setCompany(uuid?: string, companies?: Company[]) {
        const userCompanies = companies || this.context.stores.user?.companies
        const qs = new URLSearchParams(document.location.search.substring(1))
        const qsUuid = qs.get('u')
        if (uuid) {
            CompanyService.companyId = userCompanies?.find((c) => c.uuid === uuid)?.uuid || null
        } else if (qsUuid) {
            CompanyService.companyId = userCompanies?.find((c) => c.uuid === qsUuid)?.uuid || null
        } else {
            const _previousCompany = userCompanies?.find((c) => c.uuid === CompanyService.companyId)
            if (_previousCompany) {
                return
            } else {
                CompanyService.companyId = userCompanies?.[0]?.uuid || null
            }
        }
    }

    public static companySizes = [
        { name: '1 - 10', value: 'very-small' },
        { name: '10 - 50', value: 'small' },
        { name: '50 - 500', value: 'medium' },
        { name: '500 - 1000', value: 'large' },
        { name: '1000+', value: 'enterprise' },
    ]

    public subdomainVisibilityOptions: KeyValuePair[] = [
        { name: 'Private', value: CompanySubdomainVisibility.PRIVATE, description: 'Only you can see the page' },
        // { name: 'Connected', value: CompanySubdomainVisibility.CONNECTED },
        { name: 'Public', value: CompanySubdomainVisibility.PUBLIC, description: 'Anyone can see the page' },
    ]

    public getCompany(companyId: string): Promise<Company> {
        if (!companyId) {
            return Promise.reject()
        }
        return this.httpService.get<Company>(`${this.basePath}/${companyId}`)
    }

    public getCompanyBySlug(): Promise<Company> {
        return this.httpService.get<Company>(this.basePath)
    }

    public checkCompanyDomain(): Promise<Company> {
        return this.httpService.get<Company>(`${this.basePath}/${CompanyService.companyId}?d=1`)
    }

    public getSlimCompanies() {
        const qs = new URLSearchParams(
            'return=uuid,name,logoUrl,type,productCount,activityCount,co2e,claimed,spend,contacts,dataImportId&limit=-1',
        )
        this.getSuppliers(qs.toString()).then()
        this.getCustomers(qs.toString()).then()
    }

    public async getMyCompany(): Promise<Company | undefined> {
        if (CompanyService.companyId) {
            return this.getCompany(CompanyService.companyId)
                .then((fullCompany) => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    this.context.dispatch({ type: CompanyActionType.Set, payload: fullCompany })
                    return fullCompany
                })
                .catch(() => {
                    CompanyService.companyId = null
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    this.context.dispatch({ type: CompanyActionType.Reset })
                    return undefined
                })
        }
        return Promise.resolve(undefined)
    }

    public getPremiumFactors(isAdmin: boolean = false): Promise<PremiumFactors[]> {
        if (!this.context.stores.company?.uuid) {
            return Promise.reject('No company')
        }
        let url = `${this.basePath}/${this.context.stores.company?.uuid}/premium`
        if (isAdmin) {
            url += '?a=1'
        }
        return this.httpService.get<PremiumFactors[]>(url)
    }

    public async updateCompany(company: Company): Promise<Company> {
        return this.httpService
            .put<Company>(`${this.basePath}/${company.uuid}`, {
                body: JSON.stringify({ company: company }),
            })
            .then((updatedCompany) => {
                if (updatedCompany.uuid === this.context.stores.company?.uuid) {
                    this.context.dispatch({
                        type: CompanyActionType.Set,
                        payload: { ...this.context.stores.company!, ...updatedCompany },
                    })
                } else {
                    const isCustomer = this.context.stores.customers.byId.get(updatedCompany.uuid || '')
                    if (isCustomer) {
                        this.context.dispatch({ type: CustomersActionType.SetCustomers, payload: [updatedCompany] })
                    } else {
                        this.context.dispatch({ type: SuppliersActionType.SetSuppliers, payload: [updatedCompany] })
                    }
                }
                return updatedCompany
            })
    }

    public getSubscriptions(subscriptionId?: string): Promise<Subscription[]> {
        let url = `${this.basePath}/subscription`
        if (subscriptionId) {
            url += `/${subscriptionId}`
        }
        return this.httpService.get<Subscription[]>(url)
    }

    public updateSubscription(companyId: string, subscriptionId: string): Promise<Company[]> {
        return this.httpService.put<Company[]>(this.basePath, {
            body: JSON.stringify({
                companyId: companyId,
                subscriptionId: subscriptionId,
            }),
        })
    }

    public updateDomain(company: Company, domain: CompanyDomain, isAdmin: boolean = false): Promise<CompanyDomain> {
        return this.httpService.put<CompanyDomain>(`${this.basePath}/${company.uuid}`, {
            body: JSON.stringify({ domain: domain, isAdmin: isAdmin }),
        })
    }

    public removeDomain(company: Company, domain: CompanyDomain): Promise<CompanyDomain> {
        return this.httpService.delete<CompanyDomain>(`${this.basePath}/${company.uuid}/domain/${domain.uuid}`)
    }

    public async createSupplier(supplier: Company): Promise<Company> {
        return this.httpService
            .post<Company>(this.basePath, {
                body: JSON.stringify({
                    supplier: supplier,
                }),
            })
            .then((c) => {
                this.context.dispatch({ type: SuppliersActionType.SetSuppliers, payload: [c] })
                return c
            })
    }

    public connectVia(user: UserWithoutID, company: Company, customMessage: string): Promise<Company[]> {
        return this.httpService.post<Company[]>(`${this.basePath}/${company.uuid}`, {
            body: JSON.stringify({
                connectVia: user,
                customMessage: customMessage,
            }),
        })
    }

    public async deleteSupplier(supplier: Company): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/supplier/${supplier.uuid}`).then(() => {
            this.context.dispatch({ type: SuppliersActionType.RemoveSupplier, payload: supplier })
        })
    }

    public async createCustomer(customer: Company): Promise<Company> {
        return this.httpService
            .post<Company>(this.basePath, {
                body: JSON.stringify({
                    customer: customer,
                }),
            })
            .then((c) => {
                this.context.dispatch({ type: CustomersActionType.SetCustomers, payload: [c] })
                return c
            })
    }

    public async deleteCustomer(customer: Company): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/customer/${customer.uuid}`).then(() => {
            this.context.dispatch({ type: CustomersActionType.RemoveCustomer, payload: customer })
        })
    }

    public async inviteViaEmail(emailList: string[]): Promise<void> {
        return this.httpService
            .post<void>(`${this.basePath}/member`, {
                body: JSON.stringify({
                    emailList: emailList,
                }),
            })
            .then(() => {
                this.getMyCompany().then()
                this.getMembers().then()
            })
    }

    public getConnectedSupplierCount(): number {
        return this.context.stores.suppliers?.list.filter((s) => s?.claimed)?.length || 0
    }

    public hasMoreSeats(seatsBeingAdded: number = 0): boolean {
        if (!this.context.stores.company?.subscription?.uuid) {
            return false
        }
        return this.getMemberCount() + Math.max(seatsBeingAdded, 0) < this.context.stores.company?.subscription?.users
    }

    public getMemberCount(): number {
        return this.context.stores.members.filter((m) => !Utils.isVariableEmail(m.user.email)).length
    }

    public async getMembers(cacheOk: boolean = false): Promise<CompanyMember[]> {
        const cached = this.context.stores.members
        if (cached?.length && cacheOk) {
            return Promise.resolve(cached)
        }
        return this.httpService.get<CompanyMember[]>(`${this.basePath}/member`).then((members) => {
            this.context.dispatch({
                type: MembersActionType.SetMembers,
                payload: members?.filter((m) => {
                    const userIsVariable = Utils.isVariableEmail(this.context.stores.user?.email)
                    const memberIsVariable = Utils.isVariableEmail(m.user.email)
                    return userIsVariable || !memberIsVariable || m.user.email === this.context.stores.user?.email
                }),
            })
            return members
        })
    }

    public async removeMember(user: User): Promise<void> {
        return this.httpService.delete<void>(`${this.basePath}/member/${user.uuid}`).then(() => {
            this.context.dispatch({ type: MembersActionType.RemoveMember, payload: user })
        })
    }

    public toggleCompanyMembership(company: Company): Promise<boolean> {
        const isMember = this.context.stores.user?.companies?.find((c) => c.uuid === company.uuid)
        if (isMember) {
            return this.leaveCompany(company)
        }
        return this.joinCompany(company)
    }

    public async joinCompany(company: Company): Promise<boolean> {
        return this.httpService.post<boolean>(`${this.basePath}/member`, {
            body: JSON.stringify({ joinCompany: company.uuid }),
        })
    }

    public async leaveCompany(company: Company): Promise<boolean> {
        return this.httpService.post<boolean>(`${this.basePath}/member`, {
            body: JSON.stringify({ leaveCompany: company.uuid }),
        })
    }

    public async getSuppliers(queryString?: string): Promise<ListResponse> {
        return this.httpService.get<ListResponse>(`${this.basePath}/supplier?${queryString || ''}`).then((slr) => {
            this.context.dispatch({ type: SuppliersActionType.SetSuppliers, payload: slr.data as Company[] })
            return slr
        })
    }

    public async getSupplier(supplierId: string): Promise<SupplierCustomerResponse> {
        return this.httpService.get<SupplierCustomerResponse>(`${this.basePath}/supplier/${supplierId}`).then((scr) => {
            this.context.dispatch({ type: SuppliersActionType.SetSuppliers, payload: [scr.company] })
            return scr
        })
    }

    public async getCustomers(queryString?: string): Promise<ListResponse> {
        return this.httpService.get<ListResponse>(`${this.basePath}/customer?${queryString || ''}`).then((clr) => {
            this.context.dispatch({ type: CustomersActionType.SetCustomers, payload: clr.data as Company[] })
            return clr
        })
    }

    public async getCustomer(customerId: string): Promise<SupplierCustomerResponse> {
        return this.httpService.get<SupplierCustomerResponse>(`${this.basePath}/customer/${customerId}`).then((scr) => {
            this.context.dispatch({ type: CustomersActionType.SetCustomers, payload: [scr.company] })
            return scr
        })
    }

    private updateContactsCache(companyId: string, contacts: Contact[]) {
        this.context.dispatch({
            type: SuppliersActionType.SetContacts,
            payload: { companyId: companyId, contacts: contacts },
        })
    }

    private getCachedContacts(companyId: string): Contact[] {
        const company =
            this.context.stores.suppliers.byId.get(companyId) || this.context.stores.customers.byId.get(companyId)
        return company?.contacts || []
    }

    public async getContacts(companyId: string, cacheOk: boolean = false): Promise<Contact[]> {
        if (cacheOk) {
            const cached = this.getCachedContacts(companyId)
            if (cached.length) {
                return Promise.resolve(cached)
            }
        }
        return this.httpService.get<Contact[]>(`${this.basePath}/${companyId}/contacts`).then((contacts) => {
            this.updateContactsCache(companyId, contacts)
            return contacts
        })
    }

    public async updateContacts(companyId: string, contacts: Contact[]): Promise<Contact[]> {
        return this.httpService
            .put<Contact[]>(`${this.basePath}/${companyId}/contacts`, {
                body: JSON.stringify({ contacts: contacts }),
            })
            .then((contacts) => {
                this.updateContactsCache(companyId, contacts)
                return contacts
            })
    }

    public async deleteContact(companyId: string, contact: Contact): Promise<Contact[]> {
        return this.httpService
            .delete<Contact[]>(`${this.basePath}/${companyId}/contact/${contact.uuid}`)
            .then((contacts) => {
                this.updateContactsCache(companyId, contacts)
                return contacts
            })
    }

    public shareWithSupplier(supplier: Company): Promise<SharingToken> {
        return this.httpService.put<SharingToken>(`${this.basePath}/supplier/${supplier.uuid}`, {
            body: JSON.stringify({
                share: supplier.uuid,
            }),
        })
    }

    public claimSupplierConnection(token: string): Promise<Company> {
        return this.httpService.put<Company>(`${this.basePath}/supplier`, {
            body: JSON.stringify({ claim: token }),
        })
    }

    public saveCompanySpend(
        perspective: CompanyPerspective,
        company: Company,
        spend: CompanySpend[],
    ): Promise<CompanySpend[]> {
        return this.httpService.put<CompanySpend[]>(`${this.basePath}/${perspective}/${company.uuid}`, {
            body: JSON.stringify({ spend: spend }),
        })
    }

    public disconnectSupplierConnection(token: string): Promise<Company> {
        return this.httpService.put<Company>(`${this.basePath}/supplier`, {
            body: JSON.stringify({ disconnect: token }),
        })
    }

    public async mergeSuppliers(supplier: Company, mergeIds: string[]): Promise<Company[]> {
        return this.httpService
            .post<Company[]>(`${this.basePath}/supplier/${supplier.uuid}`, {
                body: JSON.stringify({ mergeIds: mergeIds }),
            })
            .then((c) => {
                this.getSlimCompanies()
                return c
            })
    }

    public async getIndustries(): Promise<IndustrySector[]> {
        return this.httpService
            .get<IndustrySector[]>(`${this.basePath}/industry`, { ttlInSeconds: 60 * 60 * 24 })
            .then((industries) => {
                this.context.dispatch({ type: IndustryActionType.Set, payload: industries })
                return industries
            })
    }

    public async getRoles(): Promise<UserRole[]> {
        return this.httpService.get<UserRole[]>(`${this.basePath}/role`).then((roles) => {
            this.context.dispatch({ type: UserRoleActionType.SetUserRole, payload: roles })
            return roles
        })
    }
}
