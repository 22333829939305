import { HTMLAttributeAnchorTarget, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Utils from '../../services/utils'
import { allowedDocumentFileTypes, FileData } from '../../services/file'
import { Link } from 'react-router-dom'
import Button from './Button'
import Delete from '../Delete'
import { Paperclip } from '@phosphor-icons/react'
import { VariableServicesContext } from '../../services'
import { StandardAttributes, VariableNodeType } from '../../types'
import { FileIcon } from './FileIcon'
import { Modal } from '../Modal'
import { FileViewer } from './FileViewer'

const MAX_MB = 50

type FileProps = StandardAttributes & {
    label?: ReactNode
    labelProps?: StandardAttributes
    nodeType?: VariableNodeType
    nodeId?: string
    labelClassName?: string
}

export const FileUploader = (
    props: FileProps & {
        children?: ReactNode
        onUpload: (fileData: FileData) => void
    },
) => {
    const { fileService } = useContext(VariableServicesContext)
    const [file, setFile] = useState<File>()
    const [saving, setSaving] = useState<boolean>(false)
    const fileReader = new FileReader()
    const id = `fr-${Math.random()}`
    const fileRef = useRef<any>()

    useEffect(() => {
        if (!file) {
            return
        }
        const fileNameParts = file.name.split('.')
        const fileType = fileNameParts[fileNameParts.length - 1]
        if (!allowedDocumentFileTypes.includes(fileType?.toLowerCase())) {
            Utils.errorToast(`${fileType} files are not allowed`)
            return
        }
        if (file.size > Utils.ONE_MB * MAX_MB) {
            Utils.errorToast(`Documents cannot be larger than ${MAX_MB}mb`)
            return
        } else {
            fileReader.readAsDataURL(file)
        }
    }, [file])

    fileReader.onload = async () => {
        if (file && props.nodeId) {
            setSaving(true)
            fileService
                .uploadDocument(props.nodeType || 'Document', props.nodeId, file)
                .then((fileData) => props.onUpload(fileData))
                .catch(Utils.errorToast)
                .finally(() => {
                    setFile(undefined)
                    setSaving(false)
                    fileRef.current.value = ''
                })
        }
    }

    return (
        <label
            htmlFor={id}
            className={[
                props.nodeId ? '' : 'opacity-25 not-clickable',
                props.labelClassName || 'position-relative btn btn-sm btn-light',
                props.disabled ? 'not-clickable text-muted' : '',
            ].join(' ')}
        >
            {saving && (
                <div className='fill-parent bg-white bg-opacity-50 d-flex align-items-center justify-content-center'>
                    <span className='spinner-border spinner-border-sm' />
                </div>
            )}
            {props.children || (
                <div className='d-flex align-items-center gap-1 clickable'>
                    <Paperclip />
                    {props.label || 'Upload'}
                </div>
            )}
            <input
                id={id}
                ref={fileRef}
                disabled={props.disabled}
                className={props.className}
                type='file'
                onClick={(e) => {
                    if (!props.nodeId) {
                        e.preventDefault()
                        e.stopPropagation()
                    }
                }}
                onChange={(e) => {
                    if (e.target.files && e.target.files.length === 1) {
                        setFile(e.target.files[0])
                    }
                }}
            />
        </label>
    )
}

type DocumentItemType = {
    nodeId?: string
    disabled?: boolean
    target?: HTMLAttributeAnchorTarget
    onUnAuthClick?: (e: any) => void
    onRemove?: (fileData: FileData) => void
}

type DocumentListProps = DocumentItemType & {
    className?: string
    documentClassName?: string
    nodeType?: VariableNodeType
    files?: FileData[]
}

export const DocumentList = (props: DocumentListProps) => {
    const { fileService } = useContext(VariableServicesContext)
    const [files, setFiles] = useState<FileData[] | undefined>(props.files)
    const [file, setFile] = useState<FileData>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => setFiles(props.files), [props.files])

    useEffect(() => {
        if (props.nodeId && props.files === undefined) {
            setLoading(true)
            fileService
                .getFilesByNodeId(props.nodeId)
                .then(setFiles)
                .finally(() => setLoading(false))
        }
    }, [props.nodeId])

    if (loading) return <span className='spinner-border spinner-border-sm' />

    if (!files?.length) return <></>

    return (
        <>
            <div className={props.className}>
                {files
                    ?.sort(Utils.sortByUpdatedOrCreated)
                    ?.map((file) => (
                        <DocumentItem
                            key={`doc-${file.uuid}`}
                            file={file}
                            nodeId={props.nodeId}
                            target={props.target}
                            disabled={props.disabled}
                            className={props.documentClassName}
                            onClick={setFile}
                            onRemove={props.onRemove}
                            onUnAuthClick={props.onUnAuthClick}
                        />
                    ))}
            </div>
            {file && (
                <Modal
                    hidden={false}
                    size='custom'
                    style={{ minWidth: '90vw' }}
                    extraClassName='flex-grow-1'
                    contentStyle={{ height: '90vh' }}
                    header={
                        <>
                            <FileIcon file={file} extraClassName='me-1' />
                            {file.originalFileName || file.name || fileService.friendlyPath(file.hashedPath || '')}
                        </>
                    }
                    content={<FileViewer documentPath={file.hashedPath} url={file.url} />}
                    onVisibilityChange={(isVisible) => {
                        if (!isVisible) setFile(undefined)
                    }}
                />
            )}
        </>
    )
}

export const DocumentItem = (
    props: DocumentItemType & {
        className?: string
        file: FileData
        onClick: (file: FileData) => void
    },
) => {
    const { fileService, authenticationService } = useContext(VariableServicesContext)
    const tooltip = `${props.file.originalFileName || props.file.name} (Created: ${Utils.toDateTimeString(
        props.file.updated || props.file.created,
    )})`
    return (
        <span
            className={[
                'position-relative d-inline-flex align-items-center hover-parent-direct bg-light-hover p-1 rounded-2 max-w-100',
                props.className,
            ].join(' ')}
        >
            <Link
                to={`/documents/${props.file.hashedPath}`}
                className='flex-grow-1 small text-overflow-ellipsis'
                onClick={(e) => {
                    if (!authenticationService.isOnboarded()) {
                        e.preventDefault()
                        e.stopPropagation()
                        props.onUnAuthClick?.(e)
                    } else if (!Utils.isModifierKey(e)) {
                        e.preventDefault()
                        props.onClick(props.file)
                    }
                }}
            >
                <FileIcon file={props.file} extraClassName='me-1' />
                <span title={tooltip}>{props.file.originalFileName || props.file.name}</span>
            </Link>
            {!authenticationService.isOnboarded() && (
                <Button
                    className='fill-parent btn btn-sm btn-plain bg-white bg-opacity-50 hover-parent'
                    onClick={props.onUnAuthClick}
                >
                    <span className='d-flex align-items-center justify-content-center show-on-hover fill-parent bg-white bg-opacity-75'>
                        <span className='btn btn-sm btn-secondary text-nowrap'>Sign up</span>
                    </span>
                </Button>
            )}
            {!props.disabled && props.onRemove && props.file && props.nodeId && (
                <Delete
                    className='show-on-hover ms-1'
                    deleteFn={() =>
                        fileService.deleteFile(props.file, props.nodeId || '').then(() => props.onRemove?.(props.file))
                    }
                    iconOnly={true}
                />
            )}
        </span>
    )
}

export const FileManager = (props: FileProps & { documentListProps?: Partial<DocumentListProps> }) => {
    const { fileService } = useContext(VariableServicesContext)
    const [files, setFiles] = useState<FileData[]>()

    const getDocumentList = useCallback(() => {
        if (!props.nodeId) return
        fileService.getFilesByNodeId(props.nodeId).then(setFiles)
    }, [props.nodeId])

    return (
        <div className={[props.className || 'position-relative', props.extraClassName].join(' ')} style={props.style}>
            <DocumentList
                nodeId={props.nodeId}
                files={files}
                disabled={props.disabled}
                onRemove={() => getDocumentList()}
                {...props.documentListProps}
            />
            <FileUploader
                label={props.label || 'Add documentation'}
                disabled={props.disabled}
                nodeType={props.nodeType}
                nodeId={props.nodeId}
                className='visually-hidden'
                labelClassName={props.labelClassName || 'btn btn-xs btn-light border'}
                onUpload={() => getDocumentList()}
                {...props.labelProps}
            />
        </div>
    )
}
