import { Handle, NodeProps, Position } from 'reactflow'
import OrgService, { Org, OrgActionType, OrgNodeData } from '../../services/org'
import { ORG_NODE_HEIGHT, ORG_NODE_WIDTH } from '../../services/flow'
import CO2e from '../CO2e'
import { useContext, useEffect, useRef, useState } from 'react'
import Button from '../Input/Button'
import { PlusCircle } from '@phosphor-icons/react'
import InputField from '../Input/InputField'
import { UserCard } from '../User/UserCard'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import Utils, { LocalOption } from '../../services/utils'

interface OrgNodeProps extends NodeProps {
    data: OrgNodeData
}

export const OrgNode = ({ dragging, data }: OrgNodeProps) => {
    const context = useContext(ApplicationContext)
    const { orgService } = useContext(VariableServicesContext)
    const [org, setOrg] = useState<Org>()
    const inputRef = useRef<HTMLInputElement>()
    const _co2e = parseFloat(org?.categoryCo2e || '0')

    useEffect(
        () =>
            setOrg({
                ...data.org,
                uuid: OrgService.isTemp(data.org) ? undefined : data.org.uuid,
            } as Org),
        [data.org],
    )

    useEffect(() => {
        if (context.stores.orgs.current && context.stores.orgs.current?.uuid === org?.uuid) {
            setOrg(context.stores.orgs.current)
        }
    }, [context.stores.orgs.current])

    useEffect(() => {
        if (data.highlight === false) {
            inputRef.current?.focus()
        }
    }, [data.highlight])

    return (
        <div
            role='button'
            tabIndex={0}
            className={[
                'border border-primary rounded-1 p-2 small position-relative hover-parent shadow text-primary',
                data.highlight ? 'border-secondary bg-secondary bg-opacity-5' : 'bg-white border',
                OrgService.isTemp(org) && !data.highlight ? 'flow-org-node-transition' : '',
                OrgService.isOther(org) ? 'border-dashed' : '',
                !OrgService.isTemp(org) ? 'bg-light-hover border-primary-hover' : '',
                dragging ? 'grabbing' : '',
                data.reordering ? 'cursor-reorder-y' : '',
                context.stores.orgs.current?.uuid && context.stores.orgs.current?.uuid === org?.uuid ? 'bg-light' : '',
            ].join(' ')}
            style={{ width: `${ORG_NODE_WIDTH}px`, height: `${ORG_NODE_HEIGHT}px` }}
            onClick={() => {
                if (org) {
                    context.dispatch({ type: OrgActionType.SetOrg, payload: org })
                }
            }}
            onKeyDown={() => {}}
        >
            <div className='d-flex flex-column overflow-hidden h-100 text-center'>
                <div className='text-overflow-ellipsis' title={orgService.getOrgName(org)}>
                    {org?.uuid && orgService.getOrgName(org)}
                    {Utils.getLocalOption(LocalOption.SHOW_DEBUG_DATA) && context.stores.ui?.altKey && (
                        <>
                            <span className='position-absolute start-0 top-0 mt--1 ms--1 px-1 border bg-light rounded-1 small text-alt-font'>
                                {org?.level}:{org?.order}:{Math.round(org?.leftEdge || 0)}-
                                {Math.round(org?.rightEdge || 0)}
                            </span>
                            <span className='position-absolute end-0 top-0 mt--1 me--1 px-1 border bg-light rounded-1 small text-alt-font'>
                                {org?.maxChildLevel}
                            </span>
                        </>
                    )}
                    {!org?.uuid && (
                        <InputField
                            passedRef={inputRef}
                            placeholder='Org name'
                            className='variable-form-control w-100 text-center'
                            onEscapeKey={() => data.onEscape()}
                            onChange={(newValue) => setOrg((state) => ({ ...state!, name: newValue }))}
                            onEnterOrBlur={() => {
                                if (org?.name) {
                                    orgService.createOrUpdate(org).then((_org) => {
                                        setOrg(_org)
                                        data.onUpdate(_org)
                                    })
                                }
                            }}
                        />
                    )}
                </div>
                <div className='mt-auto d-flex align-items-center justify-content-center gap-2'>
                    <div className='w-25' />
                    <div className='w-50'>
                        <CO2e
                            co2e={_co2e}
                            unitSize='large'
                            precision={2}
                            shortUnit={true}
                            showZeroesToPrecision={true}
                            showUnit={_co2e > 0}
                            onZero='dash'
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-end flex-grow-0 w-25 overflow-hidden pt-1 mt--1'>
                        {!OrgService.isOther(org) &&
                            org?.users
                                ?.slice(0, 3)
                                ?.map((ou, idx) => (
                                    <UserCard
                                        key={`uc-${org?.uuid}-${ou.uuid}`}
                                        user={ou}
                                        iconOnly={true}
                                        style={{ marginLeft: `${idx ? '-15' : 0}px` }}
                                    />
                                ))}
                    </div>
                </div>
            </div>
            {org?.uuid && !org?.isUncategorized && !OrgService.isTemp(org) && !OrgService.isOther(org) && (
                <Button
                    className='btn btn-plain show-on-hover position-absolute start-50 translate-middle-x mt--1 z-index-tooltip bg-white rounded-circle p-0 text-primary-hover'
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        data.onCreate()
                    }}
                >
                    <PlusCircle enableBackground={2} />
                </Button>
            )}
            <Handle
                className={OrgService.isSystem(org) ? 'visibility-hidden' : ''}
                type='target'
                position={Position.Top}
                isConnectable={false}
            />
            <Handle
                className={OrgService.isTemp(org) || OrgService.isOther(org) ? 'visibility-hidden' : ''}
                type='source'
                position={Position.Bottom}
                isConnectable={false}
            />
        </div>
    )
}
