import { User } from './user-context'
import VariableService from './service'
import { VariableBaseNode } from '../types'
import { UIOptionActionType } from './ui'
import { Company } from './company'

export interface Flag extends VariableBaseNode {
    name: string
    enabled: boolean
    archived?: boolean
    users?: User[]
    companies?: Company[]
}

export interface Flags {
    [name: string]: Flag
}

export default class FlagService extends VariableService {
    private basePath: string = '/flag'
    public static ready: boolean = false
    public static flags: Flags = {}
    public static enabledFlags: Set<string> = new Set()

    public flagEnabled(flag: string): boolean {
        return FlagService.enabledFlags.has(flag)
    }

    public async getFlags(): Promise<Flags> {
        return this.httpService.get<Flags>(this.basePath).then((flags) => {
            FlagService.ready = true
            FlagService.flags = flags
            Object.keys(flags).forEach((flag) => {
                if (flags[flag].enabled) FlagService.enabledFlags.add(flag)
            })
            this.context.dispatch({ type: UIOptionActionType.FlagsReady, payload: true })
            return flags
        })
    }

    public async archiveFlag(flag: Flag): Promise<Flag> {
        return this.httpService.put<Flag>(this.basePath, {
            body: JSON.stringify({ flagId: flag.uuid, archive: true }),
        })
    }

    public async getFlagsAdmin(): Promise<Flags> {
        return this.httpService.get<Flags>(`${this.basePath}?u=1`).then((flags) => {
            FlagService.flags = flags
            this.context.dispatch({ type: UIOptionActionType.FlagsReady, payload: true })
            return flags
        })
    }

    public async saveFlag(flag: Flag): Promise<Flag> {
        return this.httpService.post<Flag>(this.basePath, {
            body: JSON.stringify({ flag: flag }),
        })
    }

    public async addIdToFlag(flag: Flag, id: string): Promise<Flag> {
        return this.httpService.put<Flag>(this.basePath, {
            body: JSON.stringify({ flagId: flag.uuid, addId: id }),
        })
    }

    public async removeIdFromFlag(flag: Flag, id: string): Promise<Flag> {
        return this.httpService.put<Flag>(this.basePath, {
            body: JSON.stringify({ flagId: flag.uuid, removeId: id }),
        })
    }
}
