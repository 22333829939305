import { Link } from 'react-router-dom'
import { Img } from './Img'
import Tooltip from './Tooltip'
import { UserCompanyList } from './Company/UserCompanyList'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { PlanLabel } from './Navigation'
import AuthenticationService from '../services/authentication'
import CompanyService from '../services/company'
import { ApplicationContext } from '../context'
import { Bell, Coffee, Gear, IdentificationBadge, SignOut, User, Users } from '@phosphor-icons/react'
import ActivityService, { ActivityState } from '../services/activity'
import { StatusMessage } from '../services/status'
import Utils from '../services/utils'
import { VariableServicesContext } from '../services'
import { Notification } from './Icons/Notification'
import UserService from '../services/user'
import DataRequestService, { DataRequestState } from '../services/dataRequest'
import { PrettyNumber } from './PrettyNumber'
import { ColorModeSelector } from './User/ColorModeSelector'
import FlagService from '../services/flag'
import { SuperPowers } from '../services/user-context'

export const AccountHeader = (props: { className?: string; style?: CSSProperties }) => {
    const context = useContext(ApplicationContext)
    const { statusService, userService } = useContext(VariableServicesContext)
    const [statusMessages, setStatusMessages] = useState<StatusMessage[]>([])
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>()
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false)
    const [companyMenuOpen, setCompanyMenuOpen] = useState<boolean>(false)

    useEffect(() => {
        const messages: StatusMessage[] = [...(context.stores.status.messages || [])]
        if (context.stores.status.pendingSupplierRequestCount) {
            messages.unshift({
                item: (
                    <Link
                        to={`${DataRequestService.webRootCollect}?filterBy=state&id=${DataRequestState.PENDING}`}
                        className='d-block bg-light-hover py-2 px-3 underline-on-hover'
                    >
                        <PrettyNumber num={context.stores.status.pendingSupplierRequestCount} /> pending{' '}
                        {DataRequestService.webTitleRequest(
                            context.stores.status.pendingSupplierRequestCount !== 1,
                        ).toLowerCase()}
                    </Link>
                ),
            })
        }
        if (context.stores.status.pendingCustomerRequestCount) {
            messages.unshift({
                item: (
                    <Link
                        to={DataRequestService.webRootShare}
                        className='d-block bg-light-hover py-2 px-3 underline-on-hover'
                    >
                        <PrettyNumber num={context.stores.status.pendingCustomerRequestCount} /> pending{' '}
                        {DataRequestService.webTitleShare(
                            context.stores.status.pendingCustomerRequestCount !== 1,
                        ).toLowerCase()}
                    </Link>
                ),
            })
        }
        if (context.stores.status.activityPendingCount) {
            messages.unshift({
                item: (
                    <Link
                        to={`${ActivityService.webRootActivities}?activityStates=${ActivityState.PENDING}&when=all`}
                        className='d-block bg-light-hover py-2 px-3 underline-on-hover'
                    >
                        <PrettyNumber num={context.stores.status.activityPendingCount} /> pending{' '}
                        {ActivityService.webTitle(context.stores.status.activityPendingCount !== 1).toLowerCase()}
                    </Link>
                ),
            })
        }
        setStatusMessages(messages)
    }, [context.stores.status])

    return (
        <div
            className={`d-flex align-items-stretch justify-content-end flex-border-between gap-3 ${props.className}`}
            style={props.style}
        >
            <div className='d-flex align-items-center'>
                <Tooltip
                    trigger='click'
                    interactive={true}
                    placement='bottom-end'
                    showArrow={false}
                    offset={[10, 11]}
                    closeButton={false}
                    closeOnInteraction={true}
                    tooltipClassName='small border p-0'
                    tooltipContent={
                        <div className='position-relative overflow-hidden'>
                            <div className='fs-6 bg-light px-3 py-2 m-0 border-bottom shadow-sm'>Notifications</div>
                            <div>
                                {statusMessages.map((sm, idx) => (
                                    <span key={`msg-${idx}`}>{sm.item || sm.message || ''}</span>
                                ))}
                                {!statusMessages.length && (
                                    <div className='p-3'>
                                        Nothing right now <Coffee size={Utils.verySmallIconSize} />
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    onClick={() => statusService.getStatus()}
                    className='position-relative d-flex align-items-start'
                >
                    <Bell size={Utils.largeIconSize} />
                    <Notification count={statusMessages.length} size={12} style={{ top: '-2px', right: '-1px' }} />
                </Tooltip>
            </div>
            <div className='d-flex align-items-center'>
                <Tooltip
                    ariaLabel='User menu'
                    trigger='click'
                    placement='bottom-end'
                    interactive={true}
                    offset={[10, 9]}
                    closeOnOutsideClick={true}
                    visible={userMenuOpen}
                    onVisibleChange={setUserMenuOpen}
                    onClick={() => userService.hasSuperPower(SuperPowers.GLOBAL_ADMIN).then(setIsSuperAdmin)}
                    tooltipClassName='p-2'
                    tooltipContent={
                        <>
                            <div className='d-flex align-items-center gap-2 px-2 mb-2'>
                                <span className='small'>{userService.getColorModeIcon()} Theme:</span>
                                <ColorModeSelector />
                            </div>
                            <Link
                                to={UserService.webRootProfile}
                                className='dropdown-item small'
                                onClick={() => setUserMenuOpen(false)}
                            >
                                <User /> Your profile
                            </Link>
                            {isSuperAdmin && (
                                <Link
                                    to='/admin'
                                    className='dropdown-item small'
                                    onClick={() => setUserMenuOpen(false)}
                                >
                                    <IdentificationBadge className='nt--1 me-1' />
                                    Admin
                                </Link>
                            )}
                            <hr className='my-2 border-top border-dark-subtle' />
                            <Link
                                to={AuthenticationService.signOutPath}
                                className='dropdown-item small'
                                onClick={() => setUserMenuOpen(false)}
                            >
                                <SignOut /> Sign out
                            </Link>
                        </>
                    }
                >
                    <span className='d-flex align-items-center gap-2 text-nowrap'>
                        {!context.stores.user?.profileImageUrl && (
                            <span className='d-block text-overflow-ellipsis' style={{ maxWidth: '10rem' }}>
                                {context.stores.user?.firstName || 'You'}
                            </span>
                        )}
                        <Img
                            src={context.stores.user?.profileImageUrl}
                            alt={context.stores.user?.firstName}
                            size='30px'
                            className='rounded-circle'
                        />
                    </span>
                </Tooltip>
            </div>
            {!FlagService.enabledFlags.has('UseCustomerLogo') && (
                <div className='d-flex align-items-center'>
                    <Tooltip
                        ariaLabel='Company menu'
                        interactive={true}
                        trigger='click'
                        placement='bottom-end'
                        visible={companyMenuOpen}
                        onVisibleChange={setCompanyMenuOpen}
                        hidden={!context.stores.company?.name}
                        offset={[10, 5]}
                        tooltipStyle={{ minWidth: '8rem' }}
                        tooltipContent={
                            <>
                                <Link
                                    to={CompanyService.webRootAdmin}
                                    className='dropdown-item small'
                                    onClick={() => setCompanyMenuOpen(false)}
                                >
                                    <Gear /> Settings
                                </Link>
                                <Link
                                    to={CompanyService.webRootUsers}
                                    className='dropdown-item small'
                                    onClick={() => setCompanyMenuOpen(false)}
                                >
                                    <Users /> Users
                                </Link>
                                <UserCompanyList extraClassName='border-top' />
                            </>
                        }
                    >
                        <span className='d-flex align-items-center py-1'>
                            {!context.stores.company?.logoUrl && (
                                <span className='d-block text-overflow-ellipsis' style={{ maxWidth: '10rem' }}>
                                    {context.stores.company?.name || 'Company'}
                                </span>
                            )}
                            <Img
                                src={context.stores.company?.logoUrl}
                                alt={context.stores.company?.name}
                                width='auto'
                                height='30px'
                            />
                        </span>
                    </Tooltip>
                </div>
            )}
            <PlanLabel className='d-flex align-items-center' />
        </div>
    )
}
