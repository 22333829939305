import InputService, { Input } from '../services/input'
import { Product } from '../services/product'
import { useCallback, useContext, useEffect, useState } from 'react'
import { VariableServicesContext } from '../services'
import { ApplicationContext } from '../context'
import UnitService from '../services/unit'
import ProcessingService from '../services/processing'
import TransportService, { TransportInstance, TransportType } from '../services/transport'
import UseStageService from '../services/useStage'

export const useInputType = (props: {
    instanceId: string
    onDeleteTransportInstance?: (ti: TransportInstance) => void
}) => {
    const context = useContext(ApplicationContext)
    const { analyticsService, productService, inputService, transportService, processingService, useStageService } =
        useContext(VariableServicesContext)
    const [instanceId, setInstanceId] = useState<string>(props.instanceId)
    const [creatingUseStage] = useState<boolean>(false)
    const [creatingTransport] = useState<boolean>(false)
    const [creatingProcessing] = useState<boolean>(false)

    useEffect(() => setInstanceId(props.instanceId), [props.instanceId])

    useEffect(() => {
        if (
            context.stores.transport?.node?.uuid === instanceId &&
            context.stores.transport.updates &&
            context.stores.transport.deletedTransportInstance
        ) {
            props.onDeleteTransportInstance?.(context.stores.transport.deletedTransportInstance)
            transportService.unsetTransportContext()
        }
    }, [context.stores.transport.deletedTransportInstance])

    const editTransportInstance = useCallback(
        async (input: Input, focus?: string) => {
            transportService.setTransportContext({
                instanceId: input.transportInstance?.uuid,
                node: input,
                focus: focus,
                canDelete: props.onDeleteTransportInstance !== undefined,
            })
        },
        [props.onDeleteTransportInstance],
    )

    const createTransportInstanceForInput = useCallback(
        async (input?: Input, transportType?: TransportType, transportFor?: Input) => {
            if (!input?.uuid) throw new Error('createTransportInstanceForInput: Error creating transport instance')
            if (input.transportInstance?.uuid) {
                throw new Error('createTransportInstanceForInput: Transport instance already exists')
            }
            const ti = await transportService.updateTransportInstance(
                TransportService.getEmptyTransportInstance(transportFor, transportType),
                input?.uuid,
            )
            input = await inputService.updateInput({
                uuid: input.uuid,
                name: 'Transport',
                transportFor: transportFor,
                transportInstance: ti,
                unit: UnitService.unitByCode['tkm'],
            })
            transportService.setTransportContext({
                instanceId: ti.uuid,
                node: input,
                canDelete: props.onDeleteTransportInstance !== undefined,
            })
            analyticsService.track('Added Transportation Input')
            return input
        },
        [analyticsService, productService, inputService, context.stores.ui?.useStagesReady],
    )

    const createTransportInstance = useCallback(
        async (product?: Product, input?: Input) => {
            if (!product?.uuid) throw new Error('Error creating transport type [1]')
            let _newInput = await productService.createInput(product?.uuid)
            if (!_newInput?.uuid) throw new Error('Error creating transport type [2]')
            return createTransportInstanceForInput(_newInput, undefined, input)
        },
        [analyticsService, productService, inputService, context.stores.ui?.useStagesReady],
    )

    const createProcessingType = useCallback(
        async (product?: Product, input?: Input) => {
            if (!product?.uuid) throw new Error('Error creating processing type [1]')
            let _newInput = await productService.createInput(product?.uuid)
            if (!_newInput?.uuid) throw new Error('Error creating processing type [2]')
            const _newProcessingType = await processingService.updateInputProcessing(
                ProcessingService.getEmptyProcessingType(),
                _newInput.uuid,
            )
            _newInput = await inputService.updateInput({
                uuid: _newInput.uuid,
                name: 'Processing',
                processingFor: input,
                processingType: _newProcessingType,
                useStageCategory: InputService.UseStageByCode.get('A3'),
                unit: _newProcessingType.energy?.unit || UnitService.unitByCode['kWh'],
            })
            processingService.editProcessingType({ node: _newInput, processingType: _newProcessingType })
            analyticsService.track('Added Processing Input')
            return _newInput
        },
        [analyticsService, productService, inputService, context.stores.ui?.useStagesReady],
    )

    const createUseStageType = useCallback(
        async (product?: Product) => {
            if (!product?.uuid) throw new Error('Error creating use stage type [1]')
            let _newInput = await productService.createInput(product?.uuid)
            if (!_newInput?.uuid) throw new Error('Error creating use stage type [2]')
            const ust = await useStageService.updateInputUseStageType(
                UseStageService.getEmptyUseStageType(),
                _newInput.uuid,
            )
            _newInput = await inputService.updateInput({
                uuid: _newInput.uuid,
                useStageType: ust,
                unit: UnitService.unitByCode['kWh'],
                useStageCategory: InputService.UseStageByCode.get('B1'),
            })
            useStageService.editUseStageType({ node: _newInput, useStageType: ust })
            analyticsService.track('Added Use Stage Input')
            return _newInput
        },
        [analyticsService, productService, inputService, context.stores.ui?.useStagesReady],
    )

    return {
        setInstanceId,
        createTransportInstanceForInput,
        createTransportInstance,
        creatingTransport,
        editTransportInstance,
        createProcessingType,
        creatingProcessing,
        createUseStageType,
        creatingUseStage,
    }
}
