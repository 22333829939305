import ProductService, { EmptyFactor, Factor, Product } from '../../services/product'
import { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils'
import InputField from '../Input/InputField'
import { UnitSelector } from '../Input/UnitSelector'
import { useFootprintSelector } from '../../hooks/useFootprintSelector'
import Button from '../Input/Button'
import { VariableNodeType } from '../../types'
import CO2e from '../CO2e'
import { GoTo } from '../GoTo'
import { Part } from '../../services/part'
import { VariableServicesContext } from '../../services'
import { InventoryType } from '../../services/inventory'

export const AmountOfEmissionFactor = (props: {
    targetNode?: Product | Part
    targetType?: VariableNodeType
    factor?: Factor
    hidden?: boolean
    allowProducts?: boolean
    disabled?: boolean
    onChange: (factor: Factor, rawQuantity: number, co2e: string) => void
}) => {
    const { productService } = useContext(VariableServicesContext)
    const [shouldSave, setShouldSave] = useState<boolean>(false)
    const [focusInput, setFocusInput] = useState<boolean>(false)
    const [factor, setFactor] = useState<Factor>({ ...EmptyFactor, ...props.factor })

    useEffect(() => {
        if (props.factor) setFactor(props.factor)
    }, [props.factor])

    useEffect(() => {
        if (!props.factor?.footprint?.uuid) return
        productService.getById(props.factor?.footprint?.uuid).then((footprint) => {
            setFactor((state) => ({ ...state, footprint }))
        })
    }, [props.factor?.footprint?.uuid])

    useEffect(() => {
        if (shouldSave && factor) {
            setShouldSave(false)
            const factorQuantity = Utils.Decimal(factor.quantity || 1)
            const factorQuantityUnitMultiplier = Utils.Decimal(factor.unit?.fromBaseUnit || 1)
            const quantity = factorQuantity.times(factorQuantityUnitMultiplier)
            const footprintCo2e = Utils.Decimal(parseFloat(factor.footprint?.co2e || '1'))
            const footprintUnitMultiplier = Utils.Decimal(factor.footprint?.unit?.toBaseUnit || 1)
            const footprint = footprintCo2e.times(footprintUnitMultiplier)
            const productCo2e = quantity.times(footprint)
            props.onChange(factor, factorQuantity.toNumber(), productCo2e.valueOf())
        }
    }, [shouldSave])

    useEffect(() => Utils.setTrueFor(setFocusInput), [])

    const showFootprintModal = useFootprintSelector({
        searchConfig: {
            instanceId: `amount-of-emission-factor-${props.targetNode?.uuid}`,
            title: 'Select Footprint',
            targetType: props.targetType,
            targetNode: props.targetNode,
            queryOptions: { unitType: props.targetNode?.unit?.type, taxonomy: factor?.footprint?.taxonomy?.uuid },
            view: 'database',
            inventoryTypes: [InventoryType.Product],
            visible: true,
        },
        onSelectProduct: (footprint) => {
            if (!footprint) return
            if (footprint?.uuid === props.targetNode?.uuid) {
                Utils.errorToast(undefined, `Cannot set an element as its own footprint`, { position: 'top-center' })
                return
            }
            const properties: Partial<Factor> = { footprint }
            if (factor.unit?.type !== footprint.unit?.type) {
                properties.unit = footprint.unit
            }
            if (!factor.quantity) {
                factor.quantity = 1
            }
            setFactor((state) => ({ ...state, ...properties }))
            setShouldSave(true)
        },
    })

    if (props.hidden) return null

    return (
        <div className='d-inline-flex align-items-center max-w-100'>
            <InputField
                disabled={!factor?.footprint?.uuid || props.disabled}
                hidden={!factor?.footprint?.uuid}
                focusOnRender={focusInput}
                placeholder='Quantity'
                className='variable-form-control border text-end'
                style={{ maxWidth: '4rem' }}
                defaultValue={factor?.quantity}
                followDefaultValue={true}
                isNumber={true}
                required={true}
                onChanged={(value) => {
                    setFactor((state) => ({ ...state, quantity: parseFloat(value) }))
                    setShouldSave(true)
                }}
            />
            <UnitSelector
                disabled={!factor?.footprint?.unit?.uuid || props.disabled}
                hidden={!factor?.footprint?.uuid}
                unit={factor?.unit}
                comparisonUnit={factor?.footprint?.unit}
                className='variable-select-arrow'
                required={true}
                onChange={(value) => {
                    setFactor((state) => ({ ...state, unit: value, quantity: factor?.quantity || 1 }))
                    setShouldSave(true)
                }}
            />
            <Button
                disabled={props.disabled}
                onClick={() => showFootprintModal()}
                className='btn btn-sm border shadow-none bg-white bg-primary-hover text-overflow-ellipsis max-w-100'
            >
                {factor.footprint?.uuid && (
                    <span>
                        {factor.footprint.name} (
                        <CO2e
                            co2e={factor.footprint.co2e}
                            unitSize='small'
                            functionalUnit={factor.footprint.unit?.code}
                            functionalUnitClassName='ms-1'
                        />
                        )
                    </span>
                )}
                {!factor.footprint?.uuid && 'Select'}
            </Button>
            {factor?.footprint?.uuid && (
                <GoTo
                    url={ProductService.getPassportUrl(factor.footprint)}
                    className='d-block px-2 text-muted text-primary-hover'
                />
            )}
        </div>
    )
}
