import Utils from '../../services/utils'
import { DataSourceSelector, DataSourceSelectorProps } from './DataSourceSelector'
import { KeyValuePair, QueryOptions } from '../../types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ApplicationContext } from '../../context'
import { Selector, SelectorProps } from './Selector'
import { FilterSelector } from './FilterSelector'
import { ArrowCounterClockwise, ListMagnifyingGlass, Plus, X } from '@phosphor-icons/react'
import Button from './Button'
import { OrgSelector } from '../Org/OrgSelector'
import { CompanyFilter } from '../Company/CompanyFilter'
import ProductService from '../../services/product'
import { ScopeSelector } from './ScopeSelector'
import MemberSearch from '../Company/MemberSearch'
import { Filter } from '../../services/filter'
import Tooltip from '../Tooltip'
import { unitTypes } from '../../services/unit'
import { VariableServicesContext } from '../../services'
import { TaxonomySelector } from './TaxonomySelector'
import GHGService from '../../services/ghg'
import { InventoryService } from '../../services/inventory'
import { InventorySelector } from './InventorySelector'

export type FilterType =
    | 'elementType'
    | 'inventory'
    | 'scope'
    | 'taxonomy'
    | 'org'
    | 'supplier'
    | 'customer'
    | 'createdBy'
    | 'user'
    | 'source'
    | 'unitType'

export const defaultFilters: FilterType[] = [
    'elementType',
    'inventory',
    'scope',
    'taxonomy',
    'org',
    'supplier',
    'customer',
    'createdBy',
    'user',
    'source',
    'unitType',
]

export const allFiltersList: KeyValuePair[] = [
    {
        value: 'elementType',
        name: 'Element type',
        description: 'Live Footprint, Imported, Transport, Switch, Mix, etc.',
    },
    {
        value: 'inventory',
        name: ProductService.elementTitle(true),
        description: `Your ${ProductService.elementTitle(true).toLowerCase()}`,
    },
    { value: 'scope', name: 'Scope', description: 'Scope 1, 2, 3, Transport, Purchased goods, etc.' },
    { value: 'taxonomy', name: 'Category', description: 'Product, Service, Business Travel, etc.' },
    { value: 'org', name: 'Org', description: 'Your org units' },
    { value: 'supplier', name: 'Supplier', description: 'Your suppliers' },
    { value: 'customer', name: 'Customer', description: 'Your customers' },
    { value: 'createdBy', name: 'Created by', description: 'Filter by original creator' },
    { value: 'user', name: 'User', description: 'User that has created or edited...' },
    { value: 'source', name: 'Source', description: 'Filter by data source' },
    { value: 'unitType', name: 'Unit type', description: 'Weight, volume, energy, etc.' },
]

export const Filters = (props: {
    hidden?: boolean
    queryOptions?: QueryOptions
    className?: string
    extraClassName?: string
    filters?: FilterType[]
    savedFilter?: Filter
    showFilterSelector?: boolean
    showClearButton?: boolean
    showDepthFilter?: boolean
    dataSourceSelectorProps?: Partial<DataSourceSelectorProps>
    onChange: (queryOptions?: QueryOptions) => void
    onSet?: (queryOptions: QueryOptions) => void
}) => {
    const context = useContext(ApplicationContext)
    const { inventoryService, partService, dataSourceService } = useContext(VariableServicesContext)
    const [openOnRender, setOpenOnRender] = useState<boolean>(false)
    const [visibleFilters, setVisibleFilters] = useState<Set<FilterType>>(new Set())

    useEffect(() => {
        if (!InventoryService.list.length) partService.fetchInventory().then()
        dataSourceService.getPublic().then()
    }, [])

    useEffect(() => {
        const keys = Object.keys(props.queryOptions || {}).filter((k) => props.filters?.includes(k as FilterType))
        if (keys.length) {
            setVisibleFilters(() => new Set(keys as FilterType[]))
        }
    }, [props.savedFilter, props.queryOptions])

    const containerClassName = useMemo(() => 'd-flex align-items-center gap-1 filter-label', [])

    const filters = useMemo(() => {
        return [...allFiltersList]
            .filter((f) => {
                if (visibleFilters.size && visibleFilters?.has(f.value as FilterType)) return false
                if (props.filters?.length === 0) return true
                return !!props.filters?.includes(f.value as FilterType)
            })
            .sort(Utils.sortByName)
    }, [props.filters, visibleFilters.size])

    const standardProps = useMemo(() => {
        const props: Partial<SelectorProps> = {
            positioning: 'fixed',
            prefixValueWithPlaceholder: true,
            buttonClassName: 'small',
            className: ' ',
            hoverClassName: ' ',
            activeClassName: ' ',
        }
        return props
    }, [])

    const filterSelector = useMemo(() => {
        if (props.showFilterSelector === false) return null
        return (
            <Tooltip trigger='hover' tooltipClassName='small p-1' tooltipContent='Saved filters'>
                <FilterSelector
                    key='filter-selector'
                    placement='bottom-start'
                    extraClassName='ps-1 pe-1 pt-0 pb-0 border-0 nt--1'
                    queryOptions={props.queryOptions}
                    filters={props.filters}
                    onSelect={(filter) => {
                        setVisibleFilters(() => {
                            const nextVisibleFilters = new Set<FilterType>()
                            Object.keys(filter.queryOptions)
                                .filter((k) => props.filters?.includes(k as FilterType))
                                .forEach((key) => {
                                    nextVisibleFilters.add(key as FilterType)
                                })
                            return nextVisibleFilters
                        })
                        props.onSet?.(filter.queryOptions)
                    }}
                />
            </Tooltip>
        )
    }, [props.filters, props.queryOptions])

    const addFilter = useMemo(() => {
        return (
            <Tooltip
                key='add-filter'
                trigger='hover'
                tooltipClassName='small p-1'
                tooltipHidden={!visibleFilters.size}
                className='filter-label bg-transparent border border-dashed bg-light-hover'
                placement='top'
                tooltipContent='Add filter'
            >
                <Selector
                    {...standardProps}
                    placement='bottom-start'
                    placeholder='Filter'
                    offset={[-3, 4]}
                    label={
                        <>
                            <Plus size={Utils.verySmallIconSize} /> {visibleFilters.size ? '' : 'Filter'}
                        </>
                    }
                    disabled={filters?.length === 0}
                    options={filters}
                    readonly={true}
                    onSelect={(filter) => {
                        Utils.setTrueFor(setOpenOnRender, 500)
                        setVisibleFilters((state) => {
                            const nextVisibleFilters = new Set(state)
                            nextVisibleFilters.add(filter.value as FilterType)
                            return nextVisibleFilters
                        })
                    }}
                />
            </Tooltip>
        )
    }, [filters, visibleFilters.size])

    const clearItem = useCallback(
        (filterType: FilterType) => {
            setVisibleFilters((state) => {
                const nextVisibleFilters = new Set(state)
                nextVisibleFilters.delete(filterType)
                return nextVisibleFilters
            })
            props.onChange?.({ [filterType]: undefined })
        },
        [props.queryOptions, visibleFilters],
    )

    const clearItemButton = useCallback(
        (filterType: FilterType) => {
            if (!Utils.hasValue(props.queryOptions?.[filterType])) return null
            return (
                <Button className='btn btn-xxs bg-light-hover' onClick={() => clearItem(filterType)}>
                    <X size={Utils.verySmallIconSize} />
                </Button>
            )
        },
        [props.queryOptions, clearItem],
    )

    const inventoryTypeSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('elementType')) return null
        const currentType =
            typeof props.queryOptions?.elementType === 'string' && props.queryOptions?.elementType?.match(/[0-9]/)
                ? parseInt(props.queryOptions?.elementType)
                : props.queryOptions?.elementType
        return (
            <div
                key='inventory-type-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.elementType) ? 'active' : ''].join(
                    ' ',
                )}
            >
                <Selector
                    {...standardProps}
                    placement='bottom-start'
                    openOnRender={openOnRender}
                    placeholder='Footprint type'
                    options={inventoryService.inventoryTypes.sort(Utils.sortByName)}
                    option={currentType}
                    onSelect={(newValue) => props.onChange({ elementType: newValue.value })}
                />
                {clearItemButton('elementType')}
            </div>
        )
    }, [standardProps, props.queryOptions?.elementType, openOnRender, clearItemButton])

    const inventorySelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('inventory')) return null
        const inventory = InventoryService.byId.get(props.queryOptions?.inventory || '')
        return (
            <div
                key='inventory-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.inventory) ? 'active' : ''].join(
                    ' ',
                )}
            >
                <InventorySelector
                    {...standardProps}
                    openOnRender={openOnRender}
                    option={inventory?.uuid}
                    onSelect={(inv) => props.onChange({ inventory: inv?.uuid })}
                />
                {clearItemButton('inventory')}
            </div>
        )
    }, [props.queryOptions?.inventory, context.stores.inventory?.updated, openOnRender, clearItemButton])

    const scopeSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('scope')) return null
        let scope = GHGService.byId.get(props.queryOptions?.scope)
        return (
            <div
                key='scope-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.scope) ? 'active' : ''].join(' ')}
            >
                <ScopeSelector
                    {...standardProps}
                    openOnRender={openOnRender}
                    placement='bottom-start'
                    noValueOption='Uncategorized'
                    displayNoValue={true}
                    scope={props.queryOptions?.scope === 'null' ? null : scope}
                    onSelect={(newScope) => {
                        if (newScope?.isNoValue) {
                            props.onChange({ scope: 'null' })
                        } else {
                            props.onChange({ scope: newScope?.uuid })
                        }
                    }}
                />
                {clearItemButton('scope')}
            </div>
        )
    }, [
        props.queryOptions?.scope,
        openOnRender,
        clearItemButton,
        context.stores.ui?.taxonomyReady,
        context.stores.ui?.ghgReady,
    ])

    const taxonomySelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('taxonomy')) return null
        return (
            <div
                key='taxonomy-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.taxonomy) ? 'active' : ''].join(' ')}
            >
                <TaxonomySelector
                    {...standardProps}
                    option={props.queryOptions?.taxonomy}
                    openOnRender={openOnRender}
                    noValueOption='Uncategorized'
                    displayNoValue={true}
                    onSelect={(newValue) => {
                        if (newValue?.isNoValue) {
                            props.onChange({ taxonomy: 'null' })
                        } else {
                            props.onChange({ taxonomy: newValue?.value })
                        }
                    }}
                />
                {clearItemButton('taxonomy')}
            </div>
        )
    }, [standardProps, props.queryOptions?.taxonomy, openOnRender, clearItemButton, context.stores.ui?.taxonomyReady])

    const orgSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('org')) return null
        const currentOrg = context.stores.orgs.orgById?.get(props.queryOptions?.org || '')
        return (
            <div
                key='org-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.org) ? 'active' : ''].join(' ')}
            >
                <OrgSelector
                    {...standardProps}
                    placement='bottom-start'
                    allowAnyOrg={true}
                    openOnRender={openOnRender}
                    org={props.queryOptions?.org === 'null' ? null : currentOrg}
                    onSelect={(_org) => {
                        if (_org?.isUncategorized) {
                            props.onChange({ org: 'null', depth: undefined })
                        } else {
                            props.onChange({ org: _org?.uuid, depth: undefined })
                        }
                    }}
                />
                {clearItemButton('org')}
            </div>
        )
    }, [props.queryOptions?.org, context.stores.orgs.orgById?.size, openOnRender, clearItemButton])

    const supplierSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('supplier')) return null
        const supplier = context.stores.suppliers.byId.get(props.queryOptions?.supplier || '')
        return (
            <div
                key='supplier-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.supplier) ? 'active' : ''].join(' ')}
            >
                <CompanyFilter
                    {...standardProps}
                    placement='bottom-start'
                    company={supplier}
                    openOnRender={openOnRender}
                    perspective='supplier'
                    noValueOption='No supplier'
                    displayNoValue={true}
                    onSelect={(node) => {
                        if (node?.isNoValue) {
                            props.onChange({ supplier: 'null' })
                        } else {
                            props.onChange({ supplier: node?.uuid })
                        }
                    }}
                />
                {clearItemButton('supplier')}
            </div>
        )
    }, [props.queryOptions?.supplier, context.stores.suppliers.byId.size, openOnRender, clearItemButton])

    const customerSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('customer')) return null
        const customer = context.stores.customers.byId.get(props.queryOptions?.customer || '')
        return (
            <div
                key='customer-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.customer) ? 'active' : ''].join(' ')}
            >
                <CompanyFilter
                    {...standardProps}
                    placement='bottom-start'
                    company={customer}
                    openOnRender={openOnRender}
                    perspective='customer'
                    noValueOption='No customer'
                    displayNoValue={true}
                    onSelect={(node) => {
                        if (node?.isNoValue) {
                            props.onChange({ customer: 'null' })
                        } else {
                            props.onChange({ customer: node?.uuid })
                        }
                    }}
                />
                {clearItemButton('customer')}
            </div>
        )
    }, [props.queryOptions?.customer, context.stores.customers.byId.size, openOnRender, clearItemButton])

    const userFilter = useMemo(() => {
        if (props.filters && !props.filters?.includes('user')) return null
        const _member = context.stores.members.find((m) => m.user.uuid === props.queryOptions?.user)
        return (
            <div
                key='user-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.user) ? 'active' : ''].join(' ')}
            >
                <MemberSearch
                    {...standardProps}
                    buttonClassName='d-flex align-items-center gap-1 small'
                    placeholder='User'
                    placement='bottom-start'
                    openOnRender={openOnRender}
                    member={_member?.user}
                    onSelect={(member) => props.onChange({ user: member?.uuid })}
                />
                {clearItemButton('user')}
            </div>
        )
    }, [props.queryOptions?.user, context.stores.members.length, openOnRender, clearItemButton])

    const createdByFilter = useMemo(() => {
        if (props.filters && !props.filters?.includes('createdBy')) return null
        const _member = context.stores.members.find((m) => m.user.uuid === props.queryOptions?.createdBy)
        return (
            <div
                key='created-by-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.createdBy) ? 'active' : ''].join(
                    ' ',
                )}
            >
                <MemberSearch
                    {...standardProps}
                    buttonClassName='d-flex align-items-center gap-1 small'
                    placeholder='Created by'
                    placement='bottom-start'
                    openOnRender={openOnRender}
                    member={_member?.user}
                    onSelect={(member) => props.onChange({ createdBy: member?.uuid })}
                />
                {clearItemButton('createdBy')}
            </div>
        )
    }, [props.queryOptions?.createdBy, context.stores.members.length, openOnRender, clearItemButton])

    const dataSourceSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('source')) return null
        return (
            <DataSourceSelector
                key='data-source-selector'
                {...standardProps}
                {...props.dataSourceSelectorProps}
                containerExtraClassName={[
                    Utils.hasValue(props.queryOptions?.source) ? 'active' : '',
                    'filter-label',
                ].join(' ')}
                placeholder='Source'
                openOnRender={openOnRender}
                option={props.queryOptions?.source}
                onSelect={(newValue) => props.onChange({ source: newValue?.uuid })}
                onClear={() => clearItem('source')}
            />
        )
    }, [props.dataSourceSelectorProps, props.queryOptions?.source, openOnRender, clearItem])

    const unitTypeSelector = useMemo(() => {
        if (props.filters && !props.filters?.includes('unitType')) return null
        return (
            <div
                key='unit-type-selector'
                className={[containerClassName, Utils.hasValue(props.queryOptions?.unitType) ? 'active' : ''].join(' ')}
            >
                <Selector
                    {...standardProps}
                    placement='bottom-start'
                    placeholder='Unit type'
                    openOnRender={openOnRender}
                    options={unitTypes.sort((a, b) => a.text?.localeCompare(b.text || '') || -1)}
                    option={props.queryOptions?.unitType}
                    onSelect={(newValue) => props.onChange({ unitType: newValue.value })}
                />
                {clearItemButton('unitType')}
            </div>
        )
    }, [props.queryOptions?.unitType, openOnRender, clearItemButton])

    const depthFilter = useMemo(() => {
        if (props.showDepthFilter === false) return null
        if (!props.queryOptions?.taxonomy && !props.queryOptions?.org) return null
        if (props.queryOptions?.taxonomy === 'null' || props.queryOptions?.org === 'null') return null
        const depth = parseInt(props.queryOptions?.depth || '1')
        return (
            <div
                key='depth-filter'
                className={[containerClassName, props.queryOptions?.depth !== '0' ? 'active' : ''].join(' ')}
            >
                <Tooltip
                    trigger='hover'
                    placement='top'
                    tooltipClassName='small p-1'
                    tooltipContent={depth === 0 ? 'Show subcategories' : 'Hide subcategories'}
                    className='d-block'
                    onClick={() => {
                        if (depth === 0) {
                            props.onChange({ depth: undefined })
                        } else {
                            props.onChange({ depth: '0' })
                        }
                    }}
                >
                    <ListMagnifyingGlass weight={depth === 0 ? 'thin' : 'bold'} />
                </Tooltip>
            </div>
        )
    }, [props.queryOptions?.depth, props.queryOptions?.taxonomy, props.queryOptions?.org])

    const clearButton = useMemo(() => {
        if (props.showClearButton === false || !visibleFilters?.size) return null
        return (
            <Button
                key='clear-button'
                className='btn btn-xxs bg-light-hover text-very-muted text-primary-hover underline-on-hover nt-1'
                tooltip='Clear all filters'
                tooltipProps={{
                    positioning: 'fixed',
                    tooltipClassName: 'small p-1',
                    placement: 'top',
                    offset: [0, 12],
                }}
                onClick={() => {
                    setVisibleFilters(() => new Set())
                    props.onSet?.({})
                }}
            >
                <ArrowCounterClockwise />
            </Button>
        )
    }, [visibleFilters.size, props.queryOptions, props.showClearButton])

    return (
        <div
            hidden={props.hidden}
            className={
                props.className ||
                [props.hidden ? 'd-none' : 'd-flex', 'align-items-center gap-1', props.extraClassName].join(' ')
            }
        >
            {filterSelector}
            {Array.from(visibleFilters)?.map((f) => {
                switch (f) {
                    case 'elementType':
                        return inventoryTypeSelector
                    case 'inventory':
                        return inventorySelector
                    case 'scope':
                        return scopeSelector
                    case 'taxonomy':
                        return taxonomySelector
                    case 'org':
                        return orgSelector
                    case 'supplier':
                        return supplierSelector
                    case 'customer':
                        return customerSelector
                    case 'createdBy':
                        return createdByFilter
                    case 'user':
                        return userFilter
                    case 'source':
                        return dataSourceSelector
                    case 'unitType':
                        return unitTypeSelector
                    default:
                        return <span key={`not-implemented-selector-${Math.random()}-${new Date().valueOf()}`} />
                }
            })}
            {depthFilter}
            {addFilter}
            {clearButton}
        </div>
    )
}
