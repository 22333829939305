import { ActivityItem, ActivityRating, ActivityState } from './activity'
import { ActionMap } from '../context'
import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { DataImport } from './dataImport'
import { DataRequest, DataRequestPerspective } from './dataRequest'
import { ListResponse, TimePeriodFormat, UnitTotal } from '../types'
import { Company } from './company'
import Utils from './utils'

export enum ActivityActionType {
    SetLoading = 'SetActivitiesLoading',
    SetQueryStringKey = 'SetQueryStringKey',
    SetTimePeriodFormat = 'SetTimePeriodFormat',

    Update = 'UpdateActivity',
    Remove = 'RemoveActivity',
    Select = 'SelectActivity',
    Deselect = 'DeselectActivity',
    Reset = 'ResetActivities',

    SetActivityData = 'SetActivityData',
    SetTotalCount = 'SetTotalCount',
    SetTotalCo2e = 'SetTotalCo2e',
    SetTotalUncategorizedCo2e = 'SetTotalUncategorizedCo2e',
    SetActivityRating = 'SetActivityRating',
    SetUnitTotals = 'SetUnitTotals',

    SetDataImport = 'SetDataImport',
    ResetDataImport = 'ResetDataImport',
    SetDataRequest = 'SetDataRequest',

    SetDataRequestPerspective = 'SetDataRequestPerspective',
    ResetDataRequest = 'ResetDataRequest',

    SetIsOnboarding = 'SetIsOnboardingActivity',

    SetProductOf = 'SetProductOf',

    UpdateList = 'UpdateActivityList',
}

type ActivityListActionPayload = {
    [ActivityActionType.SetLoading]: boolean
    [ActivityActionType.SetQueryStringKey]: URLSearchParams
    [ActivityActionType.SetTimePeriodFormat]: TimePeriodFormat

    [ActivityActionType.Update]: ActivityItem
    [ActivityActionType.Remove]: ActivityItem
    [ActivityActionType.Select]: ActivityItem
    [ActivityActionType.Deselect]: undefined
    [ActivityActionType.Reset]: undefined

    [ActivityActionType.SetActivityData]: ListResponse
    [ActivityActionType.SetTotalCo2e]: number | undefined
    [ActivityActionType.SetTotalUncategorizedCo2e]: number | undefined
    [ActivityActionType.SetTotalCount]: number | undefined
    [ActivityActionType.SetActivityRating]: ActivityRating | undefined
    [ActivityActionType.SetUnitTotals]: UnitTotal[] | undefined

    [ActivityActionType.SetDataImport]: DataImport
    [ActivityActionType.ResetDataImport]: undefined
    [ActivityActionType.SetDataRequest]: DataRequest

    [ActivityActionType.SetDataRequestPerspective]: DataRequestPerspective
    [ActivityActionType.ResetDataRequest]: undefined

    [ActivityActionType.SetIsOnboarding]: boolean | undefined

    [ActivityActionType.SetProductOf]: Company | undefined

    [ActivityActionType.UpdateList]: undefined
}

type Actions = ActionMap<ActivityListActionPayload>[keyof ActionMap<ActivityListActionPayload>]

interface IActivityContext {
    loading: boolean
    queryString?: string
    pageSize: number
    timePeriodFormat?: TimePeriodFormat
    totalCount: number
    totalCo2e?: number
    unitTotals?: UnitTotal[]
    uncategorizedCo2e?: number
    grandTotalCo2e?: number
    activityRating?: ActivityRating
    activityData?: ListResponse
    activities: ActivityItem[]
    activity?: ActivityItem
    isOnboarding?: boolean
    updateCount: number
    listRefreshCount: number
    closed: boolean
    dataImport?: DataImport
    dataRequest?: DataRequest
    drPerspective?: DataRequestPerspective
    productOf?: Company
}

const mainReducer = (state: IActivityContext, action: Actions): IActivityContext => {
    if (action.type === ActivityActionType.SetQueryStringKey) {
        const qs = new URLSearchParams(state.queryString)
        const fs = Utils.filterSettings
        for (const key in fs) {
            qs.set(key, fs[key])
        }
        if (qs.get('when') !== 'range') {
            qs.delete('sd')
            qs.delete('ed')
        }
        Array.from(action.payload.entries()).forEach(([key, value]) => {
            qs.set(key, value)
        })
        if (!qs.get('activityStates')) {
            qs.set('activityStates', ActivityState.APPROVED)
        }
        return { ...state, queryString: qs.toString() }
    }
    switch (action.type) {
        case ActivityActionType.SetLoading:
            return {
                ...state,
                loading: action.payload,
            }
        case ActivityActionType.SetTimePeriodFormat:
            return {
                ...state,
                timePeriodFormat: action.payload,
            }
        case ActivityActionType.SetTotalCount:
            return {
                ...state,
                totalCount: action.payload || 0,
            }
        case ActivityActionType.SetActivityRating:
            return {
                ...state,
                activityRating: action.payload,
            }
        case ActivityActionType.SetUnitTotals:
            return {
                ...state,
                unitTotals: action.payload,
            }
        case ActivityActionType.SetTotalCo2e:
            return {
                ...state,
                totalCo2e: action.payload,
            }
        case ActivityActionType.SetTotalUncategorizedCo2e:
            return {
                ...state,
                uncategorizedCo2e: action.payload,
            }
        case ActivityActionType.SetActivityData:
            return {
                ...state,
                activityData: action.payload,
                totalCount: action.payload.count,
                totalCo2e: action.payload.totalCo2e,
                uncategorizedCo2e: action.payload.uncategorizedCo2e,
                grandTotalCo2e: (action.payload.totalCo2e || 0) + (action.payload.uncategorizedCo2e || 0),
                activities: action.payload.data as ActivityItem[],
                loading: false,
            }
        case ActivityActionType.Update:
            return {
                ...state,
                activities: state.activities.map((ai) => {
                    if (ai.uuid === action.payload.uuid) {
                        return action.payload
                    }
                    return ai
                }),
                activity: action.payload,
                updateCount: state.updateCount + 1,
            }
        case ActivityActionType.Remove:
            return {
                ...state,
                activities: state.activities.filter((ai) => ai.uuid !== action.payload.uuid),
                activity: undefined,
                closed: true,
                updateCount: state.updateCount + 1,
            }
        case ActivityActionType.Select:
            return {
                ...state,
                activity: action.payload,
                updateCount: 0,
                closed: false,
            }
        case ActivityActionType.Deselect:
            return {
                ...state,
                activity: undefined,
                closed: true,
            }
        case ActivityActionType.SetDataImport:
            return {
                ...state,
                dataImport: action.payload,
            }
        case ActivityActionType.ResetDataImport:
            return {
                ...state,
                dataImport: undefined,
            }
        case ActivityActionType.SetDataRequest:
            return {
                ...state,
                dataRequest: action.payload,
            }
        case ActivityActionType.SetDataRequestPerspective:
            return {
                ...state,
                drPerspective: action.payload,
            }
        case ActivityActionType.ResetDataRequest:
            return {
                ...state,
                dataRequest: undefined,
                drPerspective: undefined,
            }
        case ActivityActionType.SetIsOnboarding:
            return {
                ...state,
                isOnboarding: action.payload,
            }
        case ActivityActionType.SetProductOf:
            return {
                ...state,
                productOf: action.payload,
            }
        case ActivityActionType.UpdateList:
            return {
                ...state,
                listRefreshCount: state.listRefreshCount + 1,
            }
        case ActivityActionType.Reset:
            return { ...initialState, activity: state.activity }
        default:
            return state
    }
}

const initialState: IActivityContext = {
    loading: false,
    queryString: undefined,
    pageSize: 100,
    timePeriodFormat: undefined,
    totalCount: 0,
    totalCo2e: undefined,
    unitTotals: undefined,
    uncategorizedCo2e: undefined,
    grandTotalCo2e: undefined,
    activityRating: undefined,
    activityData: undefined,
    activities: [],
    activity: undefined,
    isOnboarding: undefined,
    listRefreshCount: 0,
    updateCount: 0,
    closed: true,
    dataImport: undefined,
    dataRequest: undefined,
    drPerspective: undefined,
    productOf: undefined,
}

export interface ActivityContextInterface extends IActivityContext {
    dispatch: Dispatch<Actions>
}

export const ActivityContext = createContext<ActivityContextInterface>({
    ...initialState,
    dispatch: () => null,
})

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
    const [stores, dispatch] = useReducer(mainReducer, initialState)
    return <ActivityContext.Provider value={{ ...stores, dispatch }}>{children}</ActivityContext.Provider>
}
