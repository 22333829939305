import Button from '../Input/Button'
import ProductService, { Product, ProductVisibility } from '../../services/product'
import { UnitHeader } from '../UnitHeader'
import CO2e from '../CO2e'
import { CSSProperties, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Company } from '../../services/company'
import Utils from '../../services/utils'
import InputField from '../Input/InputField'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { CloudCheck, Plus } from '@phosphor-icons/react'
import PactService from '../../services/pact'
import { ElementBadge } from './ElementBadge'

import { Co2eDisplay } from '../../types'
import { InventoryService } from '../../services/inventory'

export const CompactProductCatalog = (props: {
    productOf?: Company
    title?: ReactNode
    newBtnClassName?: string
    listClassName?: string
    listStyle?: CSSProperties
    shouldFetch?: number
    co2eDisplay?: Co2eDisplay
    disabled?: boolean
    actionButtonText?: ReactNode
    actionButtonClassName?: string
    filterClassName?: string
    filterProduct?: (product: Product) => boolean
    onProducts?: (products: Product[]) => void
    onClick?: (product: Product) => void
    onCreate?: (product: Product) => void
}) => {
    const context = useContext(ApplicationContext)
    const { productService, analyticsService } = useContext(VariableServicesContext)
    const [hasFetched, setHasFetched] = useState<Set<string>>(new Set())
    const [filterText, setFilterText] = useState<string>()
    const [fetching, setFetching] = useState<boolean>(false)
    const [products, setProducts] = useState<Product[]>([])
    const [minProductVisibility, setMinProductVisibility] = useState(ProductVisibility.PRIVATE)

    useEffect(() => {
        if (!hasFetched.has(props.productOf?.uuid || '') && !products.length) {
            setHasFetched((state) => {
                state.add(props.productOf?.uuid || '')
                return state
            })
            fetchProducts()
        }
    }, [props.productOf?.uuid])

    useEffect(() => {
        if (!props.productOf?.uuid) return
        setProducts(
            InventoryService.list
                .filter((p) => p.originalProduct?.productOf?.uuid === props.productOf?.uuid)
                .map((i) => i.originalProduct!) || [],
        )
    }, [])

    useEffect(() => props.onProducts?.(products), [products])

    useEffect(
        () => fetchProducts(),
        [props.shouldFetch, props.productOf?.uuid, context.stores.products.previewClosedId, minProductVisibility],
    )

    const fetchProducts = useCallback(() => {
        if (!props.productOf?.uuid) return
        setFetching(true)
        productService
            .get(`productOf=${props.productOf.uuid}&minProductVisibility=${minProductVisibility}&limit=-1`)
            .then((plr) => setProducts(plr.data as Product[]))
            .finally(() => setFetching(false))
    }, [props.productOf?.uuid, minProductVisibility])

    return (
        <div className={props.listClassName || 'w-100'} style={props.listStyle}>
            <div
                className='d-grid align-items-baseline small max-h-100'
                style={{ gridTemplate: 'auto / [name] minmax(50%, auto) [action] auto [co2e] auto' }}
            >
                <div
                    className='d-grid align-items-center border-bottom pb-1'
                    style={{ gridColumn: 'name / span 3', gridTemplateColumns: 'subgrid' }}
                >
                    <div className='d-flex flex-wrap align-items-center gap-1 px-2' style={{ gridColumn: 'name' }}>
                        <span hidden={props.title === ''} className='fs-6'>
                            {props.title !== undefined ? props.title : ProductService.webTitle(true)}
                        </span>
                        <InputField
                            hidden={products.length < 4}
                            placeholder='Filter list'
                            className={props.filterClassName || 'variable-form-control bg-light'}
                            onChange={setFilterText}
                        />
                        {fetching && <span className='spinner-border spinner-border-sm' />}
                    </div>
                    <div style={{ gridColumn: 'action' }}>
                        {productService.isEditable(ProductService.getEmptyProduct({ productOf: props.productOf })) &&
                            !props.disabled && (
                                <Button
                                    disabled={props.disabled}
                                    onClick={() => {
                                        productService.openPreview(
                                            ProductService.getEmptyProduct({ productOf: props.productOf }),
                                        )
                                        analyticsService.track('Open Add Product Modal')
                                    }}
                                    className={
                                        props.newBtnClassName ||
                                        'btn btn-sm btn-light border shadow-none px-1 py-0 text-nowrap'
                                    }
                                >
                                    <Plus size={Utils.verySmallIconSize} /> {ProductService.webTitle()}
                                </Button>
                            )}
                    </div>
                    <div style={{ gridColumn: 'co2e' }} className='text-end ps-1'>
                        <UnitHeader unitSize='small' />
                    </div>
                </div>
                {products
                    ?.filter((p) => {
                        if (props.filterProduct && !props.filterProduct(p)) {
                            return false
                        }
                        if (filterText) {
                            return (
                                p.name.toLowerCase().includes(filterText.toLowerCase()) ||
                                p.description?.toLowerCase().includes(filterText.toLowerCase())
                            )
                        }
                        return true
                    })
                    ?.sort(Utils.sortByName)
                    ?.map((p) => {
                        const hasButton = props.onClick && !props.disabled
                        let co2eValue = p.upstreamCo2e || p.co2e
                        if (props.co2eDisplay === 'Total') co2eValue = p.co2e
                        else if (props.co2eDisplay === 'Downstream') co2eValue = p.downstreamCo2e || p.co2e
                        return (
                            <div
                                key={`p-${p.uuid}`}
                                className='d-grid align-items-center py-1 border-bottom border-last-none'
                                style={{ gridColumn: 'name / span 3', gridTemplateColumns: 'subgrid' }}
                            >
                                <div
                                    style={{ gridColumn: hasButton ? 'name' : 'name / span 2' }}
                                    className='overflow-hidden'
                                >
                                    <ElementBadge
                                        product={p}
                                        tooltipProps={{ disabled: true }}
                                        onClick={() => {
                                            productService.openPreview(p)
                                            analyticsService.track('Edit Product Modal', {
                                                uuid: p.uuid,
                                                name: p.name,
                                            })
                                        }}
                                    />
                                    {PactService.isPactSynced(p) && <CloudCheck className='nt--1 ms-1' />}
                                </div>
                                {hasButton && (
                                    <div style={{ gridColumn: 'action' }}>
                                        <Button
                                            className={props.actionButtonClassName || 'btn btn-xs btn-outline-primary'}
                                            disabled={props.disabled}
                                            onClick={() => {
                                                analyticsService.track('Select Product', {
                                                    uuid: p.uuid,
                                                    name: p.name,
                                                })
                                                props.onClick?.(p)
                                            }}
                                        >
                                            {props.actionButtonText || 'Select'}
                                        </Button>
                                    </div>
                                )}
                                <div style={{ gridColumn: 'co2e' }} className='text-end ps-1'>
                                    <CO2e
                                        co2e={co2eValue}
                                        unitSize='small'
                                        product={p}
                                        showUnit={false}
                                        precision={2}
                                        showZeroesToPrecision={true}
                                        functionalUnitClassName='d-block small text-muted'
                                        functionalUnit={p?.unit?.code}
                                    />
                                </div>
                            </div>
                        )
                    })}
                <div style={{ gridColumn: 'name / span 3', gridTemplateColumns: 'subgrid' }}>
                    <Button
                        onClick={() => {
                            if (minProductVisibility === ProductVisibility.PRIVATE) {
                                setMinProductVisibility(ProductVisibility.ARCHIVED)
                            } else {
                                setMinProductVisibility(ProductVisibility.PRIVATE)
                            }
                        }}
                        className='btn btn-xs btn-light mt-1'
                    >
                        {minProductVisibility === ProductVisibility.PRIVATE && 'Show archived'}
                        {minProductVisibility === ProductVisibility.ARCHIVED && 'Hide archived'}
                    </Button>
                </div>
            </div>
        </div>
    )
}
