import { ReactNode } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import Button from './Input/Button'
import AuthenticationService from '../services/authentication'
import { Link, useLocation } from 'react-router-dom'
import ProductService from '../services/product'

export const useAuth0User = () => {
    const { user } = useAuth0()
    return { auth0User: user }
}

export const VariableAuthProvider = (props: { children: ReactNode }) => {
    const location = useLocation()
    if (location.pathname?.startsWith(ProductService.webRootEmbed)) {
        return <>{props.children}</>
    }
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{ redirect_uri: window.location.origin + AuthenticationService.welcomePath }}
        >
            <>{props.children}</>
        </Auth0Provider>
    )
}

const getFrom = (from?: string): string => {
    if (from && from !== '/') {
        return `?from=${from}`
    }
    return ''
}

export const AuthButton = (props: { className?: string; screen?: 'signup' | 'login'; children?: ReactNode }) => {
    let _url = `${process.env.REACT_APP_BASE_URL}${AuthenticationService.signUpPath}?from=/`
    if (props.screen === 'login') {
        _url = `${process.env.REACT_APP_BASE_URL}${AuthenticationService.signInPath}?from=/`
    }
    return (
        <Link to={_url} className={props.className || 'btn btn-sm btn-primary'}>
            <>{props.children || 'Sign up'}</>
        </Link>
    )
}

export const LegacyAuthButtons = (props: {
    from?: string
    className?: string
    signUpClassName?: string
    signInClassName?: string
}) => {
    const _from = props.from || '/'
    const _onClick = () => {
        AuthenticationService.nextPage = props.from || `${document.location.pathname}${document.location.search}`
    }
    return (
        <span className={props.className}>
            <Link
                onClick={_onClick}
                className={props.signUpClassName || 'me-2 btn btn-sm btn-secondary'}
                to={`${process.env.REACT_APP_BASE_URL}${AuthenticationService.signUpPath}${getFrom(_from)}`}
            >
                Sign up
            </Link>
            <Link
                onClick={_onClick}
                className={
                    props.signInClassName || 'd-none d-sm-inline-block btn btn-sm text-white-25 text-hover-white'
                }
                to={`${process.env.REACT_APP_BASE_URL}${AuthenticationService.signInPath}${getFrom(_from)}`}
            >
                Sign in
            </Link>
        </span>
    )
}

export const Auth0Button = (props: { className?: string; screen?: 'signup' | 'login'; children?: ReactNode }) => {
    const { loginWithRedirect } = useAuth0()
    return (
        <Button
            className={props.className || 'btn btn-sm btn-primary'}
            onClick={async () => {
                AuthenticationService.nextPage = `${document.location.pathname}${document.location.search}`
                await loginWithRedirect({ authorizationParams: { screen_hint: props.screen || 'login' } })
            }}
        >
            {props.children || 'Sign up'}
        </Button>
    )
}
